import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { clone, cloneDeep, compact, find, first, get, isEmpty, remove, sortBy, uniqueId } from "lodash"
import moment, { Moment } from "moment"
import numbro from "numbro"
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Table, Tooltip, UncontrolledTooltip } from "reactstrap"
import { CalendarContext, appDate } from "../../Context/CalendarContext"
import { ClientPortfolioFinancialSummaryFragment, ClientPortfolioReturnTypeCode, FinancialSummaryInput, FinancialSummaryPeriod, PlanPerformanceReturnsFragment, usePlanPerformanceReturnsQuery, usePlanPerformanceWealthQuery, useUpdatePlanPerformanceReturnsMutation } from "../../__generated__/graphql"
import { PlanPerformanceReturnsColumnDef } from "../../helpers/columnDefs"
import exportTables, { ExtraRow } from "../../helpers/exportTable"
import { FootnotableComponent, currencyFormat, percentFormat, rankingFormat, largeMantissaFormat, longPercentFormat } from "../Report/Shared/ReportComponent"
import SortableTable from "../Shared/SortableTable"
import LoadingOverlay from "../ui/Loading"
import { CellValueChangedEvent, ColumnApi, FilterChangedEvent, GridApi, GridReadyEvent } from "@ag-grid-community/core"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "../../helpers/constant"
import { EditButtonContext } from "../../Context/EditButtonContext"
import { useHistory } from "react-router-dom"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import { CalendarPickerPeriod } from "../CalendarPicker"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { WealthLineOptions } from "../../helpers/highCharts"
import ErrorDisplay from "../Shared/ErrorDisplay"
import { IconName } from "@fortawesome/fontawesome-svg-core"

export type ReturnsHandle = {
  handleSubmit: () => void;
};

interface PlanPerformanceReturnsProps {
  auth: any
  portfolioId: number
  editMode: boolean
  subtype?: string
  planId: number
  saving: boolean
  search: string
  selectedDataPeriod: CalendarPickerPeriod
  setSelectedDataPeriod: (period: CalendarPickerPeriod) => void
  returnsRef: React.RefObject<ReturnsHandle>
  setSaving: (saving: boolean) => void
  setEditMode: (editMode: boolean) => void
}

export const PlanPerformanceReturns: React.FC<PlanPerformanceReturnsProps> = (props) => {
  const { auth, portfolioId, editMode, subtype, planId, saving, search, selectedDataPeriod, setSelectedDataPeriod, returnsRef } = props

  const { data, loading, error } = usePlanPerformanceReturnsQuery({
    variables: {
      id: portfolioId
    },
  })

  if(data && data.clientPortfolio){
    return (
      <PlanPerformanceReturnsDisplay
        auth={auth}
        portfolioId={portfolioId}
        editMode={editMode}
        planId={planId}
        saving={saving}
        data={data.clientPortfolio}
        search={search}
        selectedDataPeriod={selectedDataPeriod}
        setSelectedDataPeriod={setSelectedDataPeriod}
        ref={returnsRef}
        setSaving={props.setSaving}
        setEditMode={props.setEditMode}
      />
    )
  }
  return (
    <LoadingOverlay/>
  )
}

interface PlanPerformanceReturnsDisplayProps {
  auth: any
  portfolioId: number
  editMode: boolean
  subtype?: string
  planId: number
  saving: boolean
  data: PlanPerformanceReturnsFragment
  search: string
  selectedDataPeriod: CalendarPickerPeriod
  setSelectedDataPeriod: (period: CalendarPickerPeriod) => void
  ref: React.Ref<ReturnsHandle>
  setSaving: (saving: boolean) => void
  setEditMode: (editMode: boolean) => void
}

export type ReturnColumnDataType = ClientPortfolioFinancialSummaryFragment & {
  rowId: string
  previousId?: string
  otherAssets?: number | null
  touched?: boolean
}

export const PlanPerformanceReturnsDisplay: React.FC<PlanPerformanceReturnsDisplayProps> = forwardRef((props, ref) => {
  const { auth, portfolioId, editMode, setEditMode, subtype, planId, saving, setSaving, data, search, selectedDataPeriod, setSelectedDataPeriod} = props

  const [updatePlanPerformanceReturns] = useUpdatePlanPerformanceReturnsMutation()
  const [selectedRows, setSelectedRows] = useState<ReturnColumnDataType[]>([])
  const [quarterModalOpen, setQuarterModalOpen] = useState(false)
  const [wealthModalOpen, setWealthModalOpen] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [valuesChanged, setValuesChanged] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)
  const { setWarningModal } = useContext(EditButtonContext)
  const { period: lockedPeriod } = useContext(CalendarContext)
  const history = useHistory()

  useEffect(() => {
    setInitialLoad(true)
    if(columnApi){
      columnApi.resetColumnState()
      columnApi.applyColumnState({state: [
        {colId: 'date', sort:"desc"},
        {colId: 'type.value', sort:"asc"},
      ], applyOrder: true})
    }
    if(gridApi){
      gridApi.setFilterModel({
        'type.value': {
          filterType: 'set',
          values: ["Gross", "Net"]
        }
      })
    }
  }, [portfolioId])

  const columnData = useMemo(() => {
    const addPreviousData = (rows: ClientPortfolioFinancialSummaryFragment[] | null, type: "monthly" | "quarterly", otherRows?: ClientPortfolioFinancialSummaryFragment[]) => {
      if(!rows) return []
      return rows.map((item) => {
        const previousDate = moment(item.date).subtract(1, type === "monthly" ? "months" : "quarters").endOf("month").format("YYYY-MM-DD")
        const previousRow = find(rows, (rowTest) => {
          return get(rowTest, "date") === previousDate && get(rowTest, "type.code") === item.type?.code
        }) as ClientPortfolioFinancialSummaryFragment
        let otherRow = null
        if(!previousRow){
          otherRow = find(otherRows, {date: previousDate})
        }
        return {
          ...item,
          rowId: `${item.date}:${item.type?.value}`,
          previousId: `${previousRow?.date}:${previousRow?.type?.value}`,
          otherAssets: otherRow?.assets,
          touched: false,
        }
      })
    }
    if(initialLoad && !lockedPeriod){
      setInitialLoad(false)
      if(data.financialSummaryMonthly && data.financialSummaryMonthly.length > 0 && data.financialSummaryQuarterly && data.financialSummaryQuarterly.length > 0){
        if(moment(first(data.financialSummaryMonthly)?.date) > moment(first(data.financialSummaryQuarterly)?.date)){
          setSelectedDataPeriod("month")
          return addPreviousData(data.financialSummaryMonthly, "monthly", data.financialSummaryQuarterly)
        } else {
          setSelectedDataPeriod("quarter")
          return addPreviousData(data.financialSummaryQuarterly, "quarterly", data.financialSummaryMonthly)
        }
      } else {
        if(data.financialSummaryMonthly && data.financialSummaryMonthly.length > 0){
          setSelectedDataPeriod("month")
          return addPreviousData(data.financialSummaryMonthly, "monthly")
        } else if(data.financialSummaryQuarterly && data.financialSummaryQuarterly.length > 0){
          setSelectedDataPeriod("quarter")
          return addPreviousData(data.financialSummaryQuarterly, "quarterly")
        }
      }
    } else {
      if(selectedDataPeriod === "month"){
        return addPreviousData(data.financialSummaryMonthly || null, "monthly", data.financialSummaryQuarterly || undefined)
      } else if(selectedDataPeriod === "quarter"){
        return addPreviousData(data.financialSummaryQuarterly || null, "quarterly", data.financialSummaryMonthly || undefined)
      }
    }
    return []
  }, [data, selectedDataPeriod, initialLoad])
  const [currentColumnData, setCurrentColumnData] = useState<ReturnColumnDataType[]>(columnData)

  useEffect(() => {
    setCurrentColumnData(cloneDeep(columnData))
  }, [columnData, editMode])

  useImperativeHandle(ref, () => {
    return {
      handleSubmit() {
        setSaving(true)
        let graphqlFinancialSummary:FinancialSummaryInput = {
          add: [],
          remove: [],
        }
        currentColumnData.forEach((item) => {
          const originalRow = find(columnData, {rowId: item.rowId})
          if(JSON.stringify(item) !== JSON.stringify(originalRow)){
            if(originalRow && (item.date !== originalRow?.date || item.type?.code !== originalRow?.type?.code)){
              graphqlFinancialSummary.remove?.push({
                date: originalRow?.date,
                period: selectedDataPeriod === "month" ? FinancialSummaryPeriod.Monthly : FinancialSummaryPeriod.Quarterly,
                type: originalRow?.type?.code as ClientPortfolioReturnTypeCode,
              })
              graphqlFinancialSummary.add?.push({
                date: item.date,
                period: selectedDataPeriod === "month" ? FinancialSummaryPeriod.Monthly : FinancialSummaryPeriod.Quarterly,
                type: item.type?.code as ClientPortfolioReturnTypeCode,
                assets: item.assets,
                fee: item.fee,
                income: item.income,
                purchases: item.purchases,
                returnPercent: item.returnPercent,
                sales: item.sales,
              })
            } else {
              graphqlFinancialSummary.add?.push({
                date: item.date,
                period: selectedDataPeriod === "month" ? FinancialSummaryPeriod.Monthly : FinancialSummaryPeriod.Quarterly,
                type: item.type?.code as ClientPortfolioReturnTypeCode,
                assets: item.assets,
                fee: item.fee,
                income: item.income,
                purchases: item.purchases,
                returnPercent: item.returnPercent,
                sales: item.sales,
              })
            }
          }
        })
        columnData.forEach((item) => {
          if(!find(currentColumnData, {rowId: item.rowId})){
            graphqlFinancialSummary.remove?.push({
              date: item.date,
              period: selectedDataPeriod === "month" ? FinancialSummaryPeriod.Monthly : FinancialSummaryPeriod.Quarterly,
              type: item.type?.code as ClientPortfolioReturnTypeCode,
            })
          }
        })
        updatePlanPerformanceReturns({
          variables: {
            input: {
              id: portfolioId,
              patch: {
                financialSummary: graphqlFinancialSummary,
              }
            }
          }
        }).then(result => {
          setSaving(false)
          if (result && result.data) {
            setEditMode(false)
          }
        })
        .catch(err => {
          setSaving(false)
          console.error("Error plan Performance Returns", err.message)
        })
      },
    };
  }, [currentColumnData, columnData]);

  const addActive = editMode && !saving
  const addRow = () => {
    if(!addActive) return
    const latestDate = moment.max(...currentColumnData.map((item) => moment(item.date)))
    // const latestRow = find(currentColumnData, {date: latestDate.format("YYYY-MM-DD")})
    let updatedPreviousId = undefined
    const previousDate = moment(latestDate).format(DATE_API_FORMAT)
    currentColumnData.forEach((row) => {
      if(row.date === previousDate && row.type?.code === ClientPortfolioReturnTypeCode.ALL){
        updatedPreviousId = row.rowId
      }
    })
    const rowId = uniqueId()
    let newRow: ReturnColumnDataType = {
      rowId: `${rowId}`,
      date: latestDate.add(1, selectedDataPeriod === "month" ? "month" : "quarter").endOf("month").format("YYYY-MM-DD"),
      type: {
        code: ClientPortfolioReturnTypeCode.ALL,
        value: "Gross",
        __typename: "ClientPortfolioReturnTypeLookup",
      },
      origin: {
        code: "DNA",
        value: "DNA",
        __typename: "FinancialSummaryOriginCodeLookup",
      },
      assets: null,
      fee: null,
      income: null,
      purchases: null,
      returnPercent: null,
      sales: null,
      editable: true,
      updated: moment().format("YYYY-MM-DD"),
      previousId: updatedPreviousId,
      __typename: "ClientPortfolioFinancialSummary",
    }
    setCurrentColumnData([newRow, ...currentColumnData])
    if(gridApi){
      gridApi.paginationGoToPage(0);
      gridApi.ensureIndexVisible(0, "top")
    }
  }

  const deleteActive = editMode && !saving && selectedRows.length > 0
  const deleteRows = () => {
    if(!deleteActive) return
    setCurrentColumnData(currentColumnData.filter((item) => !selectedRows.includes(item)))
  }

  const quarterVisible = selectedDataPeriod === "month"
  const clickQuarter = () => {
    if(!quarterVisible || editMode)return
    setQuarterModalOpen(true)
  }

  const openWealthModal = () => {
    if(selectedRows.length !== 1) return
    setWealthModalOpen(true)
  }

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    setColumnApi(params.columnApi)
    params.columnApi.applyColumnState({state: [
      {colId: 'date', sort:"desc"},
      {colId: 'type.value', sort:"asc"},
    ]})
    params.api!.setFilterModel({
      'type.value': {
        filterType: 'set',
        values: ["Gross", "Net"]
      }
    })
  }

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    let updatedData = cloneDeep(currentColumnData)
    const row = find(updatedData, {rowId: params.data.rowId})
    let rowIndex = params.rowIndex || 0
    if(gridApi){
      const pageSize = gridApi.paginationGetPageSize();
      const pageNumber = Math.floor(rowIndex / pageSize);
      const currentPage = gridApi.paginationGetCurrentPage();
      if(currentPage !== pageNumber){
        gridApi.paginationGoToPage(pageNumber);
        gridApi.ensureIndexVisible(rowIndex, 'middle')
      }
    }
    if(row){
      row.touched = true
      setCurrentColumnData(updatedData)
    }
  }

  useEffect(() => {
    const hasChanged:boolean = JSON.stringify(columnData) !== JSON.stringify(currentColumnData)
    if(hasChanged){
      let badDates:string[] = []
      currentColumnData.forEach((item) => {
        if(item.touched){
          const originalRow = find(columnData, {rowId: item.rowId})
          if(!originalRow){
            let previousRow = find(columnData, {date: item.date, type: item.type})
            if(previousRow){
              badDates.push(moment(item.date).format(DATE_DISPLAY_FORMAT))
            }
          }
        }
      })
      if(badDates.length > 0){
        setWarningModal({
          heading: "Date Verification",
          message: `This date already exists. This will overwrite the row for the date ${badDates.join(", ")}. If this is correct, please click Save.`,
        })
      }
    }
    setValuesChanged(hasChanged)
  }, [currentColumnData])

  const colDef = PlanPerformanceReturnsColumnDef(setCurrentColumnData, selectedDataPeriod, editMode)
  return (
    <Container fluid className="d-flex flex-grow-1 flex-direction-column px-0">
      <RouteLeavingGuard
        when={editMode && valuesChanged}
        navigate={path => history.push(path)}
      />
      <div className="w-100 p-2 border-left border-right">
        {editMode &&
        <>
          <Button color={addActive ? "secondary" : "light"!} className="mr-auto" onClick={() => addRow()}>
            <span className={classNames({"text-gray-70": !addActive})}>
              Add Row
            </span>
            <FontAwesomeIcon
              icon="plus-circle"
              className={classNames("ml-2", {"text-blue-100": addActive, "text-gray-70": !addActive})}
            />
          </Button>
          <Button color={deleteActive ? "secondary" : "light" } className="mr-auto ml-2" onClick={() => deleteRows()}>
            <span className={classNames({"text-gray-70": !deleteActive})}>
              Delete Row
            </span>
            <FontAwesomeIcon
              icon="trash"
              className={classNames("ml-2", {"text-blue-100": deleteActive, "text-gray-70": !deleteActive})}
            />
          </Button>
        </>
        }
        <Button color={selectedRows.length === 1 ? "secondary" : "light" } id={"wealthmodal"} className="mr-auto ml-2" onClick={() => openWealthModal()}>
          <span className={classNames({"text-gray-70": selectedRows.length !== 1})}>
            Wealths
          </span>
        </Button>
        {selectedRows.length === 1 &&
          <WealthModal
            isOpen={wealthModalOpen}
            toggle={() => setWealthModalOpen(!wealthModalOpen)}
            portfolioId={portfolioId}
            selectedRow={selectedRows[0]}
            period={selectedDataPeriod === "month" ? FinancialSummaryPeriod.Monthly : FinancialSummaryPeriod.Quarterly}
          />
        }
        {quarterVisible && !editMode && <>
          <div className="border-left d-inline mr-2">
            <Button color={!editMode ? "secondary" : "light" } className="mr-auto ml-2" onClick={() => clickQuarter()}>
              <span className={classNames({"text-gray-70": editMode})}>
                Quarter Totals
              </span>
            </Button>
          </div>
        </>}
        <QuarterModal
          isOpen={quarterModalOpen}
          toggle={() => setQuarterModalOpen(!quarterModalOpen)}
          data={data}
        />
      </div>
      <SortableTable
        key={selectedDataPeriod}
        loading={false}
        filterText=""
        columnDefs={colDef}
        tableData={currentColumnData}
        rowId={"rowId"}
        editMode={editMode}
        setSelectedRows={setSelectedRows}
        onReady={onReady}
        onCellValueChanged={onCellValueChanged}
        exportParams={{allColumns: true}}
      />
    </Container>
  )
})

interface QuarterModalProps {
  isOpen: boolean
  toggle: () => void
  data: PlanPerformanceReturnsFragment
}

type HasData = {
  calculatedGross?: number | null
  calculatedNet?: number | null
  storedGross?: number | null
  storedNet?: number | null
}

type MonthData = HasData & {
  month: string
}

type QuarterData = HasData & {
  quarter: string
  months: MonthData[]
}

type YearData = {
  year:string
  quarters: QuarterData[]
}

const QuarterModal: React.FC<QuarterModalProps> = ({isOpen, toggle, data}) => {
  const [openItems, setOpenItems] = useState<string[]>([])
  let [years, exportData] = useMemo(() => {
    let years:YearData[] = []
    let exportData:ExtraRow[] = []
    let sortedCalculatedGrossMonth = cloneDeep(sortBy(data.monthlyGrossPerformance, (a) => -moment(a?.endDate).valueOf()))
    let sortedCalculatedGrossQuarter = cloneDeep(sortBy(data.quarterlyGrossPerformance, (a) => -moment(a?.endDate).valueOf()))
    let sortedStoredGrossMonth = cloneDeep(sortBy(data.financialSummaryMonthly?.filter((item) => item.type?.code === ClientPortfolioReturnTypeCode.ALL), (a) => -moment(a?.date).valueOf()))
    let sortedStoredGrossQuarter = cloneDeep(sortBy(data.financialSummaryQuarterly?.filter((item) => item.type?.code === ClientPortfolioReturnTypeCode.ALL), (a) => -moment(a?.date).valueOf()))
    let sortedCalculatedNetMonth = cloneDeep(sortBy(data.monthlyNetPerformance, (a) => -moment(a?.endDate).valueOf()))
    let sortedCalculatedNetQuarter = cloneDeep(sortBy(data.quarterlyNetPerformance, (a) => -moment(a?.endDate).valueOf()))
    let sortedStoredNetMonth = cloneDeep(sortBy(data.financialSummaryMonthly?.filter((item) => item.type?.code === ClientPortfolioReturnTypeCode.ALLN), (a) => -moment(a?.date).valueOf()))
    let sortedStoredNetQuarter = cloneDeep(sortBy(data.financialSummaryQuarterly?.filter((item) => item.type?.code === ClientPortfolioReturnTypeCode.ALLN), (a) => -moment(a?.date).valueOf()))
    let currentDate = moment.max(
      moment(first(sortedCalculatedGrossMonth)?.endDate),
      moment(first(sortedCalculatedGrossQuarter)?.endDate),
      moment(first(sortedStoredGrossMonth)?.date),
      moment(first(sortedStoredGrossQuarter)?.date),
      moment(first(sortedCalculatedNetMonth)?.endDate),
      moment(first(sortedCalculatedNetQuarter)?.endDate),
      moment(first(sortedStoredNetMonth)?.date),
      moment(first(sortedStoredNetQuarter)?.date),
    )
    let dataObject = {sortedCalculatedGrossMonth, sortedCalculatedGrossQuarter, sortedStoredGrossMonth, sortedStoredGrossQuarter, sortedCalculatedNetMonth, sortedCalculatedNetQuarter, sortedStoredNetMonth, sortedStoredNetQuarter}
    const quarterIttr = [4,3,2,1]
    const monthIttr = [0,1,2]
    exportData.push({values: ["Period","Calculated Gross","Stored Gross","Calculated Net","Stored Net"]})

    const fetchData = (date: Moment, period: "Month" | "Quarter") => {
      let useNextCalculatedGross = moment(first(dataObject[`sortedCalculatedGross${period}`])?.endDate).diff(date, "days") >= 0
      let useNextStoredGross = moment(first(dataObject[`sortedStoredGross${period}`])?.date).diff(date, "days") >= 0
      let useNextCalculatedNet = moment(first(dataObject[`sortedCalculatedNet${period}`])?.endDate).diff(date, "days") >= 0
      let useNextStoredNet = moment(first(dataObject[`sortedStoredNet${period}`])?.date).diff(date, "days") >= 0
      return {
        calculatedGross: useNextCalculatedGross ? dataObject[`sortedCalculatedGross${period}`].shift()?.value?.active : null,
        calculatedNet: useNextCalculatedNet ? dataObject[`sortedCalculatedNet${period}`].shift()?.value?.active : null,
        storedGross: useNextStoredGross ? dataObject[`sortedStoredGross${period}`].shift()?.returnPercent : null,
        storedNet: useNextStoredNet ? dataObject[`sortedStoredNet${period}`].shift()?.returnPercent : null,
        anyResults: useNextCalculatedGross || useNextStoredGross || useNextCalculatedNet || useNextStoredNet,
      }
    }

    // While there are still data objects to process
    while(dataObject.sortedCalculatedGrossMonth.length > 0 ||
      dataObject.sortedCalculatedGrossQuarter.length > 0 ||
      dataObject.sortedStoredGrossMonth.length > 0 ||
      dataObject.sortedStoredGrossQuarter.length > 0 ||
      dataObject.sortedCalculatedNetMonth.length > 0 ||
      dataObject.sortedCalculatedNetQuarter.length > 0 ||
      dataObject.sortedStoredNetMonth.length > 0 ||
      dataObject.sortedStoredNetQuarter.length > 0
    ){
      const yearStr = currentDate.format("YYYY")
      let currentYear:YearData = {
        year: yearStr,
        quarters: []
      }
      quarterIttr.forEach((quarter) => {
        let quarterResults = false
        let currentQuarterDate = moment(`${yearStr}-01-01`).add(quarter, "quarters").subtract(1, "day")
        let {anyResults, ...currentQuarterData} = fetchData(currentQuarterDate, "Quarter")
        quarterResults = anyResults || quarterResults
        let currentQuarter:QuarterData = {
          ...currentQuarterData,
          quarter: `Q${quarter}`,
          months: []
        }
        monthIttr.forEach((month) => {
          let currentMonthDate = moment(currentQuarterDate).subtract(month, "months").endOf("month")
          let {anyResults, ...currentMonthData} = fetchData(currentMonthDate, "Month")
          if(anyResults){
            currentQuarter.months.push({
              month: currentMonthDate.format("MMMM"),
              ...currentMonthData
            })
            quarterResults = true
          }
        })
        if(quarterResults){
          currentYear.quarters.push(currentQuarter)
          exportData.push({values: [
            `${yearStr} Q${quarter}`,
            currentQuarter.calculatedGross ? numbro(currentQuarter.calculatedGross/100).format(percentFormat) : "",
            currentQuarter.storedGross ? numbro(currentQuarter.storedGross).format(percentFormat) : "",
            currentQuarter.calculatedNet ? numbro(currentQuarter.calculatedNet/100).format(percentFormat) : "",
            currentQuarter.storedNet ? numbro(currentQuarter.storedNet).format(percentFormat) : "",
          ]})
          currentQuarter.months.forEach((monthData) => {
            exportData.push({values: [
              `${yearStr} ${monthData.month}`,
              monthData.calculatedGross ? numbro(monthData.calculatedGross/100).format(percentFormat) : "",
              monthData.storedGross ? numbro(monthData.storedGross).format(percentFormat) : "",
              monthData.calculatedNet ? numbro(monthData.calculatedNet/100).format(percentFormat) : "",
              monthData.storedNet ? numbro(monthData.storedNet).format(percentFormat) : "",
            ]})
          })
        }
      })
      years.push(currentYear)
      currentDate.subtract(1, "year")

    }
    return [years, exportData]
  }, [data])

  return(
    <Modal size="lg" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className='d-flex justify-content-between'>
          <div>Quarter Return Totals</div>
          <div onClick={() => toggle()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="exportable" data-export-name="quarter-returns-total" data-export-settings={JSON.stringify({extraRows: exportData})}></div>
        <div className="scrollable-modal-table-container">
          <Table hover className={"report-table scrollable-modal-table fixed-header equal-width"}>
            <thead>
              <tr className="border-bottom-0">
                <th className="text-left shadow-border-bottom shadow-olive-100">
                </th>
                <th className="text-center shadow-border-bottom shadow-olive-100" colSpan={2}>Gross</th>
                <th className="text-center shadow-border-bottom shadow-olive-100" colSpan={2}>Net</th>
              </tr>
              <tr className="">
                <th className="text-left second-row">
                </th>
                <th className="text-center second-row">Calculated</th>
                <th className="text-center second-row">Stored</th>
                <th className="text-center second-row">Calculated</th>
                <th className="text-center second-row">Stored</th>
              </tr>
            </thead>
            <tbody>
              {years.map((year) => {
                return(
                  <React.Fragment key={year.year}>
                    <tr key={year.year}>
                      <td colSpan={5} className="text-left font-weight-bold">
                        {year.year}
                      </td>
                    </tr>
                    {year.quarters.map((quarter) => {
                      return(
                        <QuarterRow
                          key={`${year.year}:${quarter.quarter}`}
                          data={quarter}
                          year={year.year}
                          setOpenItems={setOpenItems}
                          openItems={openItems}
                        />
                      )
                    })}
                  </React.Fragment>
                )

              })}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter className={classNames({"justify-content-between": true})}>
        <Button color="secondary btn-thin" className="ml-1 text-callan-blue" onClick={()=> exportTables()}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
        <Button color="primary" onClick={() => toggle()}>Done</Button>
      </ModalFooter>
    </Modal>
  )
}

interface QuarterRowProps {
  data: QuarterData
  year: string
  setOpenItems: (tabs: string[]) => void
  openItems: string[]
}
const QuarterRow: React.FC<QuarterRowProps> = (props) => {
  const {data, year, setOpenItems, openItems} = props
  const rowId = `${year}:${data.quarter}`
  let isOpen = openItems.includes(rowId)
  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(isOpen){
      let removedArray = clone(openItems)
      remove(removedArray, (id) => rowId === id)
      setOpenItems(removedArray)
    } else {
      setOpenItems(compact([...openItems, rowId]))
    }
  }
  let grossError = false
  let netError = false
  if(data.calculatedGross && data.storedGross && Math.abs(data.calculatedGross/100 - data.storedGross) > 0.0001){
    grossError = true
  }
  if(data.calculatedNet && data.storedNet && Math.abs(data.calculatedNet/100 - data.storedNet) > 0.0001){
    netError = true
  }
  return(
    <>
      <tr className={classNames("report-tr-title report-tr-total row-with-col-lines")}>
        <td className={classNames("text-left")} onClick={(e) => handleExpand(e)}>
          <div className={classNames("report-table-item has-subgroup")}>
            <div className="d-flex">
              <div className={`report-table-caret report-table-depth-0 `}>
                <FontAwesomeIcon
                  icon={isOpen ? "caret-down" : "caret-right"}
                  size="sm"
                />
              </div>
              <div>
                <div className={classNames("report-table-title report-table-total text-text-color")}>
                  <FootnotableComponent
                  >
                    {data.quarter}
                  </FootnotableComponent>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td className={classNames("text-left", {"negative-value": (!!data.calculatedGross && data.calculatedGross < 0)})}>
          <div className={classNames("position-relative")}>
            {grossError && <div className="position-absolute">
              <div id={`tooltip-${year}-${data.quarter}-gross`}>
                <FontAwesomeIcon icon="exclamation-triangle" className="text-red-100 mr-3"/>
              </div>
              <UncontrolledTooltip placement="top" target={`tooltip-${year}-${data.quarter}-gross`} className="tooltip-ontop-modal">
                Error: Difference found between calculated quarterly return from stored monthly returns and its equivalent stored quarterly return.
              </UncontrolledTooltip>
            </div>}
            <span className="pl-4">{data.calculatedGross == null ? "-" : `${numbro(data.calculatedGross/100).format({...percentFormat, negative: "sign"})}`}</span>
          </div>
        </td>
        <td className={classNames("text-left", {"negative-value": (!!data.storedGross && data.storedGross < 0)})}>
          <span className="pl-4">
            {data.storedGross == null ? "-" : `${numbro(data.storedGross).format({...percentFormat, negative: "sign"})}`}
          </span>
        </td>
        <td className={classNames("text-left", {"negative-value": (!!data.calculatedNet && data.calculatedNet < 0)})}>
          <div className={classNames("position-relative")}>
            {netError && <div className="position-absolute">
              <div id={`tooltip-${year}-${data.quarter}-net`}>
                <FontAwesomeIcon icon="exclamation-triangle" className="text-red-100 mr-3"/>
              </div>
              <UncontrolledTooltip placement="top" target={`tooltip-${year}-${data.quarter}-net`} className="tooltip-ontop-modal">
                Error: Difference found between calculated quarterly return from stored monthly returns and its equivalent stored quarterly return.
              </UncontrolledTooltip>
            </div>}
            <span className="pl-4">{data.calculatedNet == null ? "-" : `${numbro(data.calculatedNet/100).format({...percentFormat, negative: "sign"})}`}</span>
          </div>
        </td>
        <td className={classNames("text-left", {"negative-value": (!!data.storedNet && data.storedNet < 0)})}>
          <span className="pl-4">
            {data.storedNet == null ? "-" : `${numbro(data.storedNet).format({...percentFormat, negative: "sign"})}`}
          </span>
        </td>
      </tr>
      {isOpen && data.months.map((month, idx) => {
        return(
          <MonthRow
            key={`${rowId}:${month.month}`}
            data={month}
            year={year}
          />
        )
      })}
    </>
  )
}

interface MonthRowProps {
  year: string
  data: MonthData
}
const MonthRow: React.FC<MonthRowProps> = (props) => {
  const {data, year} = props
  let grossError = false
  let netError = false
  if(data.calculatedGross && data.storedGross && Math.abs(data.calculatedGross/100 - data.storedGross) > 0.0001){
    grossError = true
  }
  if(data.calculatedNet && data.storedNet && Math.abs(data.calculatedNet/100 - data.storedNet) > 0.0001){
    netError = true
  }
  return(
    <tr className={classNames("report-tr-title row-with-col-lines")}>
      <td className={classNames("text-left")}>
        <div className={classNames("report-table-item has-subgroup")}>
          <div style={{marginLeft: 10}} className="d-flex">
            <div className={`report-table-caret report-table-depth-1`}>
            </div>
            <div>
              <div className={classNames("report-table-title report-table-total font-weight-normal text-text-color")}>
                <FootnotableComponent
                >
                  {data.month}
                </FootnotableComponent>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className={classNames("text-left", {"negative-value": (!!data.calculatedGross && data.calculatedGross < 0)})}>
          <div className={classNames("position-relative")}>
            {grossError && <div className="position-absolute">
              <div id={`tooltip-${year}-${data.month}-gross`}>
                <FontAwesomeIcon icon="exclamation-triangle" className="text-red-100 mr-3"/>
              </div>
              <UncontrolledTooltip placement="top" target={`tooltip-${year}-${data.month}-gross`} className="tooltip-ontop-modal">
                Error: Difference found between calculated quarterly return from stored monthly returns and its equivalent stored quarterly return.
              </UncontrolledTooltip>
            </div>}
            <span className="pl-4">{data.calculatedGross == null ? "-" : `${numbro(data.calculatedGross/100).format({...percentFormat, negative: "sign"})}`}</span>
          </div>
        </td>
        <td className={classNames("text-left", {"negative-value": (!!data.storedGross && data.storedGross < 0)})}>
          <span className="pl-4">
            {data.storedGross == null ? "-" : `${numbro(data.storedGross).format({...percentFormat, negative: "sign"})}`}
          </span>
        </td>
        <td className={classNames("text-left", {"negative-value": (!!data.calculatedNet && data.calculatedNet < 0)})}>
          <div className={classNames("position-relative")}>
            {netError && <div className="position-absolute">
              <div id={`tooltip-${year}-${data.month}-net`}>
                <FontAwesomeIcon icon="exclamation-triangle" className="text-red-100 mr-3"/>
              </div>
              <UncontrolledTooltip placement="top" target={`tooltip-${year}-${data.month}-net`} className="tooltip-ontop-modal">
                Error: Difference found between calculated quarterly return from stored monthly returns and its equivalent stored quarterly return.
              </UncontrolledTooltip>
            </div>}
            <span className="pl-4">{data.calculatedNet == null ? "-" : `${numbro(data.calculatedNet/100).format({...percentFormat, negative: "sign"})}`}</span>
          </div>
        </td>
        <td className={classNames("text-left", {"negative-value": (!!data.storedNet && data.storedNet < 0)})}>
          <span className="pl-4">
            {data.storedNet == null ? "-" : `${numbro(data.storedNet).format({...percentFormat, negative: "sign"})}`}
          </span>
        </td>
    </tr>
  )
}

interface WealthModalProps {
  isOpen: boolean
  toggle: () => void
  portfolioId: number
  period: FinancialSummaryPeriod
  selectedRow: ReturnColumnDataType
}

export const WealthModal: React.FC<WealthModalProps> = (props) => {
  const {isOpen, toggle, portfolioId, selectedRow, period} = props
  const {data, error, loading} = usePlanPerformanceWealthQuery({
    variables: {
      id: portfolioId,
      date: selectedRow.date,
      period: period,
      returnType: selectedRow.type?.code || ClientPortfolioReturnTypeCode.ALL,
    },
  })

  let niceDate = moment(selectedRow.date).format(period === FinancialSummaryPeriod.Monthly ? "MMMM YYYY" : "YYYY Qo")
  let returnTypeChar = ""
  if(selectedRow.type?.code === ClientPortfolioReturnTypeCode.ALL)returnTypeChar =  "G"
  if(selectedRow.type?.code === ClientPortfolioReturnTypeCode.ALLN)returnTypeChar =  "N"

  const [sortedDailyWealths, openData, closeData, exportData] = useMemo(() => {
    if(data && data.clientPortfolio?.dailyWealths){
      let sortedDailyWealths = cloneDeep(sortBy(data.clientPortfolio.dailyWealths, (a) => moment(a?.date).valueOf()))
      let exportData:ExtraRow[] = []
      exportData.push({values: ["Date","Open","Close","Cash Flow","Weighted Cash Flow","Daily Return","Daily Return Factor Form","Cumulative Return","Cumulative Return Factor Form"]})

      let openData:(number|Date|string)[][] = []
      let closeData:(number|Date|string)[][] = []
      sortedDailyWealths.forEach((dailyWealth, idx) => {
        let skipDate = (idx === 0 || idx === sortedDailyWealths.length -1)
        if(dailyWealth?.openMarketValue || dailyWealth?.openMarketValue === 0) openData.push([moment(dailyWealth?.date).valueOf(), dailyWealth?.openMarketValue])
        if(dailyWealth?.closeMarketValue || dailyWealth?.closeMarketValue === 0) closeData.push([moment(dailyWealth?.date).valueOf(), dailyWealth?.closeMarketValue])
        exportData.push({values: [
          skipDate ? "" : `${moment(dailyWealth?.date).format(DATE_DISPLAY_FORMAT)}${dailyWealth?.tradeDay ? "" : "*"}`,
          (dailyWealth?.openMarketValue || dailyWealth?.openMarketValue === 0) ? numbro(dailyWealth.openMarketValue).format(currencyFormat) : "",
          (dailyWealth?.closeMarketValue || dailyWealth?.closeMarketValue === 0) ? numbro(dailyWealth.closeMarketValue).format(currencyFormat) : "",
          (dailyWealth?.cashFlow || dailyWealth?.cashFlow === 0) ? numbro(dailyWealth.cashFlow).format(currencyFormat) : "",
          (dailyWealth?.weightedCashFlow || dailyWealth?.weightedCashFlow === 0) ? numbro(dailyWealth.weightedCashFlow).format(rankingFormat) : "",
          (dailyWealth?.dailyReturn || dailyWealth?.dailyReturn === 0) ? numbro(dailyWealth.dailyReturn).format(longPercentFormat) : "",
          (dailyWealth?.dailyReturn || dailyWealth?.dailyReturn === 0) ? numbro(1+dailyWealth.dailyReturn).format(largeMantissaFormat) : "",
          (dailyWealth?.cumulativeReturn || dailyWealth?.cumulativeReturn === 0) ? numbro(dailyWealth.cumulativeReturn).format(longPercentFormat) : "",
          (dailyWealth?.cumulativeReturn || dailyWealth?.cumulativeReturn === 0) ? numbro(1+(dailyWealth?.cumulativeReturn || 0)).format(largeMantissaFormat) : "",
        ]})
      })
      return [sortedDailyWealths,  openData, closeData, exportData]
    }
    return [[],[], [], []]
  }, [data])

  let bodyContent = <></>
  if(loading){
    bodyContent = <LoadingOverlay />
  } else if(error){
    bodyContent = <ErrorDisplay error={error} />
  } else if(data && data.clientPortfolio){
    bodyContent = <>
      <HighchartsReact
        options={WealthLineOptions({
          openData,
          closeData,
          period,
        })}
        highcharts={Highcharts}
        containerProps={{ style: { height: `400px` } }}
      />
      <div className="exportable" data-export-name={`wealth-${portfolioId}-${returnTypeChar}-${moment(selectedRow.date).format(DATE_API_FORMAT)}`} data-export-settings={JSON.stringify({extraRows: exportData})}></div>
      <div className="scrollable-modal-table-half-container">
        <Table hover className={"report-table scrollable-modal-table fixed-header"}>
          <thead>
            <tr className="">
              <th className="text-left text-uppercase font-weight-normal background-gray-10">Date</th>
              <th className="text-left text-uppercase font-weight-normal background-gray-10">Open</th>
              <th className="text-left text-uppercase font-weight-normal background-gray-10">Close</th>
              <th className="text-left text-uppercase font-weight-normal background-gray-10">Cash Flow</th>
              <th className="text-left text-uppercase font-weight-normal background-gray-10">
                Weighted Cash Flow
                <div className="d-inline-flex align-items-center tooltip-icon" id="WeightedCashFlowTooltip">
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              </th>
              <th className="text-left text-uppercase font-weight-normal background-gray-10">Daily Return</th>
              <th className="text-left text-uppercase font-weight-normal background-gray-10">Cumulative Return</th>
              <th className="text-left text-uppercase font-weight-normal background-gray-10"></th>
            </tr>
          </thead>
          <tbody>
            {sortedDailyWealths.map((dailyWealth, idx) => {
              return(
                <React.Fragment key={dailyWealth?.date}>
                  <tr className={classNames("row-with-col-lines", {"background-gray-10": !dailyWealth?.tradeDay})}>
                    <td className="text-left py-2">
                      {!(idx === 0 || idx === sortedDailyWealths.length -1) && `${moment(dailyWealth?.date).format(DATE_DISPLAY_FORMAT)}${dailyWealth?.tradeDay ? "" : "*"}`}
                    </td>
                    <td className="text-left py-2">
                      {(dailyWealth?.openMarketValue || dailyWealth?.openMarketValue === 0) ? numbro(dailyWealth.openMarketValue).format(currencyFormat) : ""}
                    </td>
                    <td className="text-left py-2">
                      {(dailyWealth?.closeMarketValue || dailyWealth?.closeMarketValue === 0) ? numbro(dailyWealth.closeMarketValue).format(currencyFormat) : ""}
                    </td>
                    <td className="text-left py-2">
                      {(dailyWealth?.cashFlow || dailyWealth?.cashFlow === 0) ? numbro(dailyWealth.cashFlow).format(currencyFormat) : ""}
                    </td>
                    <td className="text-right py-2">
                      {(dailyWealth?.weightedCashFlow || dailyWealth?.weightedCashFlow === 0) ? numbro(dailyWealth.weightedCashFlow).format(rankingFormat) : ""}
                    </td>
                    <td className="text-right py-2">
                      {(dailyWealth?.dailyReturn || dailyWealth?.dailyReturn === 0) ? numbro(dailyWealth.dailyReturn).format(percentFormat) : ""}
                    </td>
                    <td className="text-right py-2">
                      {(dailyWealth?.cumulativeReturn || dailyWealth?.cumulativeReturn === 0) ? numbro(dailyWealth.cumulativeReturn).format(percentFormat) : ""}
                    </td>
                    <td className="text-right py-2">
                      {!dailyWealth?.tradeDay && !(dailyWealth?.cumulativeReturn || dailyWealth?.cumulativeReturn !== 0) && <div className="">
                        <div id={`tooltip-${dailyWealth.date}`} className="px-0 mr-2">
                          <FontAwesomeIcon icon="exclamation-triangle" className="text-red-100"/>
                        </div>
                        <UncontrolledTooltip placement="top" target={`tooltip-${dailyWealth.date}`} className="tooltip-ontop-modal">
                          Error: Cumulative Return present on a non-trade day.
                        </UncontrolledTooltip>
                      </div>}
                    </td>
                  </tr>
                </React.Fragment>
              )

            })}
          </tbody>
        </Table>
      </div>
    </>
  }

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className='d-flex justify-content-between'>
          <div>Wealth ID {portfolioId} - {niceDate} {returnTypeChar}OF</div>
          <div onClick={() => toggle()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {bodyContent}
      </ModalBody>
      <ModalFooter className={classNames({"justify-content-between": true})}>
        <Button color="secondary btn-thin" className="ml-1 text-callan-blue" onClick={()=> exportTables()}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
        <Button color="primary" onClick={() => toggle()}>Done</Button>
      </ModalFooter>
    </Modal>
  )
}
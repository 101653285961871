import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface AssetValidationProps {
	modalOpen: boolean
	setModalOpen: (value: boolean) => void
}

export const AssetValidation: React.FC<AssetValidationProps>= ({
	modalOpen,
	setModalOpen,
	}) => {
	const closeModal = () => {
			setModalOpen(false)
		}
	return(
		<>
		<Modal size="md" className="modal-dialog-centered" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
		<ModalHeader className="fee-modal-header full-width-header">
			<div className="d-flex justify-content-between">
				<div>
				</div>
				<div onClick={() => closeModal()}>
					<FontAwesomeIcon
						icon="times"
						className="ml-auto"
					/>
				</div>
			</div>
		</ModalHeader>
		<ModalBody>
		<h5 className="d-flex justify-content-between">Please make sure that all asset values are entered in Millions instead of Dollars.</h5>
		</ModalBody>
		<ModalFooter>
		<Button onClick={() => closeModal()}>Continue Editing</Button>
	</ModalFooter>
		</Modal>
		</>
	)
	}
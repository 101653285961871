import React, { Component } from "react"
import {
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
} from "reactstrap"
import { renderToString } from 'react-dom/server'
import { CellPosition, CsvExportParams, FilterChangedEvent, GridApi, GridReadyEvent, IRowNode, PaginationChangedEvent, RowHeightParams, RowSelectedEvent, SelectionChangedEvent, TabToNextCellParams, FirstDataRenderedEvent } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { CellValueChangedEvent, ColDef, ExcelExportParams, GetRowIdParams } from '@ag-grid-community/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"

import AumTooltip from '../../helpers/agGridAumTooltip'
import { get, isNumber } from "lodash"
import classNames from "classnames"
import AgGridFromEditor from "./AgGridFormEditor"
import MessageTooltip from "../../helpers/agGridMessageTooltip"
import LoadingOverlay from "../ui/Loading"
import { AgGridContext } from "../../Context/AgGridContext"
import AgGridDatePickerEditor from "./AgGridDatePickerEditor"

interface MasterDetailSettingProps {
  detailCellRendererParams?: any
  detailRowAutoHeight?: boolean
}

interface SortableTableProps {
  loading?: boolean
  filterText: string
  columnDefs: any
  tableData: any
  onReady?: (api:GridReadyEvent) => void
  onGridColumnsChanged?: () => void
  onFilterChanged?: (event: FilterChangedEvent) => void
  onFirstDataRendered?: (value: FirstDataRenderedEvent) => void
  rowId?: string
  getRowId?: (params: GetRowIdParams) => string
  noPagination?: boolean
  rowClassRules?: {[cssClassName: string]: string | ((params: any) => boolean)}
  subClasses?: { [name in SortableTableSubClasses]?: string }
  suppressFocusAfterRefresh?: boolean
  exportParams?: CsvExportParams | ExcelExportParams
  setSelectedRows?:(rows: any[]) => void
  onRowSelected?:(node: any, data: any) => void
  treeData?: boolean
  getDataPath?: (data: any) => string[]
  autoGroupColumnDef?: any
  // property for editable cells
  columnTypes?: { [key: string]: ColDef }
  editMode?: boolean
  suppressExport?: {csv?: boolean, excel?: boolean}
  onCellValueChanged?: (params: CellValueChangedEvent) => void
  singleRowSelection?: boolean
  getRowHeight?: (params: RowHeightParams) => any
  masterDetail?: boolean
  masterDetailSetting?: MasterDetailSettingProps
  groupDefaultExpandedOverride?: number
}

const sortableTableSubClasses = [
  "tableWrapperClasses",
  "paginationWrapperClasses",
  "tableContainerClasses"
] as const
export type SortableTableSubClasses = typeof sortableTableSubClasses[number]

const Loading = () => renderToString(<LoadingOverlay/>)

export default class SortableTable extends Component<SortableTableProps> {
  state = {
    api: {} as GridApi,
    pageSize: 25,
    pageSizeMenu: false,
    apiInitialized: false,
    pageState: {
      currentPage: 1,
      maxPage: 1,
    },
    totalResults: 0,
    lastEditedNode: null as null | IRowNode<any>,
  }

  static contextType = AgGridContext

  componentWillUnmount = () =>{
    this.context.setGrid(undefined)
  }

  /* Grid Events we're listening to */
  onGridReady = (event: GridReadyEvent) => {
    if(this.props.onReady) this.props.onReady(event)
    if (this.props.loading) {
      event.api.showLoadingOverlay()
    } else {
      event.api.hideOverlay()
    }
    this.setState({
      pageState: {
        currentPage: event.api.paginationGetCurrentPage() +1,
        maxPage: event.api.paginationGetTotalPages()
      },
      totalResults: event.api.paginationGetRowCount(),
      api: event.api,
      apiInitialized:true,
    })
    this.context.setGrid(event.api)
  }

  onBtPrevious = () => {
    this.state.api.paginationGoToPreviousPage();
  };

  toPage = (page:number) => {
    this.state.api.paginationGoToPage(page - 1);
  };

  onBtNext = () => {
    this.state.api.paginationGoToNextPage();
  };

  onPaginationChanged = (event: PaginationChangedEvent) => {
    // add newPage, newData to fix rerendering issue
    let {newPage, newData} = event
    if(!this.state.apiInitialized) {
      return
    }
    // add countChange to fix result count not updating issue
    let countChange = this.state.api.paginationGetRowCount() !== this.state.totalResults
    let maxPageChange = this.state.api.paginationGetTotalPages() !== this.state.pageState.maxPage
    if (newPage || newData || countChange || maxPageChange) {
      this.setState({
        pageState: {
          currentPage: this.state.api.paginationGetCurrentPage() +1,
          maxPage: this.state.api.paginationGetTotalPages()
        },
        totalResults: this.state.api.paginationGetRowCount(),
      })
    }
  }

  setPaginationSize = (pageSize:number) => {
    this.setState({pageSize: pageSize})
    this.state.api.paginationSetPageSize(pageSize)
  }

  createPaginationNumbers = () => {
    let numbers = []
    let lastRendered = true
    for (let i = 1; i <= this.state.pageState.maxPage; i++){
      if (i === 1 || i === this.state.pageState.maxPage || (i >= this.state.pageState.currentPage - 2 && i <= this.state.pageState.currentPage + 2)) {
        lastRendered = true
        numbers.push(
          <Button key={i} className={classnames({ active: this.state.pageState.currentPage === i })} onClick={() =>  this.toPage(i)} color="page-number">
            {i}
          </Button>
        );
      } else if (lastRendered){
        lastRendered = false
        numbers.push(
          <Button key={i} color="page-number">
              ...
          </Button>
        );
      }
    }

    return numbers
  }

  onSelectionChanged = (event: SelectionChangedEvent) => {
    if(this.props?.setSelectedRows) {
      let selectedRows = event?.api?.getSelectedRows() || []
      this.props.setSelectedRows(selectedRows)
    }
  }

  onRowSelected = (event: RowSelectedEvent) => {
    if(this.props?.onRowSelected) {
      this.props.onRowSelected(event?.node, event?.data)
    }
  }

  tabToNextCell = (params: TabToNextCellParams): CellPosition | null => {
    if(!params.nextCellPosition) return null
    const previousCell = params.previousCellPosition
    const nextCell = params.nextCellPosition
    let nextRowIndex = nextCell?.rowIndex || previousCell.rowIndex
    if(previousCell.rowIndex === nextCell?.rowIndex) {
      if(this.state.lastEditedNode && this.state.lastEditedNode.rowIndex !== null) {
        nextRowIndex = this.state.lastEditedNode.rowIndex
      }
    }
    const result = {
      rowIndex: nextRowIndex,
      column: nextCell?.column || previousCell.column,
      rowPinned: previousCell.rowPinned,
    };
    return result;
  }

  table() {
    const isMasterDetailsGrid = !!this.props.masterDetail
    const groupDefaultExpandedOverride = isNumber(this.props.groupDefaultExpandedOverride)? this.props.groupDefaultExpandedOverride: -1
    return (
      <AgGridReact
        // ref="donot"
        // listening for events

        // onBodyScroll={this.onBodyScroll}
        // onRowSelected={this.onRowSelected}
        // onCellClicked={this.onCellClicked}
        // binding to simple properties
        quickFilterText={this.props.filterText}
        key="unchanged"
        // enableBrowserTooltips={true}
        tooltipShowDelay={0}
        // binding to an object property
        // binding to array properties
        defaultCsvExportParams={this.props.exportParams as CsvExportParams || {}}
        defaultExcelExportParams={this.props.exportParams as ExcelExportParams || {}}
        // deltaRowDataMode={true}
        onRowSelected={this.onRowSelected}
        onSelectionChanged={this.onSelectionChanged}
        defaultColDef={{
          minWidth: 50,
          resizable: true,
          // sortable: true,
          filter: 'agSetColumnFilter',
          menuTabs: ['filterMenuTab'],
          headerComponentParams: {
            menuIcon: "fa-bars",
          }
        }}
        // fix double icons using headerTemplate.
        icons={{
          sortAscending: renderToString(<FontAwesomeIcon icon={"arrow-down"} className="ml-1"/>),
          sortDescending: renderToString(<FontAwesomeIcon icon={"arrow-up"} className="ml-1"/>),
          filter: renderToString(<FontAwesomeIcon icon="filter"/>),
          tooltip: renderToString(<FontAwesomeIcon icon="question-circle" className="mx-1"/>),
        }}
        rowData={this.props.tableData}
        // rowBuffer={0}
        pagination={!this.props.noPagination}
        paginationPageSize={this.state.pageSize}
        suppressCsvExport={!!this.props.suppressExport?.csv}
        suppressExcelExport={!!this.props.suppressExport?.excel}
        suppressPaginationPanel={true}
        onPaginationChanged={this.onPaginationChanged}
        onGridColumnsChanged={this.props.onGridColumnsChanged}
        onFilterChanged={this.props.onFilterChanged}
        getRowId={(params: GetRowIdParams) => this.props.getRowId? this.props.getRowId(params): get(params.data, this.props.rowId || "id")}
        // cacheOverflowSize={2}
        // maxConcurrentDatasourceRequests={1}
        // infiniteInitialRowCount={2000}
        // maxBlocksInCache={10}
        // no binding, just providing hard coded strings for the properties
        // boolean properties will default to true if provided (ie suppressRowClickSelection => suppressRowClickSelection="true")
        // suppressRowClickSelection
        rowSelection={this.props.singleRowSelection ? "single" : "multiple"}
        rowClassRules={this.props.rowClassRules}
        // rowModelType="infinite"
        // setting default column properties
        // so you can copy from table cell
        enableCellTextSelection={true}
        ensureDomOrder={true}
        // suppressPropertyNamesCheck={true}
        suppressFocusAfterRefresh={this.props.suppressFocusAfterRefresh}
        // debug={true}
        animateRows={true}
        onGridReady={this.onGridReady}
        onFirstDataRendered={this.props.onFirstDataRendered}
        suppressScrollOnNewData={true}
        components={{aumTooltip: AumTooltip, formInputComponent: AgGridFromEditor, formDatePickerComponent: AgGridDatePickerEditor, messageTooltip: MessageTooltip}}
        getDataPath={this.props.getDataPath}
        treeData={this.props.treeData}
        autoGroupColumnDef={this.props.autoGroupColumnDef}
        groupDefaultExpanded={groupDefaultExpandedOverride}
        suppressMenuHide={true} // always show column hamburger menu
        // edit
        columnDefs={this.props.columnDefs}
        columnTypes={this.props.columnTypes}
        suppressClickEdit={!this.props.editMode}
        stopEditingWhenCellsLoseFocus={true}
        singleClickEdit={this.props.editMode}
        onCellValueChanged = {(params: CellValueChangedEvent) => {
          // const colId = params.column.getId()
          // if (colId === 'dueDates') {
          //   console.log(244, {params, colId})
          // }
          this.props.onCellValueChanged?.(params)
        }}
        //fix error of ResizeObserver for v29. could be removed on v30. https://github.com/ag-grid/ag-grid/issues/6562
        alwaysShowHorizontalScroll={true}
        overlayLoadingTemplate={Loading()}
        getRowHeight={this.props.getRowHeight}
        // Master Detail Grid below
        masterDetail={isMasterDetailsGrid}
        detailCellRendererParams={isMasterDetailsGrid? this.props.masterDetailSetting?.detailCellRendererParams: undefined}
        detailRowAutoHeight={isMasterDetailsGrid? this.props?.masterDetailSetting?.detailRowAutoHeight: undefined}
        tabToNextCell={this.tabToNextCell}
        onCellEditingStarted={(event) => {this.setState({lastEditedNode: event.node})}}
      />
    )
  }

  pagination() {
    return (
      <>
        <div>
          <Button onClick={() => this.onBtPrevious()} color="link" className="mr-1">
            <FontAwesomeIcon
              icon="chevron-left"
              className="ml-2"
            />
          </Button>
          {this.createPaginationNumbers()}
          <Button onClick={() => this.onBtNext()} color="link" className="mr-1">
            <FontAwesomeIcon
              icon="chevron-right"
              className="ml-2"
            />
          </Button>
          <ButtonDropdown isOpen={this.state.pageSizeMenu} toggle={()=> this.setState({pageSizeMenu: !this.state.pageSizeMenu})} className="mr-1">
            <DropdownToggle caret>
              {this.state.pageSize} per page
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={()=> this.setPaginationSize(5)}>5 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(10)}>10 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(25)}>25 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(50)}>50 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(100)}>100 per page</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          viewing rows {this.state.pageSize * (this.state.pageState.currentPage - 1) + 1} - {Math.min(this.state.totalResults, this.state.pageSize * (this.state.pageState.currentPage))} out of {this.state.totalResults} results.
        </div>
      </>
    )
  }

  render() {
    let tableWrapperClasses = this.props.subClasses?.tableWrapperClasses || ""
    let paginationWrapperClasses = this.props.subClasses?.paginationWrapperClasses || ""
    // let minHeight = Math.min(this.state.pageSize, this.state.totalResults) * 28 + 52 //Don't use as dropdowns are constrained to table
    // pages require REACT.fragments instead of div for containing page
    let tableContainerClasses = this.props.subClasses?.tableContainerClasses || ""
    return (
      <>
        <div className={classNames("pane pane-table p-0 d-flex flex-grow-1", tableWrapperClasses)}>
          <Row className="flex-grow-1">
            <Col className={classNames("detail-table-container ag-grid ag-grid-view ag-theme-balham", tableContainerClasses, {"col-md-9 pr-0":this.props.children})}>
                {this.table()}
            </Col>
            {this.props.children && this.props.children}
          </Row>
        </div>
        {!this.props.noPagination &&
          <Row className={classNames("py-2", paginationWrapperClasses)}>
            <Col>
              {this.pagination()}
            </Col>
          </Row>
        }
      </>
    )
  }
}

import { Query } from '@apollo/client/react/components'
import { QueryLazyOptions } from '@apollo/client'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ApolloError } from '@apollo/client'
import classnames from "classnames"
import DOMPurify from 'dompurify'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { History } from 'history'
import _, { cloneDeep, filter, findIndex, first, last, remove, sortBy, multiply, isNull } from "lodash"
import moment from 'moment'
import numbro from 'numbro'
import React, { Component } from "react"
import { useHistory } from "react-router-dom"
import {
  Button, Col, Container, ModalBody,
  ModalHeader, Nav,
  NavItem,
  NavLink, Row, Table
} from "reactstrap"

import Auth from "../../Auth/Auth"
import { appDate } from '../../Context/CalendarContext'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "../../helpers/constant"
import handleZeroAndNull from "../../helpers/handleZeroAndNull"
import { listQuarters, ManagerType, numbroFormatter, statusColor } from '../../helpers/helpers'
import { graphColors, GraphData, PercentAreaOptions, PieData, ProfileAssetPieOptions, ProfileEmployeePieOptions, ProfileLineOptions, ProfileStackedColumnOptions } from "../../helpers/highCharts"
import { PRODUCT_PROFILE_STRATEGY_QUERY } from "../../queries/Product"
import {
  AssetsByClient,
  CashFlowPerformance,
  ClientPortfolio,
  ClosedEndedFields,
  ClosedEndedHedgeFund,
  ClosedEndedPrivateCredit,
  ClosedEndedPrivateEquity,
  ClosedEndedPrivateNonRegisteredMulitAssetClass,
  ClosedEndedPrivateNonRegisteredPrivateCredit,
  ClosedEndedPrivateNonRegisteredPrivateEquity,
  ClosedEndedPrivateNonRegisteredRealAssets,
  ClosedEndedRealAssets,
  DownloadProductFactSheetQuery,
  Exact,
  Fact,
  FeeAtAum,
  File, GlidePathVersion,
  Interaction,
  ManagerSearchInfo,
  Maybe,
  OpenEndedCollectiveInvestmentFund,
  OpenEndedCollectiveInvestmentFundComposite,
  OpenEndedCollectiveInvestmentFundStableValue,
  OpenEndedCollectiveInvestmentFundStableValueComposite,
  OpenEndedEquity,
  OpenEndedExchangeTradedFund,
  OpenEndedFixedIncome,
  OpenEndedHedgeFund,
  OpenEndedMac,
  OpenEndedMutualFund,
  OpenEndedPassiveEquity,
  OpenEndedPassiveFixedIncome,
  OpenEndedPassiveMac,
  OpenEndedPooledVehicle,
  OpenEndedPooledVehiclePrivateCredit,
  OpenEndedPooledVehiclePrivateEquity,
  OpenEndedPooledVehicleRealAssets,
  OpenEndedPrivateNonRegisteredHedgeFund,
  OpenEndedRealAssets,
  OpenEndedSeparateAccount,
  OpenEndedSeparateAccountRealAssets,
  OpenEndedSeparateAccountStableValue,
  OpenEndedTargetDate,
  OpenEndedVariableAnnuity,
  Opinion,
  OtherProduct,
  OtherVehicle, Performance, PooledVehicleFields, ProductOpinion,
  ProductPerson,
  ProductProfileQuery,
  ProductProfileStrategyQuery,
  ProductRelatedClientExposureTotal,
  ProductRelatedClientPlanExposure,
  ProductRelatedClientPortfolioExposure,
  useDownloadProductFactSheetLazyQuery,
  useProductProfileQuery,
  Vehicle,
} from "../../__generated__/graphql"
import { ChartContainer } from "../Shared/ChartContainer"
import ErrorDisplay from '../Shared/ErrorDisplay'
import { GuardModal, ModalScript } from '../Shared/RouteLeavingGuard'
import { InteractionIcon } from '../ui/Icon'
import PlaceHolder from "./../ui/PlaceHolder"
import exportTables from "../../helpers/exportTable"

const ONE_MILLION = 1_000_000

type ProductTypes = OpenEndedEquity | OpenEndedPassiveEquity | OpenEndedFixedIncome | OpenEndedPassiveFixedIncome | OpenEndedHedgeFund | ClosedEndedHedgeFund | OpenEndedMac | OpenEndedPassiveMac | OpenEndedTargetDate | ClosedEndedPrivateCredit | ClosedEndedPrivateEquity | ClosedEndedRealAssets | OpenEndedRealAssets | OtherProduct
type OpenEndedProductTypes = OpenEndedEquity | OpenEndedPassiveEquity | OpenEndedFixedIncome | OpenEndedPassiveFixedIncome | OpenEndedHedgeFund | OpenEndedMac | OpenEndedPassiveMac | OpenEndedTargetDate | OpenEndedRealAssets
type VehicleType = OpenEndedMutualFund | OpenEndedExchangeTradedFund | OpenEndedPooledVehicle | OpenEndedVariableAnnuity | OpenEndedCollectiveInvestmentFundComposite | OpenEndedSeparateAccount | ClosedEndedPrivateNonRegisteredMulitAssetClass | ClosedEndedPrivateNonRegisteredPrivateCredit | OpenEndedPooledVehiclePrivateEquity | ClosedEndedPrivateNonRegisteredPrivateEquity | OpenEndedPooledVehicleRealAssets | ClosedEndedPrivateNonRegisteredRealAssets | OpenEndedSeparateAccountRealAssets | OpenEndedPrivateNonRegisteredHedgeFund | OpenEndedCollectiveInvestmentFundStableValueComposite | OpenEndedCollectiveInvestmentFundStableValue | OpenEndedSeparateAccountStableValue | OpenEndedCollectiveInvestmentFund | OpenEndedPooledVehiclePrivateCredit | OtherVehicle

interface idProps {
  id: number
  auth: Auth
  managerType?: ManagerType
}

interface writeUpList {
  [key: string]: Fact | ProductOpinion
}

interface EmployeeCode {
  __typename: string
  code: string
  value: any
}

interface SortedEmployees {
  __typename?: string | null
  count?: number | null
  date?: string | null
  type: EmployeeCode
}

/**
 * @desc    Formats gross net value objects
 * @param   {any} value the target
 * @param   {any} postfix [postfix='%']
 * @returns {String} Returns formated string
 * */
const grossNetString = (value:any, postfix: any = '%') => {
  if(!value){
    return " - "
  }

  let valueNet:string, valueGross:string
  if(value.net?.low && value.net?.high){
    valueNet = `${value.net?.low}-${value.net?.high}${postfix}`
  } else if(value.net?.target){
    valueNet = `${value.net?.target}${postfix}`
  } else {
    valueNet = ""
  }

  if(value.gross?.low && value.gross?.high){
    valueGross = `${value.gross?.low}-${value.gross?.high}${postfix}`
  } else if(value.gross?.target){
    valueGross = `${value.gross?.target}${postfix}`
  } else {
    valueGross = ""
  }

  if(valueNet === "" && valueGross === ""){
    return " - "
  }

  return `${valueGross}/${valueNet}`
}

const getRepresentativeVehicle = (vehicles?: VehicleType[] | null) => {
  if(!vehicles || vehicles.length === 0){
    return undefined
  } else if (vehicles.length === 1) {
    return _.first(vehicles)
  } else {
    const representativeVehicle = _.find(vehicles, (vehicle) => vehicle.vehicle?.isRepresentative) as VehicleType
    if(representativeVehicle){
      return representativeVehicle
    } else {
      return _.first(vehicles)
    }
  }
}
const performanceMonthlyDate = (moment().date() >= 9) ? moment().subtract(1, "months").endOf("month") : moment().subtract(2, "months").endOf("month")

const START_OF_QUARTER_DATE = moment().subtract(2, 'quarter').endOf('quarter')
const PERVIOUS_QUARTER_DATE = moment().subtract(1, 'quarter').endOf('quarter')
const performanceQuarterlyDate = (moment().date() >= 9) ? PERVIOUS_QUARTER_DATE : START_OF_QUARTER_DATE;

const clientFundLinkRenderer = (clientExposure: ProductRelatedClientPlanExposure) => {
  let clientId = clientExposure?.plan?.client?.id
  let reportsAndTotalFunds = clientExposure?.plan?.reportsAndTotalFunds
  if(reportsAndTotalFunds?.length) {
    let report = reportsAndTotalFunds.find(el => _.isNumber(el?.reportId) && _.isNumber(el?.totalFundPid))
    if(report) {
      let {reportId, totalFundPid} = report
      return `/clients/${clientId}/${reportId}/report${totalFundPid ? ("/" + totalFundPid) : ""}`
    }
  }
  return `/clients/${clientId}/documents`
}

const portfolioClientFundLinkRenderer = (portfolioExposure: ProductRelatedClientPortfolioExposure) => {
  let clientId = portfolioExposure?.portfolio?.plan?.client?.id
  return `/clients/${clientId}/documents`
}

const productInteractionLinkRenderer = (interaction: any, productId: number | undefined) => {
  return `/products/${productId}/interactions/${interaction?.id || ""}`
}

const productVehicleFeesLinkRenderer = (vehicle: Maybe<Vehicle>, productId: number| undefined) => {
  return `/products/${productId}/vehicles/${vehicle?.vehicle?.fundid}`
}

const productPeopleLinkRenderer = (person: any, productId: number| undefined) => {
  return `/products/${productId}/people/${person?.id}`
}

const productPerformanceLinkRenderer = (vehicle: Maybe<Vehicle>, productId: number| undefined) => {
  return `/products/${productId}/performance/vehicles/${vehicle?.vehicle?.fundid}/returns`
}

const productDocumentLinkRenderer = (document: File, productId: number| undefined) => {
  return `/products/${productId}/documents/${document?.id}`
}

const urlErrorMessageScript: ModalScript = {
  header: "Factsheet Error",
  body: "An error occurred, and document could not be generated. Please click on the help icon next to Factsheet for instructions on how to use this feature.",
  leaveButtonContent: "",
  stayButtonContent: "Confirm"
}

const ProductProfile: React.FC<idProps> = ({ id, managerType, auth }: idProps) => {
  const history = useHistory()
  const { data, error, loading } = useProductProfileQuery({
    variables: {
      id,
      startDate: moment(appDate).subtract(6, "years").format(DATE_API_FORMAT),
      endDate: appDate.format(DATE_API_FORMAT),
      monthDate: performanceMonthlyDate.format(DATE_API_FORMAT),
      // feeAtAum: {date: appDate.format(DATE_API_FORMAT), aum: 50},
      showOpinions: auth.checkPermissions(["view:writeups_opinion"]),
    },
    errorPolicy: "all"
  })

  const [getFactSheetUrl, {loading: urlLoading, data: urlData, error: urlError}] = useDownloadProductFactSheetLazyQuery({
    fetchPolicy: "network-only"
  })

  const heading = (
    <>
    </>
  )
  if (loading) {
    return (
      <Container fluid>
        <div className="background-white py-4 px-3 ph-xl-7">
          <Row>
            <Col>
              {heading}
              <PlaceHolder />
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
  if (error && !(data && data.product)) {
    return(
      <Container fluid>
        <div className="background-white py-4 px-3 ph-xl-7">
          <ErrorDisplay error={error}/>
        </div>
      </Container>
    )
  }
  if (data && data.product){
    return (
      <Container fluid>
        <div className="background-white py-4 px-3 ph-xl-7">
          <Row>
            <Col>
              {heading}
              <Result
                data={data}
                history={history}
                auth={auth}
                managerType={managerType}
                urlLoading={urlLoading}
                urlError={urlError}
                urlData={urlData}
                downloadUrl={getFactSheetUrl}
              />
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface ResultProps {
  data: ProductProfileQuery
  history: History
  auth: any
  managerType?: ManagerType
  urlData?: Maybe<DownloadProductFactSheetQuery>
  urlLoading?: Maybe<boolean>
  urlError?: Maybe<ApolloError>
  downloadUrl?: (options?: QueryLazyOptions<Exact<{ id: number }>> | undefined) => void
}

interface InteractionList extends Omit<Interaction, 'otherManagerAssociations'|'productAssociations'|'glidePathAssociations'|'documentAssociations'>{
}

interface EmployeeTurnoverData extends PieData {
  gained: number
  lost: number
}

interface FeeData {
  feeSpan: number[]
  value: number
}

class Result extends Component<ResultProps> {
  totalAssets: number
  constructor(props: ResultProps){
    super(props)
    this.totalAssets = _.maxBy(props.data.product?.product?.assetsByClient, (i) => (i?.categoryType.value === "Total" &&  i?.clientType.value === "Total") ?  moment(i?.quarterEndDate, DATE_API_FORMAT).valueOf() : 0 )?.assetsUnderManagement || 0
    this.representativeVehicle = getRepresentativeVehicle(props.data.product?.product?.vehicles as VehicleType[])
    this.stableValueProduct = (props.data.product?.__typename === "OpenEndedFixedIncome" && props.data.product?.product?.assetClass?.parent?.id === 73)
  }
  state = {
    overallFactExpanded: false,
    overallOpinionExpanded: false,
    philosophyFactExpanded: false,
    philosophyOpinionExpanded: false,
    constructionFactExpanded: false,
    constructionOpinionExpanded: false,
    peopleFactExpanded: false,
    peopleOpinionExpanded: false,
    performanceFactExpanded: false,
    performanceOpinionExpanded: false,
    performanceTab: "quarterly",
    assetTab: "assetsByClient",
    assetHistoricalYears: 5,
    employeeTab: "employeeTurnover",
    commonClientsExpanded: false,
    urlErrorMessage: "",
    urlDataLoading: !!this.props?.urlLoading,
    urlErrorModalOpen: false,
  }
  representativeVehicle: VehicleType | undefined
  stableValueProduct: boolean | undefined

  navigateToScreen = (url: string) => {
   this.props.history.push(url)
    window.scrollTo({ top: 0, left: 0, behavior: "instant" as ScrollBehavior })
  }

  componentDidUpdate =(prevProps: ResultProps) => {
    let {urlData, urlLoading, urlError} = this.props
    if(!prevProps.urlData && urlData) {
      let url = urlData?.download?.url
      if(!!url) {
        console.log('Download started')
        const downloadLink = document.createElement("a")
        downloadLink.href = url
        downloadLink.download = "download"
        downloadLink.click()
        setTimeout(()=> {this.setState({ urlLoading })}, 2000);
      } else {
        console.error('no url')
        this.setState({urlErrorMessage: "", urlLoading}, () => this.setUrlErrorModalVisible(true))
      }
    }else if(!prevProps.urlError && urlError) {
      this.setState({urlErrorMessage: `${urlError.message}.`, urlLoading}, () => this.setUrlErrorModalVisible(true))
    }else if(prevProps.urlLoading !==  urlLoading) {
      this.setState({urlLoading})
    }else {
      console.log("exclusion")
    }
  }

  /*

    OVERVIEW

  */
  overviewComponent = (product:ProductTypes) => {
    const activePassiveMapping= {
      "ACTIVE": 'Active',
      "PASS": 'Passive',
      "BLEND": 'Blended'
    }
    var colwidth = (product.__typename === "ClosedEndedHedgeFund") ? 6 : 4
    if(!this.props.auth.checkPermissions(["view:all_clients"])){
        colwidth =  (product.__typename === "ClosedEndedHedgeFund") ? 6 : 6
    }
    return (
      <>
        <div className="pane pane-table pane-profile">
          <Row>
            <Col md={colwidth}>
              <h3 className="headline underline">Product Info</h3>
              <Table hover className= " exportable" data-export-name={`Product Info`}>
                <tbody>
                  <tr>
                    <td className="border-top-0 text-left">Strategy Type</td>
                    <td className="border-top-0 text-left">{product.product?.assetClass?.shortName}</td>
                  </tr>
                  {"openEnded" in product &&
                    <>
                    {(product.__typename === "OpenEndedMAC" || product.__typename === "OpenEndedPassiveMAC") ?(
                        <tr>
                        <td className="text-left">Blended Benchmark</td>
                        <td className="text-left">{product.product?.primaryBenchmark?.name} {product.product?.targetBenchmarkAdd && `+ ${product.product?.targetBenchmarkAdd}%`}</td>
                        </tr>
                     ):(
                        <tr>
                          {(product.__typename === "OpenEndedHedgeFund" && product.product?.targetBenchmarkAdd) ?(
                            <>
                              <td className="text-left">Benchmark + %</td>
                              <td className="text-left">{product?.product?.primaryBenchmark?.name} {product?.product?.primaryBenchmark?.name && "+"} {product.product?.targetBenchmarkAdd}%</td>
                            </>
                          ) : (
                            <>
                              <td className="text-left">Benchmark</td>
                              <td className="text-left">{product?.product?.primaryBenchmark?.name}</td>
                            </>
                          )}
                        </tr>
                      )
                      }
                      {product.__typename !== "OpenEndedHedgeFund" &&
                        <>
                          <tr>
                            <td className="text-left">Management</td>
                            <td className="text-left">{_.get(activePassiveMapping, product.product?.activePassive || "")}</td>
                          </tr>
                        </>
                      }

                      <tr>
                        <td className="text-left">Inception Date</td>
                        <td className="text-left">{product.product?.inceptionDate}</td>
                      </tr>
                    </>
                  }
                  {"closedEnded" in product &&
                    <>
                      <tr>
                        <td className="text-left">Vintage Year</td>
                        <td className="text-left">{product.closedEnded?.vintageYearFirstCashflow && moment(product.closedEnded?.vintageYearFirstCashflow, DATE_API_FORMAT).format("YYYY")}</td>
                      </tr>
                      <tr>
                        <td className="text-left">Target IRR (Gross/Net)</td>
                        <td className="text-left">{grossNetString(product.closedEnded?.irrTarget)}</td>
                      </tr>
                    </>
                  }
                  {product.__typename === "OpenEndedTargetDate" &&
                    <tr>
                      <td className="text-left">Vintage Year</td>
                      <td className="text-left">{product.targetDate?.vintageYear}</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Col>
            {this.props.auth.checkPermissions(["view:all_clients"]) &&
              <Col md={colwidth}>
              <div className="position-relative">
                  <h3 className="headline underline">Callan Exposure</h3>
                  {
                    product.__typename === 'ClosedEndedPrivateCredit' || product.__typename === 'ClosedEndedPrivateEquity' || product.__typename === 'ClosedEndedRealAssets' || product.__typename === 'ClosedEndedHedgeFund'
                      ? <></>
                      : (
                      <div className="position-absolute d-flex text-uppercase headline-subheading" style={{ right: 0, bottom: 8}}>
                        <div className="">IAG Max <span className={`py-1 px-2 mx-2 background-${product.product?.inMaxPlatform ? "olive-10" : "error"}`}>{product.product?.inMaxPlatform ? "Yes" : "No"}</span></div>
                        <div>CFO<span className={`py-1 px-2 mx-2 background-${product.product?.callanFamilyOfficeApproved ? "olive-10" : "error"}`}>{product.product?.callanFamilyOfficeApproved ? "Yes" : "No"}</span></div>
                        <div>DC Matrix<span className={`py-1 px-2 mx-2 background-${product.product?.inDCMatrix ? "olive-10" : "error"}`}>{product.product?.inDCMatrix ? "Yes" : "No"}</span></div>
                      </div>
                    )
                  }
              </div>
                {product?.product?.productRelatedClientExposureTotal && this.exposureComponent(product?.product?.productRelatedClientExposureTotal as ProductRelatedClientExposureTotal[], product.product.managerSearchInfo as ManagerSearchInfo[])}
              </Col>
            }
            {product.__typename !== "ClosedEndedHedgeFund" &&
              <Col md={colwidth}>
                {"closedEnded" in product &&
                  <>
                    <h3 className="headline underline">Assets Under Management</h3>
                    <Table hover className= " exportable" data-export-name={`Assets Under Management`}>
                      <tbody>
                        <tr>
                          <td className="border-top-0 text-left">Target Raise</td>
                          <td className="border-top-0 text-right">{numbro(product.closedEnded?.targetRaise || 0).format(`${product.closedEnded?.targetRaise ? '$0[.]0a' : '0,0.00'}`)}</td>
                        </tr>
                        <tr>
                          <td className="text-left">Hard Cap</td>
                          <td className="text-right">{numbro(product.product?.hardCap || 0).format(`${product.product?.hardCap ? '$0[.]0a' : '0,0.00'}`)}</td>
                        </tr>
                        <tr>
                          <td className="text-left">Committed</td>
                          <td className="text-right">{(product.closedEnded?.fundRaising && product.closedEnded?.fundRaising?.length > 0) ? numbro(_.maxBy(product.closedEnded?.fundRaising, (i) => moment(i?.date, DATE_API_FORMAT).valueOf() || 0)?.committedCapital).format("$0[.]0a") : ' - '}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                }
                {"openEnded" in product && this.totalAssetsComponent(product)}
              </Col>
            }
          </Row>
        </div>
      </>
    )
  }

  exposureComponent = (exposures?:ProductRelatedClientExposureTotal[], searchInfo?:ManagerSearchInfo[]) => {
    const totalAssets = this.totalAssets
    const sortedInfo = _.sortBy(searchInfo, [i => i.timePeriod])

    let clientTotals =  {assets: 0, clients: 0}
    let clientAssetsMillions = '-'
    let clientAssets = '-'
    let clientAssetsTotal = '-'
    let clientAssetsMillionsTotal = '-'
    return (
      <>
        <Table hover className= " exportable" data-export-name={`Callan Exposure`}>
          <thead>
            <tr className="row-border-olive-100">
              <th className="text-left px-2">Clients</th>
              <th className="text-right text-nowrap px-2">Assets ($M)</th>
              <th className="text-right text-nowrap px-2"># Clients</th>
              <th className="text-right text-nowrap px-2">% Assets</th>
            </tr>
          </thead>
          <tbody>
            {exposures?.map((exposure:ProductRelatedClientExposureTotal, idx:number) => {
              if(exposure.discretionaryFlag?.code === "BOTH") return (<></>)
              const assetsMillion = (exposure.clientAssets || 0)/1000000
              clientTotals["assets"] += assetsMillion
              clientTotals["clients"] += exposure.numberOfClients || 0
              if(totalAssets && totalAssets > 0){
                clientAssets = numbro(assetsMillion / totalAssets).format('0.00%')
                clientAssetsTotal = numbro(clientTotals["assets"]/ (totalAssets)).format("0.00%")
              }
              if((handleZeroAndNull(exposure.numberOfClients) == 1) && (exposure.clientAssets == null)){
                clientAssetsMillions = "N/A"
                clientAssets = "N/A"
                clientAssetsTotal = "N/A"
                clientAssetsMillionsTotal = "N/A"
              }
              else{
                clientAssetsMillions = numbro(assetsMillion || 0).format(`${assetsMillion ? '$0,0.00' : '0,0.00' }`)
                clientAssetsMillionsTotal = numbro(clientTotals["assets"] || 0).format(`${clientTotals["assets"] ? '$0,0.00' : '0,0.00'}`)
              }
              return(
                <tr key={idx}>
                  <td className="text-left text-nowrap px-2">{ handleZeroAndNull(exposure.discretionaryFlag?.value)}</td>
                  <td className="text-right px-2">{clientAssetsMillions}</td>
                  <td className="text-right px-2">{handleZeroAndNull(exposure.numberOfClients)}</td>
                  <td className="text-right px-2">{clientAssets}</td>
                </tr>
              )
            })}
            <tr key={"total"}>
              <td className="text-left px-2">Total</td>
              <td className="text-right px-2">{clientAssetsMillionsTotal}</td>
              <td className="text-right px-2">{handleZeroAndNull(clientTotals["clients"])}</td>
              <td className="text-right px-2">{clientAssetsTotal}</td>
            </tr>
          </tbody>
        </Table>
        <Table hover className= " exportable" data-export-name={`Callan Exposure-2`}>
          <thead>
            <tr className="row-border-olive-100">
              <th className="text-left px-2">Time Period</th>
              <th className="text-right text-nowrap px-2">Semi-Finalist</th>
              <th className="text-right text-nowrap px-2">Placement</th>
            </tr>
          </thead>
          <tbody>
            {sortedInfo?.map((info:ManagerSearchInfo, idx:number) => {
              return(
                <tr key={idx}>
                  <td className="text-left text-nowrap px-2">{handleZeroAndNull(info.timePeriod)}</td>
                  <td className="text-right px-2">{handleZeroAndNull(info.semiFinalist)}</td>
                  <td className="text-right px-2">{handleZeroAndNull(info.placement)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </>
    )
  }

  totalAssetsComponent = (product:OpenEndedProductTypes) => {
    const filteredAssets = _.filter(product.product?.assetsByClient, (asset:AssetsByClient) => asset.categoryType.value === "Total" &&  asset?.clientType.value === "Total")
    const sortedAssets = _.sortBy(filteredAssets, [(i:AssetsByClient) => moment(i.quarterEndDate, DATE_API_FORMAT).valueOf()]) as AssetsByClient[]
    let initialValue:number
    let initialDate = appDate.valueOf()
    const startDate = moment(last(sortedAssets)?.quarterEndDate, DATE_API_FORMAT).subtract(5, "years").valueOf()
    let graphData:any = {
      name: "Assets Under Management",
      data: [],
    }

    let growthRate = 0
    sortedAssets.forEach((dataObject) => {
      const currentDateValue = moment(dataObject.quarterEndDate, DATE_API_FORMAT).valueOf()
      if(currentDateValue > startDate){
        if (!initialValue){
          initialValue = dataObject.assetsUnderManagement || 0
          initialDate = currentDateValue
        }
        growthRate = (((dataObject.assetsUnderManagement || 0) - initialValue) / initialValue) * 100
        // data source
        graphData.data.push([ currentDateValue, dataObject.assetsUnderManagement ])
        // return  moment(dataObject.quarterEndDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)
      }
      // return null
    })
    if (graphData.data[graphData.data.length - 1] === 0) {
      graphData.data.pop()
    }

    return(
      <>
        <div className="position-relative">
          <h3 className="headline underline">
            Assets Under Management
          </h3>
          <div className="position-absolute" style={{ right: 0, bottom: 2}}>
            <h3 className="headline m-0" style={{ fontSize: "16px" }}>{numbro((_.last(sortedAssets)?.assetsUnderManagement || 0)).format("$0,0.[00]")}M</h3>
            <p className="font-tiny m-0">{numbro(growthRate).format("0.00")}% 5-Year Growth</p>
          </div>
        </div>
        <div>
          <ChartContainer
            options={ProfileLineOptions({heading: "", subHeading: "", data: [graphData], startDate: initialDate})}
            height={200}
            hasData={graphData.data.length > 0 && !!initialDate}
            modalName={"Assets Under Management"}
          />
           <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/assets/client_type`)}>
            <span className="pr-1">View All Assets</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        </div>
      </>
    )
  }

  clientComponent = (clients: Maybe<ProductRelatedClientPlanExposure>[] | undefined | null) => {
    if(!clients?.length || !this.props.auth.checkPermissions(["view:interactions_private"])) return (<></>)
    const totalAssets = this.totalAssets
    const sortedClients = _.sortBy(clients, [i => i?.clientAssets]).reverse()
    let clientAssetsMillions = '-'
    let clientAssets = '-'
    return(
      <>
        <div className="pane pane-table pane-profile mt-4">
          <div id="CommonClients" className="pane-title">
            <h3>Common Clients</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`Common Clients`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Plan</th>
                <th className="text-right">Assets Invested ($M)</th>
                <th className="text-right">% Product Assets</th>
                <th className="text-left">Plan Type</th>
                <th className="text-right">Vehicle</th>
              </tr>
            </thead>
            <tbody>
              {sortedClients.slice(0, this.state.commonClientsExpanded ? sortedClients.length : 5).map((client, idx) => {
                if(!client) {
                  return <React.Fragment key={idx}></React.Fragment>
                }
                const assetsMillion = (client.clientAssets || 0) / 1000000
                if(totalAssets && totalAssets > 0){
                  clientAssets = numbro(assetsMillion / (totalAssets)).format("0.00%")
                }
                if(client.clientAssets == null){
                  clientAssetsMillions = "N/A"
                  clientAssets = "N/A"
                }
                else {
                  clientAssetsMillions = numbro(assetsMillion || 0).format(`${assetsMillion ? '$0,0.00' : '0,0.00'}`)
                }
                return(
                  <tr key={idx}>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(clientFundLinkRenderer(client))}>
                      {handleZeroAndNull(client?.plan?.name)}
                      </Button>
                    </td>
                    <td className="text-right">{clientAssetsMillions}</td>
                    <td className="text-right">{clientAssets}</td>
                    <td className="text-left">{handleZeroAndNull(client.plan?.fundType?.value)}</td>
                    <td className="text-right">{handleZeroAndNull(client.vehicle?.vehicle?.name)}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <div className="expand-link">
            <Button color="link" href="#CommonClients" onClick={()=> this.setState({ commonClientsExpanded: !this.state.commonClientsExpanded })}>
              <FontAwesomeIcon
                icon={this.state.commonClientsExpanded  ? "chevron-up" : "chevron-down"}
                size="sm"
              />
              <span className="pl-2 expand-text">{this.state.commonClientsExpanded  ? "Collapse" : "Expand"}</span>
            </Button>
          </div>
        </div>
      </>
    )
  }

  interactionsTable = (interactions:InteractionList[]) => {
    const interactionsCount = interactions.reduce((result, entry) => {
      // Get type default to other if not another type
      let type = "other"
      if(entry.type === "In House")        type = "inHouse"
      if(entry.type === "On Site")         type = "onSite"
      if(entry.type === "Off Site")        type = "offSite"
      if(entry.type === "Conference Call") type = "conference"
      if(entry.type === "Client")          type = "client"
      if(entry.type === "Video Call")      type = "video"
      if(entry.type === "Webcast")         type = "webcast"
      if(entry.type === "Email")           type = "email"
      // Get related entry
      let typeObject = _.get(result, type)
      let totalObject = _.get(result, 'total')
      // Update values in entry
      const interactionDate = moment(entry.date, DATE_API_FORMAT)
      if(interactionDate.diff(moment().startOf('quarter'),'month', true) > 0){
        typeObject.qtd += 1
        totalObject.qtd += 1
      }
      if(interactionDate.diff(moment().subtract(1, 'year'),'month', true) > 0){
        typeObject.ytd += 1
        totalObject.ytd += 1
      }
      if(interactionDate.diff(moment(),'year', true) > -3){
        typeObject.thy += 1
        totalObject.thy += 1
      }
      // Save values back to result object
      _.set(result, type, typeObject)
      _.set(result, 'total', totalObject)
      return result
    }, {
      inHouse:    { qtd: 0, ytd:0, thy: 0 },
      onSite:     { qtd: 0, ytd:0, thy: 0 },
      offSite:    { qtd: 0, ytd:0, thy: 0 },
      conference: { qtd: 0, ytd:0, thy: 0 },
      client:     { qtd: 0, ytd:0, thy: 0 },
      video:      { qtd: 0, ytd:0, thy: 0 },
      webcast:    { qtd: 0, ytd:0, thy: 0 },
      email:      { qtd: 0, ytd:0, thy: 0 },
      other:      { qtd: 0, ytd:0, thy: 0 },
      total:      { qtd: 0, ytd:0, thy: 0 },
    })
    const interactionOrder = [
      {
        field: 'inHouse',
        title: 'In House',
        name: 'In House',
      },{
        field: 'onSite',
        title: 'On Site',
        name: 'On Site',
      },{
        field: 'offSite',
        title: 'Off Site',
        name: 'Off Site',
      },{
        field: 'conference',
        title: 'Conf. Call',
        name: 'Conference Call',
      },{
        field: 'client',
        title: 'Client',
        name: 'Client',
      },{
        field: 'email',
        title: 'Email',
        name: 'Email',
      },{
        field: 'video',
        title: 'Video Call',
        name: 'Video Call',
      },{
        field: 'webcast',
        title: 'Webcast',
        name: 'Webcast',
      },{
        field: 'other',
        title: 'Other',
        name: 'Other',
      }
    ]
    const sortedInteractions = _.sortBy(interactions, [i => moment(i.date).valueOf()]).reverse()
    return(
      <div className="pane pane-table pane-profile mt-4 pb-5">
        <div className="pane-title d-flex">
          <h3>Interactions</h3>
        </div>
        <Row>
          <Col sm={12} lg="5" className="border-right border-color-gray-10">
            <Table hover className= " exportable table-sm" data-export-name={`Interactions`}>
              <thead>
                <tr className="table-title">
                  <th colSpan={5} className="py-3 border-0">Summary</th>
                </tr>
                <tr className="row-border-olive-100">
                  <th className="text-left text-no-wrap" colSpan={2}>Interactions</th>
                  <th className="text-right">QTD</th>
                  <th className="text-right">1 Year</th>
                  <th className="text-right">3 Years</th>
                </tr>
              </thead>
              <tbody>
                {interactionOrder.map((heading, idx) => {
                  const interactionGroup = _.get(interactionsCount, heading.field)
                  if(interactionGroup.qtd === 0 && interactionGroup.ytd === 0 && interactionGroup.thy === 0){
                    return(
                      <React.Fragment key={idx}></React.Fragment>
                    )
                  }
                  return(
                    <tr key={idx}>
                      <td className="pr-0">{<InteractionIcon type={heading.name}/>}</td>
                      <td className="text-left pl-0">{heading.title}</td>
                      <td className="text-right">{handleZeroAndNull(interactionGroup.qtd)}</td>
                      <td className="text-right">{handleZeroAndNull(interactionGroup.ytd)}</td>
                      <td className="text-right">{handleZeroAndNull(interactionGroup.thy)}</td>
                    </tr>
                  )
                })}
                <tr className="row-border-olive-100">
                  <td></td>
                  <td className="text-left pl-0">Total</td>
                  <td className="text-right">{handleZeroAndNull(interactionsCount.total.qtd)}</td>
                  <td className="text-right">{handleZeroAndNull(interactionsCount.total.ytd)}</td>
                  <td className="text-right">{handleZeroAndNull(interactionsCount.total.thy)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col sm={12} lg="7" className={"overflow-auto"}>
            <Table hover className= "table-sm exportable" data-export-name={`Details`}>
              <thead>
                <tr className="table-title">
                  <th colSpan={6} className="py-3 border-0">Details</th>
                </tr>
                <tr className="row-border-olive-100">
                  <th className="text-left px-0">Type</th>
                  <th className="text-left pl-1 pr-0">Date</th>
                  <th className="text-left pl-1">Contact</th>
                  <th className="text-left pl-1">Callan</th>
                  <th className="text-left pl-1 pr-0" style={{maxWidth: "400px"}} >Subject</th>
                  <th className="text-left px-0">Note</th>
                </tr>
              </thead>
              <tbody>
                {sortedInteractions.slice(0, 5).map((interaction, idx) => {
                  const date = moment(interaction.date, DATE_API_FORMAT)
                  const contact = _.first(interaction.contacts)
                  const callan = _.first(interaction.callanPeople)
                  return(
                    <tr key={idx}>
                      <td className="text-left px-0"><InteractionIcon type={interaction.type}/></td>
                      <td className="text-left pl-1 pr-0">{date.format(DATE_DISPLAY_FORMAT)}</td>
                      <td className="text-left pl-1">{contact && `${contact.firstName?.charAt(0)}. ${contact.lastName}`}</td>
                      <td className="text-left pl-1">{callan && `${callan.firstName?.charAt(0)}. ${callan.lastName}`}</td>
                      <td className="text-left pl-1 pr-0" style={{maxWidth: "400px"}}>
                        <Button color="blue-link" className="float-left my-0 py-0 pl-0 d-inline-block text-truncate" style={{maxWidth: "400px"}} onClick={() => this.navigateToScreen(productInteractionLinkRenderer(interaction, this.props.data.product?.product?.id))} title={interaction.subject || ""}>
                          {interaction.subject}
                        </Button>
                      </td>
                      <td className="text-left px-0">{interaction.notes?.hasNotes && <InteractionIcon type='Check'/>}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        {this.props.auth.checkPermissions(["view:interactions"]) &&
          <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/interactions`)}>
            <span className="pr-1">View All Interactions</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        }
      </div>
    )
  }
  /*

    SERIES

  */
  seriesComponent = () => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    const series = _.compact(product.product?.linkedSeries?.series) as ProductTypes[]
    const sortedSeries = _.sortBy(series, [i => ("closedEnded" in i && moment(i?.closedEnded?.vintageYearFirstCashflow, DATE_API_FORMAT).valueOf()) || 0]).reverse()
    if(product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedRealAssets"){
      return(
        <>
          <h3 className="mb-3">Series</h3>
          <div className="pane pane-table pane-profile mt-4 pb-5">
            <div className="pane-title d-flex">
              <h3>Series</h3>
            </div>
            <Table hover className= " exportable" data-export-name={`Series`}>
              <thead>
                <tr className="row-border-olive-100">
                  <th className="text-left">Fund Name</th>
                  <th className="text-right">Vintage Year</th>
                  <th className="text-right">Fund Size</th>
                  {product.__typename === "ClosedEndedRealAssets" &&
                    <th className="text-right">% Paid-in</th>
                  }
                </tr>
              </thead>
              <tbody>
                {_.map(sortedSeries, (product:ProductTypes, idx:number) => {
                  if("closedEnded" in product){
                    const committedCapital = _.maxBy(product.closedEnded?.fundRaising, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.committedCapital || 0
                    return(
                      <tr key={idx}>
                        <td className="text-left">{product.product?.name}</td>
                        <td className="text-right">{product.closedEnded?.vintageYearFirstCashflow && moment(product.closedEnded?.vintageYearFirstCashflow,DATE_API_FORMAT).format("YYYY")}</td>
                        <td className="text-right">{numbro(committedCapital || 0).format("$0,0")}</td>
                        {product.__typename === "ClosedEndedRealAssets" &&
                          <td className="text-right">{numbro(((_.get(this.representativeVehicle?.vehicle, "cashFlowsPerformanceTrue") as any as CashFlowPerformance).committedCapital || 0) / (committedCapital || 1)).format("0.00%")}</td>
                        }
                      </tr>
                    )
                  }
                  return (<React.Fragment key={idx}></React.Fragment>)
                })}
              </tbody>
            </Table>
          </div>
        </>
      )
    }
  }

  /*

    ASSETS

  */
  assetsTable = () => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    const fetchedAssets = _.get(product.product, this.state.assetTab)
    const groupedAssets = _.groupBy(fetchedAssets, 'quarterEndDate')
    const assetDate = _.maxBy(Object.keys(groupedAssets), i => moment(i).valueOf()) || ""
    const sortedAssets = _.sortBy(_.get(groupedAssets, assetDate), [abc => abc.assetsUnderManagement || 0]).reverse()
    let assetTotal = {assets: 0, accounts: 0}
    let assetReceivedTotal = {assets: 0, accounts: 0}
    let extraTotal = {name: "", assets: 0, accounts: 0}
    let nameFetch = ""
    let displayName = ""
    let modalName = ""
    switch(this.state.assetTab){
      case "assetsByClient":
        nameFetch = "clientType.value"
        displayName = "Client Type"
        modalName = "Client Type"
        break
      case "assetsByVehicle":
        nameFetch = "vehicleType.value"
        displayName = "Vehicle"
        modalName = "Vehicle"
        break
      case "assetsByRegion":
        nameFetch = "region.value"
        displayName = "Location"
        modalName = "Location"
        break
    }
    let tableData: any[] = []
    let graphData = sortedAssets.reduce(
      (
        result: any[],
        entry: any
      ) => {
        const name = _.get(entry, nameFetch)
        if(name === "Total"){
          assetReceivedTotal.assets = entry.assetsUnderManagement || 0
          assetReceivedTotal.accounts = entry.numberOfAccounts || 0
        } else if(result.length <= 3) {
          const data = {
            name: name,
            y: entry.assetsUnderManagement || 0,
            accounts: entry.numberOfAccounts || 0,
          }
          if(name === "Europe ex-UK" || name === "Defined Contribution"){
            extraTotal.name = name
            extraTotal.assets = entry.assetsUnderManagement || 0
            extraTotal.accounts = entry.numberOfAccounts || 0
          } else {
            result.push(data)
            tableData.push(data)
            assetTotal.assets += entry.assetsUnderManagement || 0
            assetTotal.accounts += entry.numberOfAccounts || 0
          }
        } else {
          let additional = cloneDeep(result.pop())
          let additionalTable = cloneDeep(tableData.pop())
          if (name === "Europe ex-UK" || name === "Defined Contribution"){
            extraTotal.name = name
            extraTotal.assets = entry.assetsUnderManagement || 0
            extraTotal.accounts = entry.numberOfAccounts || 0
          } else {
            additional.name = "Additional"
            additional.y += entry.assetsUnderManagement || 0
            additional.accounts += entry.numberOfAccounts || 0
            additionalTable.name = "Additional"
            additionalTable.y += entry.assetsUnderManagement || 0
            additionalTable.accounts += entry.numberOfAccounts || 0
            assetTotal.assets += entry.assetsUnderManagement || 0
            assetTotal.accounts += entry.numberOfAccounts || 0
          }
          tableData.push(additionalTable)
          result.push(additional)
        }
        return result
      },
      []
    ) || []
    // track number of cols that need to be added to later data if not part of first data set
    let colCount = 0
    const diffDate = moment(assetDate).subtract(this.state.assetHistoricalYears, "years")
    const startDate = _.minBy(Object.keys(groupedAssets), (i) => {
      const dateMoment = moment(i,DATE_API_FORMAT)
      return (dateMoment && dateMoment.diff(diffDate,"month") > 0) ? dateMoment.valueOf() : assetDate.valueOf()
    }) || ""
    const sortedHistoricalAssets = listQuarters(startDate, assetDate).reverse()
    let startHistoricalDate = 0
    let historicalGraphData = _.reduce(sortedHistoricalAssets,
      (
        result: GraphData[],
        date: any
      ) => {
        const entry = _.get(groupedAssets, date)
        const dateMoment = moment(date,DATE_API_FORMAT)
        let assetNames:string[] = entry?.map((o:any) => _.get(o, nameFetch))
        // Remove Total from possible values
        remove(assetNames, (name) => name === "Total")
        remove(assetNames, (name) => name === "Europe ex-UK")
        remove(assetNames, (name) => name === "Defined Contribution")
        // Only use data for the correct time period
        const diffDate = moment(assetDate).subtract(this.state.assetHistoricalYears, "years")
        if(dateMoment && dateMoment.diff(diffDate,"month") > 0){
          if(startHistoricalDate === 0) startHistoricalDate = dateMoment.valueOf()
          result.forEach((assetType:any) => {
            const matchedResult = _.find(entry, (o) => _.get(o, nameFetch) === assetType.name)
            if(matchedResult){
              assetType.data.push([matchedResult.quarterEndDate, matchedResult.assetsUnderManagement])
              const name = _.get(matchedResult, nameFetch)
              const index = assetNames.indexOf(name)
              if (index > -1) {
                assetNames.splice(index, 1);
              }
            } else {
              assetType.data.push(0)
            }
          })
          // If no match fill out missing 0s to make data line up
          assetNames?.forEach((name) => {
            const matchedResult = _.find(entry, (o) => _.get(o, nameFetch) === name)
            let data = Array(colCount).fill(0)
            data.push([matchedResult.quarterEndDate, matchedResult.assetsUnderManagement])
            result.push({ name, data })
          })
          colCount += 1
        }
        return result
      },
      []
    ) || []
    const sortedHistoricalGraphData = sortBy(historicalGraphData, (historicalEntry:GraphData) => {
      const index = findIndex(graphData, {"name": historicalEntry.name})
      return index === -1 ? 900 : index
    })
    return(
    <>
      <div className="pane pane-table pane-profile mt-4 pb-5">
        <div className="pane-title d-flex">
          <h3>Assets {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}</h3>
        </div>
        {!("closedEnded" in product) &&
          <Nav role="group" className="nav-bordered">
            <NavItem>
              <NavLink
                className={classnames({active: this.state.assetTab === "assetsByClient"})}
                onClick={()=>this.setState({assetTab: "assetsByClient"})}
              >
                Client Type
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: this.state.assetTab === "assetsByVehicle"})}
                onClick={()=>this.setState({assetTab: "assetsByVehicle"})}
              >
                Vehicle
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: this.state.assetTab === "assetsByRegion"})}
                onClick={()=>this.setState({assetTab: "assetsByRegion"})}
              >
                Location
              </NavLink>
            </NavItem>
          </Nav>
        }
        <Row>
          <Col xl="2" sm="4">
            <ChartContainer
              options={ProfileAssetPieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
              height={200}
              hasData={graphData.length > 0 && assetTotal.assets > 0 && graphData.some((data) => data.y > 0)}
            >
              <ModalHeader className="chart-modal-header">
                Assets by {modalName} {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}
              </ModalHeader>
              <ModalBody className="chart-modal-body">
                <Row>
                  <Col sm={4}>
                    <HighchartsReact
                      options={ProfileAssetPieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                      highcharts={Highcharts}
                      containerProps = {{ style: {height: `400px`} }}
                    />
                  </Col>
                  <Col sm={8}>
                    {this.assetTable({displayName, tableData, assetTotal, assetReceivedTotal, extraTotal})}
                  </Col>
                </Row>
              </ModalBody>
            </ChartContainer>
          </Col>
          <Col xl="4" sm="8" className="border-right border-callan-olive overflow-auto">
            {this.assetTable({displayName, tableData, assetTotal, assetReceivedTotal, extraTotal})}
          </Col>
          <Col xl="6">
            <div className="d-flex w-100 align-items-center">
              <h5 className="mb-0">Historical Assets</h5>
              <Nav role="group" className="ml-auto">
                <NavItem>
                  <NavLink
                    className={classnames({active: this.state.assetHistoricalYears === 1})}
                    onClick={()=>this.setState({assetHistoricalYears: 1})}
                  >
                    1Y
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: this.state.assetHistoricalYears === 3})}
                    onClick={()=>this.setState({assetHistoricalYears: 3})}
                  >
                    3Y
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: this.state.assetHistoricalYears === 5})}
                    onClick={()=>this.setState({assetHistoricalYears: 5})}
                  >
                    5Y
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div>
              <ChartContainer
                options={ProfileStackedColumnOptions({heading: "", data: sortedHistoricalGraphData, startDate: startHistoricalDate, colours: graphColors.map((cl) => cl.hex)})}
                height={200}
                hasData={sortedHistoricalGraphData.length > 0}
                modalName={`Historical Assets by ${modalName} ${assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}`}
              />
            </div>
          </Col>
          </Row>
          <div className="expand-link">
            <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/assets/client_type`)}>
              <span className="pr-1">View All Assets</span>
              <FontAwesomeIcon
                icon={"chevron-right"}
                size="sm"
              />
            </Button>
          </div>
      </div>
    </>
    )
  }
  assetTable = ({displayName, tableData, assetTotal, assetReceivedTotal, extraTotal}:any) => {
    return(
      <Table hover className= " exportable" data-export-name={`Client Type`}>
        <thead>
          <tr className="row-border-olive-100">
            <th className="pl-1 pr-0"></th>
            <th className="pl-0 text-left">{displayName}</th>
            <th className="text-left">AUM($M)</th>
            <th className="text-left">Accounts</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((graphObject: any, idx: number) => {
            return(
              <tr key={idx}>
                <td className="pl-1 pr-0"><div className={`example-color-box background-${graphColors[idx % graphColors.length].name}`}></div></td>
                <td className="pl-0 text-left">{graphObject.name}</td>
                <td className="text-left">{!!graphObject.y ? numbro(graphObject.y || 0).format("$0,0.00") : ' - '}</td>
                <td className="text-left">{!!graphObject.accounts ? numbro(graphObject.accounts || 0).format("0") : ' - '}</td>
              </tr>
            )
          })}
          <tr className="row-border-olive-100">
            <td></td>
            <td className="text-left pl-0">Total</td>
            <td className="text-left">{!!assetTotal.assets || !!assetReceivedTotal?.assets ? numbro(assetTotal.assets || assetReceivedTotal?.assets || 0).format("$0,0.00") : ' - ' }</td>
            <td className="text-left">{numbro(assetTotal.accounts || assetReceivedTotal?.accounts || 0).format("0")}</td>
          </tr>
          {extraTotal?.assets > 0 &&
            <tr>
              <td></td>
              <td className="text-left pl-0">{extraTotal.name}</td>
              <td className="text-left">{extraTotal.assets ? numbro(extraTotal.assets || 0).format("$0,0.00") : ' - '}</td>
              <td className="text-left">{numbro(extraTotal.accounts || 0).format("0")}</td>
            </tr>
          }
        </tbody>
      </Table>
    )
  }
  /*

    PEOPLE

  */
  peopleContent = () => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    const keyPeople = product.product?.people?.filter((person:ProductPerson | null) => person?.active && person.person?.active) as ProductPerson[]
    return(
      <>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title d-flex">
            <h3>People</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`People`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Name</th>
                <th className="text-left">Role</th>
                <th className="text-left">Product Experience (Yrs.)</th>
                <th className="text-left">Firm Experience (Yrs.)</th>
                <th className="text-left">Industry Experience (Yrs.)</th>
              </tr>
            </thead>
            <tbody>
              {keyPeople?.map((person:ProductPerson, idx:number) => {
                return(
                  <tr key={idx}>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productPeopleLinkRenderer(person.person, this.props.data.product?.product?.id))}>
                        {person.person?.firstName} {person.person?.lastName}
                      </Button>
                    </td>
                    <td className="text-left">{person.prodTitleNum?.value}</td>
                    <td className="text-left">{!!person.yearStartedWithProduct ? appDate.year() - person.yearStartedWithProduct : ""}</td>
                    <td className="text-left">{!!person.person?.background?.yearStartedWithFirm ? appDate.year() - person.person?.background?.yearStartedWithFirm : ""}</td>
                    <td className="text-left">{!!person.person?.background?.yearStartedInIndustry ? appDate.year() - person.person?.background?.yearStartedInIndustry : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Button color="blue-link" className="float-right" onClick={() =>this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/people?keypeople=true`)}>
          <span className="pr-1">View all Executives</span>
          <FontAwesomeIcon
            icon={"chevron-right"}
            size="sm"
          /></Button>
        </div>
      </>
    )
  }
  /*

    EMPLOYEES

  */
  employeeContent = () => {

    const employeeRaceTypes = this.props.data.employeeRaceTypes?.enumValues
    const employeeGenderTypes = this.props.data.employeeGenderTypes?.enumValues

    const product: ProductTypes = this.props.data.product as ProductTypes
    const fetchedEmployees = _.get(product.product, this.state.employeeTab)
    const groupedEmployees = _.groupBy(fetchedEmployees, 'date')
    // CAL-1525 don't show data for the current year
    let assetDate = _.maxBy(Object.keys(groupedEmployees), i => (moment(i, DATE_API_FORMAT).year() === moment().year()) ? 0 : moment(i, DATE_API_FORMAT).valueOf()) || ""
    if (moment(assetDate, DATE_API_FORMAT).year() === moment().year())
      assetDate = ""
    const sortedEmployees: SortedEmployees[] = _.sortBy(_.get(groupedEmployees, assetDate), [abc => abc.count]).reverse()

    const employeeSortedRaceType: SortedEmployees[] = [];
    const employeeSortedGenderType: SortedEmployees[] = [];

    employeeRaceTypes?.forEach((employeeRace) => {
      employeeSortedRaceType.push({
        __typename: null,
        count: null,
        date: null,
        type: {
          __typename: employeeRace.__typename,
          code: employeeRace.code,
          value: employeeRace.value,
        },
      });
    });

    employeeRaceTypes?.forEach((employeeRace) => {
      sortedEmployees.forEach((sortedEmployee) => {
        if (sortedEmployee.type.code === employeeRace.code) {
          const empSortedRaceTypeIndex = employeeSortedRaceType.map((el) => el.type.code).indexOf(sortedEmployee.type.code);

          employeeSortedRaceType[empSortedRaceTypeIndex] = {
            __typename: sortedEmployee.__typename,
            count: sortedEmployee.count,
            date: sortedEmployee.date,
            type: {
              __typename: employeeRace.__typename,
              code: employeeRace.code,
              value: employeeRace.value,
            },
          };
        }
      });
    });

    employeeGenderTypes?.forEach((employeeGender) => {
      employeeSortedGenderType.push({
        __typename: null,
        count: null,
        date: null,
        type: {
          __typename: employeeGender.__typename,
          code: employeeGender.code,
          value: employeeGender.value,
        },
      });
    });

    employeeGenderTypes?.forEach((employeeGender) => {
      sortedEmployees.forEach((sortedEmployee) => {
        if (sortedEmployee.type.code === employeeGender.code) {
          const empSortedGenderTypeIndex = employeeSortedGenderType.map((el) => el.type.code).indexOf(sortedEmployee.type.code);

          employeeSortedGenderType[empSortedGenderTypeIndex] = {
            __typename: sortedEmployee.__typename,
            count: sortedEmployee.count,
            date: sortedEmployee.date,
            type: {
              __typename: employeeGender.__typename,
              code: employeeGender.code,
              value: employeeGender.value,
            },
          };
        }
      });
    });

    const handleSortEmployees = () => tableHeader === 'Diversity Profile' ? employeeSortedRaceType : tableHeader === "Gender Breakdown" ? employeeSortedGenderType : sortedEmployees

    let graphData
    let employeeTotal = {count: 0, gained: 0, lost: 0}
    let tableHeader = ""
    let modalName = ""
    switch(this.state.employeeTab){
      case "employeeTurnover":
        tableHeader = "Employees"
        modalName = "Job Role"
        break
      case "employeeBreakdownByDiversityProfile":
        tableHeader = "Diversity Profile"
        modalName = "Diversity"
        break
      case "employeeBreakdownByGender":
        tableHeader = "Gender Breakdown"
        modalName = "Gender"
        break
    }
    if(this.state.employeeTab === "employeeTurnover"){
      const groupedTurnoverEmployees = _.groupBy(fetchedEmployees, 'type.code')
      const assetDateCompare = moment(assetDate, DATE_API_FORMAT).subtract(3,"years")
      const turnoverData = _.reduce(groupedTurnoverEmployees,
        (
          result: any[],
          entry: any[]
        ) => {
          const total = entry.reduce(
            (
              totals: EmployeeTurnoverData,
              single: any
            ) => {
              // Only want the current count for main date
              if(single.date === assetDate){
                totals.y += single.count
              }
              // Sum up gained and lost from last 3 years
              if(assetDateCompare.diff(moment(single.date, DATE_API_FORMAT)) < 0 ){
                totals.gained += single.gained
                totals.lost += single.lost
              }
              return totals
            },
            {
              name: _.get(_.first(entry), "type.value"),
              y: 0,
              gained: 0,
              lost: 0,
            }
          )
          result.push(total)
          return result
        },
        []
      )
      const sortedTurnoverData = _.sortBy(turnoverData, [tod => tod.y]).reverse()
      graphData = sortedTurnoverData.reduce(
        (
          result: any[],
          entry: any
        ) => {
          if(entry.y == null){

          } else if(result.length < 3) {
            result.push(entry)
            employeeTotal.count += entry.y
            employeeTotal.gained += entry.gained
            employeeTotal.lost += entry.lost
          } else if(result.length === 3) {
            result.push({
              name: "Additional",
              y: entry.y || 0,
              gained: entry.gained || 0,
              lost: entry.lost || 0,
            })
            employeeTotal.count += entry.y
            employeeTotal.gained += entry.gained
            employeeTotal.lost += entry.lost
          } else {
            let additional = result.pop()
            additional.y += entry.y || 0
            additional.gained += entry.gained || 0
            additional.lost += entry.lost || 0
            result.push(additional)
            employeeTotal.count += entry.y
            employeeTotal.gained += entry.gained
            employeeTotal.lost += entry.lost
          }
          return result
        },
        []
      ) || []
    } else {
      graphData = handleSortEmployees().reduce(
        (
          result: any[],
          entry: any
        ) => {
          let name = _.get(entry, "type.value")
          if(name === "Total Employees"){

          } else if(result.length < 10) {
            result.push({
              name: name,
              y: entry.count,
            })
            employeeTotal.count += entry.count
          } else if(result.length === 10) {
            result.push({
              name: "Additional",
              y: entry.count,
            })
            employeeTotal.count += entry.count
          } else {
            let additional = result.pop()
            additional.y += entry.count || 0
            result.push(additional)
            employeeTotal.count += entry.count
          }
          return result
        },
        []
      ) || []
    }
    return(
      <>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title d-flex">
          <h3>Employee Count &amp; Turnover {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format("YYYY")}`} </h3>
          </div>
          <Nav role="group" className="nav-bordered">
            <NavItem>
              <NavLink
                className={classnames({active: this.state.employeeTab === "employeeTurnover"})}
                onClick={()=>this.setState({employeeTab: "employeeTurnover"})}
              >
                Job Role
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: this.state.employeeTab === "employeeBreakdownByDiversityProfile"})}
                onClick={()=>this.setState({employeeTab: "employeeBreakdownByDiversityProfile"})}
              >
                Diversity
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: this.state.employeeTab === "employeeBreakdownByGender"})}
                onClick={()=>this.setState({employeeTab: "employeeBreakdownByGender"})}
              >
                Gender
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Col md="2">
              <ChartContainer
                options={ProfileEmployeePieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                height={200}
                hasData={graphData.length > 0 && employeeTotal.count > 0}
              >
                <ModalHeader className="chart-modal-header">
                  Employee Count & Turnover by {modalName} {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}
                </ModalHeader>
                <ModalBody className="chart-modal-body">
                  <Row>
                    <Col sm={4}>
                      <HighchartsReact
                        options={ProfileEmployeePieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                        highcharts={Highcharts}
                        containerProps = {{ style: {height: `400px`} }}
                      />
                    </Col>
                    <Col sm={8}>
                      {this.employeeTable({graphData, tableHeader, employeeTotal})}
                    </Col>
                  </Row>
                </ModalBody>
              </ChartContainer>
            </Col>
            <Col>
              {this.employeeTable({graphData, tableHeader, employeeTotal})}
            </Col>
          </Row>
          <div className="expand-link">
            <Button color="blue-link" className="float-right" onClick={() =>this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/overview/employees`)}>
              <span className="pr-1">View All Employees</span>
              <FontAwesomeIcon
                icon={"chevron-right"}
                size="sm"
              />
            </Button>
          </div>
        </div>
      </>
    )
  }
  employeeTable({graphData, tableHeader, employeeTotal}:any){
    return(
      <Table hover className= " exportable" data-export-name={`Employee count & turnover`}>
      <thead>
        <tr className="row-border-olive-100">
          <th className="pl-1 pr-0"></th>
          <th className="pl-0 text-left">{tableHeader}</th>
          <th className="text-left"># of Employees</th>
          {this.state.employeeTab === "employeeTurnover" &&
            <>
              <th className="text-left">3-Year Gain</th>
              <th className="text-left">3-Year Loss</th>
            </>
          }
        </tr>
      </thead>
      <tbody>
        {graphData.map((graphObject: any, idx: number) => {
          const handleOnNullValue = (value: any) => !isNull(value) ? numbro(value).format("0,0") : '-'
          return(
            <tr key={idx}>
              <td className="pl-1 pr-0"><div className={`example-color-box background-${graphColors[idx % graphColors.length].name}`}></div></td>
              <td className="pl-0 text-left">{graphObject.name}</td>
              <td className="text-left">{handleOnNullValue(graphObject.y)}</td>
              {this.state.employeeTab === "employeeTurnover" &&
                <>
                  <td className="text-left">{handleOnNullValue(graphObject.gained)}</td>
                  <td className="text-left">{handleOnNullValue(graphObject.lost)}</td>
                </>
              }
            </tr>
          )
        })}
        <tr className="row-border-olive-100">
          <td></td>
          <td className="text-left pl-0">Total</td>
          <td className="text-left">{numbro(employeeTotal.count || 0).format("0,0")}</td>
          {this.state.employeeTab === "employeeTurnover" &&
            <>
              <td className="text-left">{numbro(employeeTotal.gained || 0).format("0,0")}</td>
              <td className="text-left">{numbro(employeeTotal.lost || 0).format("0,0")}</td>
            </>
          }
        </tr>
      </tbody>
    </Table>
    )
  }
  /*

    PERFORMANCE

  */
  performanceComponent = () => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    let filteredVehicles = filter(product.product?.vehicles || [], (v) => {
      return v?.vehicle?.status?.code !== "t"
    })
    const sortedVehicles = sortBy(filteredVehicles, [i => i?.vehicle?.category?.value, i => i?.vehicle?.name])
    if(product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedPrivateCredit"){
      return(
        <>
          <div className="pane pane-table pane-profile mt-4 pb-5">
            <div className="pane-title d-flex">
              <h3>Performance {performanceMonthlyDate && `as of ${performanceMonthlyDate.format(DATE_DISPLAY_FORMAT)}`}</h3>
            </div>
            <Table hover className= " exportable" data-export-name={`Performance`}>
              <thead>
                <tr className="row-border-olive-100">
                  <th className="text-left">Fund</th>
                  <th className="text-left">Vintage Year</th>
                  <th className="text-right">Fund Size ($M)</th>
                  <th className="text-right">Invested ($M)</th>
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th className="text-right">Gross IRR</th>
                  <th className="text-right">Gross TVPI</th>
                  <th className="text-right">Net DPI</th>
                  <th className="text-right">Net RVPI</th>
                  <th className="text-right">Net IRR</th>
                  <th className="text-right">Net TVPI</th>
                </tr>
              </thead>
              <tbody>
                {sortedVehicles.map((vehicle, idx) => {
                  const vehicleProduct = vehicle?.vehicle?.product as ProductTypes
                  const closedEndProduct = vehicleProduct && "closedEnded" in vehicleProduct && vehicleProduct.closedEnded as ClosedEndedFields
                  const cashFlowTrue = (_.get(vehicle?.vehicle, "cashFlowsPerformanceTrue", {} as CashFlowPerformance))
                  const cashFlowFalse = (_.get(vehicle?.vehicle, "cashFlowsPerformanceFalse", {} as CashFlowPerformance))

                  const handlePerformanceDisplay = ( cashFlow :CashFlowPerformance , cashFlowValue : any, format : string, postfix : string |undefined) => {
                    return `${cashFlow && numbro(cashFlowValue || 0).format(`${cashFlowValue ? format : '0.00'}`)}${cashFlowValue ? postfix : ''}`
                  }

                  return(
                    <tr key={idx}>
                      <td className="text-left">
                        <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productPerformanceLinkRenderer(vehicle, this.props.data.product?.product?.id))}>
                          {vehicle?.vehicle?.name}
                        </Button>
                      </td>
                      <td className="text-left">{closedEndProduct && closedEndProduct.vintageYearFirstCashflow}</td>
                      <td className="text-right">{closedEndProduct && numbro(_.maxBy(closedEndProduct.fundRaising, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.committedCapital || 0).format(`${_.maxBy(closedEndProduct.fundRaising, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.committedCapital ? '$0,0' : '0,0'}`)}</td>
                      <td className="text-right">{handlePerformanceDisplay(cashFlowTrue,cashFlowTrue?.calledCapital,"$0,0", "")}</td>
                      <td className="boundary-left"></td>
                      <td className="boundary-right"></td>
                      <td className="text-right">{handlePerformanceDisplay(cashFlowFalse, cashFlowFalse?.irr, "0.00", '%' )} </td>
                      <td className="text-right">{handlePerformanceDisplay(cashFlowFalse, cashFlowFalse?.tvpi, "0.00", 'x')}</td>
                      <td className="text-right">{handlePerformanceDisplay(cashFlowTrue, cashFlowTrue?.dpi, "0.00", 'x')}</td>
                      <td className="text-right">{handlePerformanceDisplay(cashFlowTrue, cashFlowTrue?.rvpi, "0.00", 'x')}</td>
                      <td className="text-right">{handlePerformanceDisplay(cashFlowTrue ,cashFlowTrue?.irr, "0.00", '%')}</td>
                      <td className="text-right">{handlePerformanceDisplay(cashFlowTrue, cashFlowTrue?.tvpi, "0.00", 'x')}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Button color="blue-link" className="float-right" onClick={() =>this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/performance`)}>
              <span className="pr-1">View All Performance</span>
              <FontAwesomeIcon
                icon={"chevron-right"}
                size="sm"
              />
            </Button>
          </div>
        </>
      )
    } else if("openEnded" in product){
      const performanceModifier = (name:string) => {
        if(this.state.performanceTab === "monthly"){
          return `vehicle.monthly${name.charAt(0).toUpperCase() + name.slice(1)}`
        } else {
          return `vehicle.${name}`
        }
      }
      return(
        <>
          <div className="pane pane-table pane-profile mt-4 pb-5">
            <div className="pane-title d-flex">
              <h3>
                Performance {' '}
                {performanceMonthlyDate && this.state.performanceTab === "monthly" && `as of ${performanceMonthlyDate.format(DATE_DISPLAY_FORMAT)}`}
                {performanceQuarterlyDate && this.state.performanceTab === "quarterly" && `as of ${performanceQuarterlyDate.format(DATE_DISPLAY_FORMAT)}`}
              </h3>
            </div>
            <Nav role="group" className="nav-bordered">
              <NavItem>
                <NavLink
                  className={classnames({active: this.state.performanceTab === "quarterly"})}
                  onClick={()=>this.setState({performanceTab: "quarterly"})}
                >
                  Quarterly
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({active: this.state.performanceTab === "monthly"})}
                  onClick={()=>this.setState({performanceTab: "monthly"})}
                >
                  Monthly
                </NavLink>
              </NavItem>
            </Nav>
            <Table hover className= " exportable" data-export-name={`Performance`}>
              <thead>
                <tr className="row-border-olive-100">
                  <th className="text-left">Fund</th>
                  <th className="text-left">Vehicle</th>
                  <th className="text-left">Type</th>
                  {this.state.performanceTab === "monthly" &&
                    <>
                      <th className="text-right">Last Month</th>
                      <th className="text-right">Last 3 Months</th>
                    </>
                  }
                  {this.state.performanceTab === "quarterly" &&
                    <th className="text-right">Last Quarter</th>
                  }
                  <th className="text-right">Last Year</th>
                  <th className="text-right">Last 3 Years</th>
                  <th className="text-right">Last 5 Years</th>
                  <th className="text-right">Last 7 Years</th>
                  <th className="text-right">Last 10 Years</th>
                </tr>
              </thead>
              <tbody>
                {sortedVehicles.map((vehicle, idx) => {
                  const monthly = _.maxBy(_.get(vehicle, "vehicle.monthly"), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()) )?.value?.active
                  const quarterly = _.maxBy(_.get(vehicle, performanceModifier("quarterly")), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()) )?.value?.active
                  const yearly = _.maxBy(_.get(vehicle, performanceModifier("yearly")), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()) )?.value?.active
                  const threeYearly = _.maxBy(_.get(vehicle, performanceModifier("threeYearly")), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()) )?.value?.active
                  const fiveYearly = _.maxBy(_.get(vehicle, performanceModifier("fiveYearly")), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()) )?.value?.active
                  const sevenYearly = _.maxBy(_.get(vehicle, performanceModifier("sevenYearly")), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()) )?.value?.active
                  const tenYearly = _.maxBy(_.get(vehicle, performanceModifier("tenYearly")), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()) )?.value?.active
                  const type = (first(_.get(vehicle, "vehicle.monthly")) as Performance | null)?.fees?.value || (first(_.get(vehicle, "vehicle.quarterly")) as Performance | null)?.fees?.value
                  return(
                    <tr key={idx}>
                      <td className="text-left">
                        <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productPerformanceLinkRenderer(vehicle, this.props.data.product?.product?.id))}>
                          {vehicle?.vehicle?.name}
                        </Button>
                      </td>
                      <td className="text-left">{vehicle?.vehicle?.category?.value}</td>
                      <td className="text-left">{type}</td>
                      {this.state.performanceTab === "monthly" &&
                        <td className="text-right">{monthly == null ? "-" : `${numbro(monthly).format("0.00")}%`}</td>
                      }
                      <td className="text-right">{quarterly == null ? "-" : `${numbro(quarterly).format("0.00")}%`}</td>
                      <td className="text-right">{yearly == null ? "-" : `${numbro(yearly).format("0.00")}%`}</td>
                      <td className="text-right">{threeYearly == null ? "-" : `${numbro(threeYearly).format("0.00")}%`}</td>
                      <td className="text-right">{fiveYearly == null ? "-" : `${numbro(fiveYearly).format("0.00")}%`}</td>
                      <td className="text-right">{sevenYearly == null ? "-" : `${numbro(sevenYearly).format("0.00")}%`}</td>
                      <td className="text-right">{tenYearly == null ? "-" : `${numbro(tenYearly).format("0.00")}%`}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Button color="blue-link" className="float-right" onClick={() =>this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/performance`)}>
              <span className="pr-1">View All Performance</span>
              <FontAwesomeIcon
                icon={"chevron-right"}
                size="sm"
              />
            </Button>
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  }
  /*

    DOCUMENTS

  */
  documentContent(documents:File[]) {
    if(!(this.props.auth.checkPermissions(["view:documents"]) || this.props.auth.checkPermissions(["view:my_documents"]) || this.props.auth.checkPermissions(["view:manager_documents"]) || this.props.auth.checkPermissions(["view:all_documents"]))) return <></>
    const sortedDocuments = _.sortBy(documents, (i) => {
      return _.reduce(i.versions, (result, value) => {
        const currentValue = moment(value.updated, DATE_API_FORMAT).valueOf()
        return _.max([currentValue, result]) || 0
      }, 0)
    }).reverse()
    return(
      <>
        <h3 className="mt-4 mb-3">Documents</h3>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title d-flex">
            <h3>Documents</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`Documents`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Date</th>
                <th className="text-left">Type</th>
                <th className="text-left">Topic</th>
                <th className="text-left">Description</th>
                <th className="text-left">Download</th>
              </tr>
            </thead>
            <tbody>
              {sortedDocuments.slice(0, 5).map((document:File, idx:number) => {
                const latestVersion = _.maxBy(document.versions, i => moment(i.updated, DATE_API_FORMAT).valueOf())
                return(
                  <tr key={idx}>
                    <td className="text-left">{latestVersion?.updated && moment(latestVersion?.updated, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</td>
                    <td className="text-left">{document.type?.value}</td>
                    <td className="text-left">{document.subType?.value}</td>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productDocumentLinkRenderer(document, this.props.data.product?.product?.id))}>
                        {document.description || document.name}
                      </Button>
                    </td>
                    <td className="text-left">
                      {document.url &&
                        <a href={document.url} download={document.name} rel="noreferrer" target="_blank"><FontAwesomeIcon size="sm" icon="download"/></a>
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Button color="blue-link" className="float-right" onClick={() =>this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/documents`)}>
            <span className="pr-1">View All Documents</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        </div>
      </>
    )
  }

  writeUpFactDisplay(writeUp:any) {
    if(!writeUp) return {__html: ""}
    if(writeUp.text){
      return {__html: DOMPurify.sanitize(writeUp.text)}
    }
    if(writeUp.bullets){
      return {__html: DOMPurify.sanitize(writeUp.bullets)}
    }
    return {__html: ""}
  }

  writeUpOpinionDisplay(writeUp:any, type: "merits" | "considerations") {
    if(!writeUp) return {__html: ""}
    let bullets:string = ""
    let text:string = ""
    if(type === "merits"){
      text = writeUp.meritsText
      bullets = writeUp.meritsBullets
    } else if(type === "considerations"){
      text = writeUp.considerationsText
      bullets = writeUp.considerationsBullets
    }
    if(bullets && bullets.localeCompare("\n") !== 0){
      return {__html: DOMPurify.sanitize(bullets)}
    } else if (text){
      return {__html: DOMPurify.sanitize(text)}
    }
    return {__html: ""}
  }

  groupWriteUps(writeUpList:any[] | undefined | null){
    if(!writeUpList) return {}
    return writeUpList.reduce(
      (
        result: writeUpList,
        entry: Fact | ProductOpinion
      ) => {
        const conformedEntry = (entry.__typename === "Fact" && entry) || (entry.__typename === "ProductOpinion" && entry.opinions) as Fact | Opinion
        const code = (entry.__typename === "Fact" && entry.researchCategory?.code) || (entry.__typename === "ProductOpinion" && `${entry.opinions?.researchCategory?.code}:${entry.opinions?.client?.id}:${entry.vehicle?.code}`) || ""
        const foundWriteUp = _.get(result, code)
        if(foundWriteUp){
          const conformedFound = (foundWriteUp.__typename === "Fact" && foundWriteUp) || (foundWriteUp.__typename === "ProductOpinion" && foundWriteUp.opinions) as Fact | Opinion
          if(moment(conformedEntry.lastUpdated).diff(moment(conformedFound.lastUpdated)) > 0 ) _.set(result, code, entry)
        } else {
          _.set(result, code, entry)
        }
        return result
      },
      {}
    )
  }

  writeUpComponent(heading: string, writeUp: Fact | ProductOpinion, expandedVariable: string, type: "Fact" | "Opinion", linkName?: string){
    const expanded = _.get(this.state, expandedVariable)
    const stateToggle = _.set({}, expandedVariable, !expanded)
    if(type === "Fact"){
      return (
        <div className="pane pane-table pane-profile mt-4">
          <div className="pane-title">
            <h3>{heading}</h3>
          </div>
          <div className="expandable-textarea-container">
            <div className={`expandable-container${expanded ? ' expanded' : ''}`}>
              <p className="intro" dangerouslySetInnerHTML={this.writeUpFactDisplay(writeUp)}></p>
            </div>
            <div className="expand-link">
              <Button color="link" onClick={()=> this.setState(stateToggle)}>
                <FontAwesomeIcon
                  icon={expanded  ? "chevron-up" : "chevron-down"}
                  size="sm"
                />
                <span className="pl-2 expand-text">{expanded  ? "Collapse" : "Expand"}</span>
              </Button>
              {this.props.auth.checkPermissions(["edit:writeups"]) &&
                <Button color="blue-link" className="float-right" onClick={() =>this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/write-ups/${linkName}`)}>
                  <span className="pr-1">View All Write-ups</span>
                  <FontAwesomeIcon
                    icon={"chevron-right"}
                    size="sm"
                  />
                </Button>
              }
            </div>
          </div>
        </div>
      )
    } else if (type === "Opinion"){
      if(!this.props.auth.checkPermissions(["view:writeups_opinion"])) return <></>
      const opinion = (writeUp && "opinions" in writeUp) ? writeUp.opinions : undefined
      return (
        <div className="pane pane-table pane-profile mt-4">
          <div className="pane-title">
            <h3>{heading}</h3>
          </div>
          <p>Status <FontAwesomeIcon icon="circle" className={`ml-4 mr-2 text-${statusColor(opinion?.status?.value)}`} />{opinion?.status?.value}</p>
          <div className="expandable-textarea-container">
            <div className={`expandable-container${expanded ? ' expanded' : ''}`}>
              <Row>
                <Col>
                  <h3 className="headline underline border-callan-olive">Merits</h3>
                  <p className="intro" dangerouslySetInnerHTML={this.writeUpOpinionDisplay(opinion, "merits")}></p>
                </Col>
                <Col>
                  <h3 className="headline underline border-callan-olive">Considerations</h3>
                  <p className="intro" dangerouslySetInnerHTML={this.writeUpOpinionDisplay(opinion, "considerations")}></p>
                </Col>
              </Row>
            </div>
            <div className="expand-link">
              <Button color="link" onClick={()=> this.setState(stateToggle)}>
                <FontAwesomeIcon
                  icon={expanded  ? "chevron-up" : "chevron-down"}
                  size="sm"
                />
                <span className="pl-2 expand-text">{expanded  ? "Collapse" : "Expand"}</span>
              </Button>
              {this.props.auth.checkPermissions(["edit:writeups"]) &&
                <Button color="blue-link" className="float-right" onClick={() =>this.navigateToScreen(`/products/${this.props.data.product?.product?.id}/write-ups/${linkName}`)}>
                  <span className="pr-1">View All Write-ups</span>
                  <FontAwesomeIcon
                    icon={"chevron-right"}
                    size="sm"
                  />
                </Button>
              }
            </div>
          </div>
        </div>
      )
    } else {
      return(<></>)
    }
  }

  setUrlErrorModalVisible(state: boolean) {this.setState({urlErrorModalOpen: state})}

  downloadFactSheet = (id: number) => {
    const {downloadUrl} = this.props
    if(downloadUrl) {
      this.setState({urlErrorMessage:""}, ()=> downloadUrl({variables:{id}}))
    }
  }

  factSheetComponent() {
    let { auth, data, downloadUrl } = this.props

    let parentId = data?.product?.product?.assetClass?.parent?.id
    if(parentId !== 57 && parentId !== 146) {
      // feature for Private Equity & Private Debts for now CAL-2118
      return (
        <Button key='factSheet' color="secondary btn-thin" className="text-callan-blue mb-3" onClick={()=> exportTables()} >
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
      )
    }

    let { urlDataLoading, urlErrorMessage, urlErrorModalOpen} = this.state
    const downloadPermission = auth.checkPermissions(["download:meeting_notes"])

    const LoadingButton = (
      <div className="d-flex align-items-center justify-contents-between">
        <Button className="ml-auto btn-no-style text-blue-100 p-0 mx-2">
          Creating
          <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />
        </Button>
      </div>
    )

    const ExportButton = (
      <div className="justify-contents-between">
        <Button color="secondary btn-thin" className="text-callan-blue mb-3"
          onClick={()=>data?.product?.product?.id && this.downloadFactSheet(data.product.product.id)}>
            Download DOCX
            <img src='/assets/DOCX.svg' className="ml-2"/>
        </Button>
      </div>
    )

    const showExportButton = downloadPermission && downloadUrl
    let errorScript = urlErrorMessageScript
    if(urlErrorMessage) {
      errorScript = {...errorScript, body: urlErrorMessage}
    }

    return (
      <>
        {urlDataLoading? LoadingButton: showExportButton &&
          ExportButton}
        <GuardModal
          key={`fact-sheet-url-error-modal`}
          open={!!urlErrorModalOpen}
          stay={() => this.setUrlErrorModalVisible(false)}
          leave={() => this.setUrlErrorModalVisible(false)}
          script={errorScript}
          hide={{leaveButton: true}}
        />
      </>
    )
  }

  render() {
    if (this.props.data?.product?.product){
      const product:ProductTypes = this.props.data.product as ProductTypes
      const factWriteUps = this.groupWriteUps(product.product?.writeUps?.facts)
      const opinionWriteUps = this.groupWriteUps(product.product?.writeUps?.opinions)
      return (
        <>
          <div>
            <Row>
              <Col className="mt-2">
                <h3>
                  Overview
                </h3>
              </Col>
              <Col className="text-right">
                {this.factSheetComponent()}
              </Col>
            </Row>
        </div>
          {this.overviewComponent(this.props.data.product as ProductTypes)}
          {this.writeUpComponent("Product Overall",_.get(factWriteUps, "POVER"), "overallFactExpanded", "Fact", "overall")}
          {this.clientComponent(this.props.data.product.product.productRelatedClientPlanExposure)}
          {this.interactionsTable(this.props.data.product.product.interactions as Interaction[])}
          {this.writeUpComponent("Callan's Product Overall Opinion", _.get(opinionWriteUps, "POVER:244:s"), "overallOpinionExpanded", "Opinion", "overall")}
          <h3 className="mb-3 mt-4">Product</h3>
          {this.writeUpComponent("Philosophy/Process",_.get(factWriteUps, "PHIL"), "philosophyFactExpanded", "Fact", "philosophy")}
          {this.writeUpComponent("Callan's Philosophy/Process Opinion",_.get(opinionWriteUps, "PHIL:244:s"), "philosophyOpinionExpanded", "Opinion", "philosophy")}
          <Query<ProductProfileStrategyQuery>
            query={PRODUCT_PROFILE_STRATEGY_QUERY}
            variables={{
              id: this.props.data.product.product.id,
              startDate: moment(appDate).subtract(5, "years").format(DATE_API_FORMAT),
              endDate: appDate.format(DATE_API_FORMAT)
            }}
            errorPolicy="all"
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange={true}
          >
            {params => {
              if (!params || params.loading) {
                return (
                  <div className="pane pane-table pane-profile mt-4 pb-5">
                    <div className="pane-title d-flex">
                      <h3>Strategy</h3>
                    </div>
                    <PlaceHolder/>
                  </div>
                )
              }
              if(params.error && !params.data){
                return (
                  <div className="pane pane-table pane-profile mt-4 pb-5">
                    <div className="pane-title d-flex">
                      <h3>Strategy</h3>
                    </div>
                    {params.error?.message}
                  </div>
                )
              }
              if(params.data){
                return (
                  <StrategyComponent
                    data={params.data}
                    stableValueProduct={this.stableValueProduct}
                    history={this.props.history}
                    auth={this.props.auth}
                  />
                )
              }
              return (<></>)
            }}
          </Query>
          {product.__typename !== "OpenEndedTargetDate" &&
            <>
              {this.writeUpComponent("Product Dynamics",_.get(factWriteUps, "CONST"), "constructionFactExpanded", "Fact", "portfolio")}
              {this.writeUpComponent("Callan's Product Dynamics Opinion",_.get(opinionWriteUps, "CONST:244:s"), "constructionOpinionExpanded", "Opinion", "portfolio")}
              {this.seriesComponent()}
              <h3 className="mb-3 mt-4">Assets</h3>
              {this.assetsTable()}
              <h3 className="mb-3 mt-4">People</h3>
              {this.writeUpComponent("Product People",_.get(factWriteUps, "PPEO"), "peopleFactExpanded", "Fact", "people")}
              {this.writeUpComponent("Callan's Product People Opinion",_.get(opinionWriteUps, "PPEO:244:s"), "peopleOpinionExpanded", "Opinion", "people")}
              {this.peopleContent()}
              {this.employeeContent()}
              <h3 className="mb-3 mt-4">Performance</h3>
              {this.writeUpComponent("Performance",_.get(factWriteUps, "PERF"), "performanceFactExpanded", "Fact", "long")}
              {this.writeUpComponent("Callan's Performance Opinion",_.get(opinionWriteUps, "PERF:244:s"), "performanceOpinionExpanded", "Opinion", "long")}
              {this.performanceComponent()}
              {/* <h3 className="mb-3">Characteristics</h3> */}
              {/* {this.characteristicsComponent()} */}
              {this.documentContent(product.product?.documents as File[])}
            </>
          }
        </>
      )
    } else {
      return (<div>data doesn't exist</div>)
    }
  }
}

interface StrategyProps {
  data: ProductProfileStrategyQuery
  stableValueProduct?: boolean
  history: History
  auth: Auth
}

class StrategyComponent extends Component<StrategyProps> {
  constructor(props: StrategyProps){
    super(props)
    this.representativeVehicle = getRepresentativeVehicle(props.data.product?.product?.vehicles as VehicleType[])
  }
  representativeVehicle: VehicleType | undefined

  navigateToScreen = (url: string) => {
    this.props.history.push(url)
     window.scrollTo({ top: 0, left: 0, behavior: "instant" as ScrollBehavior })
  }

  /*

    STRATEGY

  */
  render = () => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    if(!("__typename" in product))return <></>
    const heading = (
      <div className="pane-title d-flex">
        <h3>Strategy</h3>
      </div>
    )
    if(product.__typename === "OpenEndedEquity" || product.__typename === "OpenEndedPassiveEquity" || product.__typename === "OpenEndedFixedIncome" || product.__typename === "OpenEndedPassiveFixedIncome"){
      // Domestic Equity
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("domestic")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("domestic")}
            </Col>
          </Row>
          {this.strategyFeesTable("domestic")}
          {this.clientFeesTable("domestic")}
        </div>
      )
    } else if (product.__typename === "OpenEndedHedgeFund"){
      // Hedge Fund
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("hedge")}
              {this.strategyTerms("hedge")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("hedge")}
              {this.strategyFees("hedge")}
            </Col>
          </Row>
        </div>
      )
    } else if (product.__typename === "ClosedEndedHedgeFund"){
      // Hedge Fund
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("hedge")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("hedge")}
            </Col>
          </Row>
        </div>
      )
    } else if (product.__typename === "OpenEndedMAC" || product.__typename === "OpenEndedPassiveMAC"){
      // Multi Asset Class
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("multi")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("multi")}
            </Col>
          </Row>
          {this.strategyFeesTable("multi")}
        </div>
      )
    } else if (product.__typename === "OpenEndedTargetDate"){
      // Target Date
      const latestAllocation = _.maxBy(product.targetDate?.allocations, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())
      const allocationOrder = [27,46,71,23,87,39,6,51,44,41,73,8,12,134,22,50,182,58,49,93,126,9,45]
      let total = 0
      let graphData = latestAllocation?.allocations.reduce(
        (
          result: any[],
          entry: any
        ) => {
          const name = _.get(entry, "assetClass.shortName")
          const id = _.get(entry, "assetClass.id")
          result.push({
            name: name,
            y: entry.weight || 0,
            id: id
          })
          total += entry.weight || 0
          return result
        },
        []
      ) || []
      graphData = sortBy(graphData, (allocation) => {
        const order = allocationOrder.indexOf(allocation.id)
        if(order === -1) return 99
        return order
      })
      return(
        <>
          <div className="pane pane-table pane-profile mt-4 pb-5">
            {heading}
            <Row>
              <Col lg="6" className="border-right border-callan-olive">
                {this.strategySummary("target")}
              </Col>
            </Row>
            {this.strategyFeesTable("target")}
          </div>
          <div className="pane pane-table pane-profile mt-4 pb-5">
            <div className="pane-title d-flex">
              <h3>Target Asset Allocations {latestAllocation?.date && ` as of ${moment(latestAllocation?.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}</h3>
            </div>
            <Row>
              <Col sm="4">
                <ChartContainer
                  options={ProfileAssetPieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                  height={200}
                  hasData={graphData.length > 0 && total > 0}
                >
                  <ModalHeader className="chart-modal-header">
                    Target Asset Allocations {latestAllocation?.date && ` as of ${moment(latestAllocation?.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}
                  </ModalHeader>
                  <ModalBody className="chart-modal-body">
                    <Row>
                      <Col sm={4}>
                        <HighchartsReact
                          options={ProfileAssetPieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                          highcharts={Highcharts}
                          containerProps = {{ style: {height: `400px`} }}
                        />
                      </Col>
                      <Col sm={8}>
                        {this.targetDateTable({graphData})}
                      </Col>
                    </Row>
                  </ModalBody>
                </ChartContainer>
              </Col>
              <Col sm="8" className="border-right border-callan-olive">
                {this.targetDateTable({graphData})}
              </Col>
            </Row>
          </div>
          <div className="pane pane-table pane-profile mt-4 pb-5">
            <div className="pane-title d-flex">
              <h3>Glide Path</h3>
            </div>
            {product.targetDate?.glidePathVersion && this.glidePathGraph(product.targetDate?.glidePathVersion)}
          </div>
        </>
      )
    } else if (product.__typename === "ClosedEndedPrivateCredit"){
      // Private Credit
      return (
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("credit")}
              {this.strategyTerms("credit")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("credit")}
              {this.strategyFundRaising("credit")}
              {this.strategyLeverage("credit")}
              {this.strategyFees("credit")}
            </Col>
          </Row>
        </div>
      )
    } else if (product.__typename === "ClosedEndedPrivateEquity"){
      // Private Equity
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("privateEquity")}
              {this.strategyTerms("privateEquity")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("privateEquity")}
              {this.strategyFundRaising("privateEquity")}
              {this.strategyLeverage("privateEquity")}
              {this.strategyFees("privateEquity")}
            </Col>
          </Row>
        </div>
      )
    } else if (product.__typename === "ClosedEndedRealAssets"){
      // Closed Real Assets
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("closedReal")}
              {this.strategyTerms("closedReal")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("closedReal")}
              {this.strategyFees("closedReal")}
              {this.strategyLeverage("closedReal")}
              {this.strategyFundRaising("closedReal")}
            </Col>
          </Row>
        </div>
      )
    } else if (product.__typename === "OpenEndedRealAssets"){
      // Open Real Assets
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("openReal")}
            </Col>
            <Col lg="6">
              {this.strategyGuidelines("openReal")}
              {this.strategyFees("openReal")}
            </Col>
          </Row>
        </div>
      )
    } else if (product.__typename === "OtherProduct"){
      // Stable Value
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
          <Row>
            <Col lg="6" className="border-right border-callan-olive">
              {this.strategySummary("stable")}
            </Col>
          </Row>
          {this.strategyFeesTable("stable")}
        </div>
      )
    } else {
      return(
        <div className="pane pane-table pane-profile mt-4 pb-5">
          {heading}
        </div>
      )
    }
  }
  targetDateTable = ({graphData}:any) => {
    return(
      <Table hover className= " exportable" data-export-name={`Target date`}>
        <thead>
          <tr className="row-border-olive-100">
            <th className="pl-1 pr-0"></th>
            <th className="pl-0 text-left">Asset Class</th>
            <th className="text-left">Target Weight</th>
          </tr>
        </thead>
        <tbody>
          {graphData.map((graphObject:any, idx:number) => {
            return(
              <tr key={idx}>
                <td className="pl-1 pr-0"><div className={`example-color-box background-${graphColors[idx % graphColors.length].name}`}></div></td>
                <td className="pl-0 text-left">{graphObject.name}</td>
                <td className="text-left">{numbro(graphObject.y || 0).format("0.00")}%</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  strategySummary = (type:string) => {
    const activePassiveMapping= {
      "ACTIVE": 'Active',
      "PASS": 'Passive',
      "BLEND": 'Blended'
    }
    const product:ProductTypes = this.props.data.product as ProductTypes
    return(
      <>
        <h3 className="headline underline border-callan-olive">Summary</h3>
        <Table hover className= " exportable" data-export-name={`Summary`}>
          <tbody>
            <tr>
              <td className="border-top-0 text-left">Asset Class</td>
              <td className="border-top-0 text-left">{product.product?.assetClass?.parent?.shortName}</td>
            </tr>
            {type !== "target" &&
              <tr>
                <td className="text-left">Strategy Type</td>
                <td className="text-left">{product.product?.assetClass?.shortName}</td>
              </tr>
            }
            {"targetDate" in product &&
              <tr>
                <td className="text-left">Vintage Year</td>
                <td className="text-left">{product.targetDate?.vintageYear}</td>
              </tr>
            }
            {"closedEnded" in product &&
              <tr>
                <td className="text-left">Vintage Year</td>
                <td className="text-left">{product.closedEnded?.vintageYearFirstCashflow && moment(product.closedEnded?.vintageYearFirstCashflow, DATE_API_FORMAT).format("YYYY")}</td>
              </tr>
            }
            {product.__typename === "ClosedEndedRealAssets" &&
              <tr>
                <td className="text-left">Primary Sectors</td>
              <td className="text-left">{product.realAssets?.primaryRealAssetSectorsTarget && product.realAssets.primaryRealAssetSectorsTarget.map((primarySector) =>
                  primarySector?.value
                ).join(', ')}
              </td>
            </tr>
            }

            {(type === "privateEquity" || type === "credit") && "closedEnded" in product &&
              <>
                <tr>
                  <td className="text-left">Currency</td>
                  <td className="text-left">{product.product?.currency?.value}</td>
                </tr>
                <tr>
                  <td className="text-left">Control Rights</td>
                  <td className="text-left">{product.closedEnded?.controlRights?.value}</td>
                </tr>
                {product.__typename === "ClosedEndedPrivateCredit" && this.loopValue(product.privateCredit?.targetInvestmentStyle, "value", "Primary Investment Style")}
                {product.__typename === "ClosedEndedPrivateEquity" && this.loopValue(product.privateEquity?.targetInvestmentStyle, "value", "Primary Investment Style")}
              </>
            }
            {(product.__typename === "ClosedEndedPrivateEquity") && this.loopValue(product.privateEquity?.targetPrimaryGICSSectors, "value", "Primary Sectors")}
            {(product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "OpenEndedHedgeFund" || product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedRealAssets") && this.loopValue(product.product?.primaryGeographicRegion, "value", "Primary Geographic Region")}
            {("openEnded" in product || product.__typename === "OtherProduct") &&
              <tr>
                <td className="text-left">Benchmark</td>
                <td className="text-left">{product.product?.primaryBenchmark?.name}</td>
              </tr>
            }
            {(("openEnded" in product && product.__typename !== "OpenEndedHedgeFund") || product.__typename === "OtherProduct") &&
              <tr>
                <td className="text-left">Active/Passive</td>
                <td className="text-left">{_.get(activePassiveMapping, product.product?.activePassive || "")}</td>
              </tr>
            }
            {("openEnded" in product || product.__typename === "OtherProduct") &&
              <tr>
                <td className="text-left">Product Inception</td>
                <td className="text-left">{product.product?.inceptionDate && moment(product.product?.inceptionDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</td>
              </tr>
            }
            {product.__typename === "OpenEndedTargetDate" &&
              <>
                <tr>
                  <td className="text-left">Version Name</td>
                  <td className="text-left">{product.targetDate?.glidePathVersion?.name}</td>
                </tr>
                <tr>
                  <td className="text-left">Version Inception</td>
                  <td className="text-left">{product.targetDate?.glidePathVersion?.inceptionYear}</td>
                </tr>
                <tr>
                  <td className="text-left">Glide Path Philosophy</td>
                  <td className="text-left">{product.targetDate?.glidePathVersion?.implementationPhilosophy?.value}</td>
                </tr>
              </>
            }
          </tbody>
        </Table>
      </>
    )
  }

  strategyTerms = (type:string) => {
    const product:ProductTypes = this.props.data.product as ProductTypes

    const handleStrategyTerms = (value: any, divideByOneMillion = false) => {

      if (value && divideByOneMillion) {
        return numbro((value || 0) / ONE_MILLION).format("$0,0")
      }

      if (value) {
        return value
      }

      return ' - '
    }

    if ("closedEnded" in product) {
      return(
        <>
          <h3 className="headline underline border-callan-olive">Terms</h3>
          <Table hover className= " exportable" data-export-name={`Terms`}>
            <tbody>
              { (product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedRealAssets") &&
                <tr>
                  <td className="border-top-0 text-left">Target IRR (Gross/Net)</td>
                  <td className="border-top-0 text-left">{grossNetString(product.closedEnded?.irrTarget)}</td>
                </tr>
              }
              { (product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedPrivateEquity") &&
                <>
                  <tr>
                    <td className="text-left">Target TVPI (Gross/Net)</td>
                    <td className="text-left">{grossNetString(product.closedEnded?.targetTotalValuePaidIn,'x')}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Target Raise ($M)</td>
                    <td className="text-left">{handleStrategyTerms(product.closedEnded?.targetRaise, true)}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Hard Cap ($M)</td>
                    <td className="text-left">{handleStrategyTerms(product.product?.hardCap, true)}</td>
                  </tr>
                </>
              }
              { (product.__typename === "ClosedEndedRealAssets") &&
                <tr>
                  <td className="text-left">Target TVPI (Gross/Net)</td>
                  <td className="text-left">{grossNetString(product.closedEnded?.targetTotalValuePaidIn,'x')}
                  </td>
                </tr>
              }
              { (product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedRealAssets") &&
                <tr>
                  <td className="text-left">Minimum Commitment ($M)</td>
                  <td className="text-left">{handleStrategyTerms(product.closedEnded?.minimumCommitment, true)}</td>
                </tr>
              }
              { (product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedPrivateEquity") &&
                <tr>
                  <td className="text-left">Sponsor Commitment</td>
                  <td className="text-left">
                    {numbro((product.closedEnded?.sponsorCommitment || 0)/100).format("0.00%")}
                  </td>
                </tr>
              }
              { (product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedRealAssets") &&
                <tr>
                  <td className="text-left">Investment Period (Yrs.)</td>
                  <td className="text-left">{handleStrategyTerms(product.closedEnded?.investmentPeriod)}</td>
                </tr>
              }
              { (product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedRealAssets") &&
                <tr>
                  <td className="text-left">Term (Yrs.)</td>
                  <td className="text-left">{handleStrategyTerms(product.closedEnded?.term)}</td>
                </tr>
              }
              { (product.__typename === "ClosedEndedPrivateCredit") &&
                <>
                  <tr>
                    <td className="text-left">Target Gross Yield</td>
                    <td className="text-left">{handleStrategyTerms(product.privateCredit?.targetGrossYield)}</td>
                  </tr>
                  <tr>
                    <td className="text-left">OID/Origination Fee</td>
                    <td className="text-left">{handleStrategyTerms(product.privateCredit?.targetOriginationFees)}</td>
                  </tr>
                </>
              }
              { (product.__typename === "ClosedEndedRealAssets") &&
                <>
                  <tr>
                    <td className="text-left">Number of Extensions Allowed</td>
                    <td className="text-left">{handleStrategyTerms(product.closedEnded?.extensions?.numberAllowed)}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Length of Extensions</td>
                    <td className="text-left">{handleStrategyTerms(product.closedEnded?.extensions?.lengthAllowed)}</td>
                  </tr>
                </>
              }
            </tbody>
          </Table>
        </>
      )
    } else if (product.__typename === "OpenEndedHedgeFund"){
      const vehicle = this.representativeVehicle as OpenEndedPrivateNonRegisteredHedgeFund
      return(
        <>
          <h3 className="headline underline border-callan-olive">Terms</h3>
          <Table hover className= " exportable" data-export-name={`Terms-2`}>
            <tbody>
              <tr>
                <td className="border-top-0 text-left">Subject to 25% Limit</td>
                <td className="border-top-0 text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.subjectTo25PercentLimit)}</td>
              </tr>
              <tr>
                <td className="text-left">Subscription Period</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.subscriptionPeriod?.value)}</td>
              </tr>
              <tr>
                <td className="text-left">Redemption Period</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.redemptionDays)}</td>
              </tr>
              <tr>
                <td className="text-left">Lockup Period</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.lockupPeriod?.value)}</td>
              </tr>
              <tr>
                <td className="text-left">Notice Period</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.noticePeriod)}</td>
              </tr>
              <tr>
                <td className="text-left">Initial Payout (%)</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.initialPayout)}{vehicle.hedgeFundVehicle?.initialPayout ? '%' : ''}</td>
              </tr>
              <tr>
                <td className="text-left">Final Payout (%)</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.finalPayout)}{vehicle.hedgeFundVehicle?.finalPayout ? '%' : ''}</td>
              </tr>
              <tr>
                <td className="text-left">Fund Gate Provisions</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.gateFundProvision)}</td>
              </tr>
              <tr>
                <td className="text-left">Investor Gate Provisions</td>
                <td className="text-left">{handleStrategyTerms(vehicle.hedgeFundVehicle?.gateInvestorProvision)}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    }
  }

  strategyGuidelines = (type:string) => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    if(product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedPrivateCredit"){

      const handleGuidelineRanges = (startValue: any, endValue: any, divideByOneMillion: boolean = true) => {
        if (startValue && endValue) {
          if(divideByOneMillion) {
            return `${numbro((startValue || 0) / ONE_MILLION).format("$0,0")} - ${numbro((endValue || 0) / ONE_MILLION).format("$0,0")}`
          }else {
            return `${numbro((startValue || 0)).format("0,0")} - ${numbro((endValue || 0)).format("0,0")}`
          }
        } else {
          return `-`
        }
      }

      return(
        <>
          <h3 className="headline underline border-callan-olive">Guidelines</h3>
          <Table hover className= " exportable" data-export-name={`Guidelines`}>
            <tbody>
              <tr>
                <td className="border-top-0 text-left">Deal Size ($M)</td>
                <td className="border-top-0 text-left">{handleGuidelineRanges(product.closedEnded?.dealPolicies?.dealSize?.lower , product.closedEnded?.dealPolicies?.dealSize?.higher)}</td>
              </tr>
              <tr>
                <td className="text-left">EBITDA ($M)</td>
                <td className="text-left">{handleGuidelineRanges(product.closedEnded?.dealPolicies?.trailing12mEBITDA?.lower , product.closedEnded?.dealPolicies?.trailing12mEBITDA?.higher)}</td>
              </tr>
              <tr>
                <td className="text-left">Revenue ($M)</td>
                <td className="text-left">{handleGuidelineRanges(product.closedEnded?.dealPolicies?.revenue?.lower , product.closedEnded?.dealPolicies?.revenue?.higher)}</td>
              </tr>
              <tr>
                <td className="text-left">Enterprise Value ($M)</td>
                <td className="text-left">{handleGuidelineRanges(product.closedEnded?.dealPolicies?.enterpriseValue?.lower , product.closedEnded?.dealPolicies?.enterpriseValue?.higher)}</td>
              </tr>
              <tr key="numberOfInvestments">
                <td className="text-left">Number of Investments</td>
                <td className="text-left">
                  {handleGuidelineRanges(product.closedEnded?.dealPolicies?.numberOfInvestments?.lower, product.closedEnded?.dealPolicies?.numberOfInvestments?.higher, false)}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    } else if (("openEnded" in product) && product.__typename !== "OpenEndedTargetDate" && !this.props.stableValueProduct) {

      const handleBpsRanges = (startValue: any, endValue: any) => {
        if (startValue && endValue) {
          return `${startValue || 0} - ${endValue || 0} bps`
        } else {
          return `-`
        }
      }

      return (
        <>
          <h3 className="headline underline border-callan-olive">Guidelines</h3>
          <Table hover className= " exportable" data-export-name={`Guidelines-2`}>
            <tbody>
              <tr>
                <td className="border-top-0 text-left">Tracking Error</td>
                <td className="border-top-0 text-left">{handleBpsRanges(product.openEnded?.riskStatisticsTargets?.trackingError?.low , product.openEnded?.riskStatisticsTargets?.trackingError?.high)}</td>
              </tr>
              <tr>
                <td className="text-left">Excess Return</td>
                <td className="text-left">{handleBpsRanges(product.openEnded?.riskStatisticsTargets?.excessReturn?.low , product.openEnded?.riskStatisticsTargets?.excessReturn?.high)}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    } else if ( ("closedEnded" in product) && product.__typename === "ClosedEndedRealAssets") {
      const handleGuidelineRanges = (startValue: any, endValue: any, divideByOneMillion: boolean = true) => {
        if (startValue && endValue) {
          if(divideByOneMillion) {
            return `${numbro((startValue || 0) / ONE_MILLION).format("$0,0")} - ${numbro((endValue || 0) / ONE_MILLION).format("$0,0")}`
          }else {
            return `${numbro((startValue || 0)).format("0,0")} - ${numbro((endValue || 0)).format("0,0")}`
          }
        } else {
          return `-`
        }
      }
      return (
        <>
          <h3 className="headline underline border-callan-olive">Guidelines</h3>
          <Table hover className= " exportable" data-export-name={`Guidelines`}>
            <tbody>
              <tr>
                <td className="border-top-0 text-left">Deal Size ($M)</td>
                <td className="border-top-0 text-left">{handleGuidelineRanges(product.closedEnded?.dealPolicies?.dealSize?.lower, product.closedEnded?.dealPolicies?.dealSize?.higher)}</td>
              </tr>
              <tr key="numberOfInvestments">
                <td className="text-left">Number of Investments</td>
                <td className="text-left">
                  {handleGuidelineRanges(product.closedEnded?.dealPolicies?.numberOfInvestments?.lower, product.closedEnded?.dealPolicies?.numberOfInvestments?.higher, false)}
                </td>
              </tr>
              <tr>
                <td className="text-left">Development Limits</td>
                <td className="text-left">{handleGuidelineRanges(product.closedEnded?.dealPolicies?.developmentLimits?.lower, product.closedEnded?.dealPolicies?.developmentLimits?.higher, false)}</td>
              </tr>
              <tr>
                <td className="text-left">Single Asset Limits</td>
                <td className="text-left">{handleGuidelineRanges(product.closedEnded?.dealPolicies?.singleAssetLimits?.lower, product.closedEnded?.dealPolicies?.singleAssetLimits?.higher, false)}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    }
  }

  strategyFundRaising = (type:string) => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    if(product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedPrivateCredit" || product.__typename === "ClosedEndedRealAssets"){
      const projectedCloseDates = product.closedEnded?.projectedCloseDates
      return(
        <>
          <h3 className="headline underline border-callan-olive">Fundraising</h3>
          <Table hover className= " exportable" data-export-name={`Fundraising`}>
            <tbody>
              <tr>
                <td className="border-top-0 text-left">Committed Capital ($M)</td>
                <td className="border-top-0 text-left">{numbro((_.get(_.first(product.closedEnded?.fundRaising), "committedCapital") || 0) / ONE_MILLION).format(`${_.get(_.first(product.closedEnded?.fundRaising), "committedCapital") ? '$0,0' : '0,0' }`)}</td>
              </tr>
              {projectedCloseDates?.map((entry:Date | null, idx:number) => {
                let dateText = "Next Close"
                if(projectedCloseDates.length === idx + 1){
                  dateText = "Final Close"
                } else if(idx === 0) {
                  dateText = "First Close"
                }
                return(
                  <tr key={idx}>
                    <td className="text-left">{dateText}</td>
                    <td className="text-left">{entry && moment(entry || "").format("MMM YYYY")}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </>
      )
    }
  }

  strategyLeverage = (type:string) => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    if(product.__typename === "ClosedEndedPrivateEquity" || product.__typename === "ClosedEndedPrivateCredit"){
      return(
        <>
          <h3 className="headline underline border-callan-olive">Leverage</h3>
          <Table hover className= " exportable" data-export-name={`Leverage`}>
            <tbody>
              <tr>
                <td className="border-top-0 text-left">Short-Term Financing</td>
                <td className="border-top-0 text-left">{product.closedEnded?.financingType?.shortTerm?.used ? `Yes - ${product.closedEnded?.financingType?.shortTerm?.limit}% on ${product.closedEnded?.financingType?.shortTerm?.basis?.value}` : "No"}</td>
              </tr>
              <tr>
                <td className="text-left">Long-Term Financing</td>
                <td className="text-left">{product.closedEnded?.financingType?.longTerm?.used ? `Yes - ${product.closedEnded?.financingType?.longTerm?.limit}% on ${product.closedEnded?.financingType?.longTerm?.basis?.value}` : "No"}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    }
  }

  strategyFees = (type:string) => {
    const vehicle = this.representativeVehicle
    if(vehicle && "closedEndedVehicle" in vehicle){
      let investmentValue = "feesByYear"
      if(vehicle.closedEndedVehicle?.managementFees?.investmentPeriod?.feesByAum && vehicle.closedEndedVehicle?.managementFees?.investmentPeriod?.feesByAum.length > 0){
        investmentValue = "feesByAum"
      }
      let investmentList = _.reduce(_.get(vehicle.closedEndedVehicle?.managementFees?.investmentPeriod, investmentValue),
        (
          result: FeeData[],
          entry: any
        ) => {
          let investmentSpan = "yearNumber"
          if(investmentValue === "feesByAum"){
            investmentSpan = "aumTier"
          }
          if(result.length === 0) {
            result.push({feeSpan: [_.get(entry,investmentSpan)], value: entry.rate})
          } else if(_.last(result)?.value === entry.rate) {
            let additional = result.pop()
            additional?.feeSpan.push(_.get(entry,investmentSpan))
            result.push(additional || {feeSpan: [_.get(entry,investmentSpan)], value: entry.rate})
          } else {
            result.push({feeSpan: [_.get(entry,investmentSpan)], value: entry.rate})
          }
          return result
        },
        []
      ) || []
      let postInvestmentValue = "feesByYear"
      if(vehicle.closedEndedVehicle?.managementFees?.postInvestmentPeriod?.feesByAum && vehicle.closedEndedVehicle?.managementFees?.postInvestmentPeriod?.feesByAum.length > 0){
        postInvestmentValue = "feesByAum"
      }
      let postInvestmentList = _.reduce(_.get(vehicle.closedEndedVehicle?.managementFees?.postInvestmentPeriod, postInvestmentValue),
        (
          result: FeeData[],
          entry: any
        ) => {
          let investmentSpan = "yearNumber"
          if(postInvestmentValue === "feesByAum"){
            investmentSpan = "aumTier"
          }
          if(result.length === 0) {
            result.push({feeSpan: [_.get(entry,investmentSpan)], value: entry.rate})
          } else if(_.last(result)?.value === entry.rate) {
            let additional = result.pop()
            additional?.feeSpan.push(_.get(entry,investmentSpan))
            result.push(additional || {feeSpan: [_.get(entry,investmentSpan)], value: entry.rate})
          } else {
            result.push({feeSpan: [_.get(entry,investmentSpan)], value: entry.rate})
          }
          return result
        },
        []
      ) || []
      return(
        <>
          <h3 className="headline underline border-callan-olive">Fees</h3>
          <Table hover className= " exportable" data-export-name={`Fees`}>
            <tbody>
              {(vehicle.__typename === "ClosedEndedPrivateNonRegisteredPrivateCredit" || vehicle.__typename === "ClosedEndedPrivateNonRegisteredPrivateEquity") &&
                investmentList.map((investment, idx) => {
                let name = ""
                if(investmentValue === "feesByYear"){
                  if(investment.feeSpan.length === 1){
                    name = `Year ${_.first(investment.feeSpan)}`
                  } else {
                    name = `Year ${_.first(investment.feeSpan)}-${_.last(investment.feeSpan)}`
                  }
                } else {
                  if(investment.feeSpan.length === 1){
                    name = `$${_.first(investment.feeSpan)}`
                  } else {
                    name = `$${_.first(investment.feeSpan)}-$${_.last(investment.feeSpan)}`
                  }
                }
                if(idx === investmentList.length - 1){
                  name += "+"
                }
                return(
                  <tr key={idx}>
                    {idx === 0 && <td className="border-top-0 text-left" rowSpan={investmentList.length}>Investment Period</td>}
                    <td className={classnames({"border-top-0": idx === 0 , "text-left":true})}>{name}</td>
                    <td className={classnames({"border-top-0": idx === 0 , "text-right":true})}>{investment.value ? numbro(investment.value || 0).format("0.00%") : ' - '}</td>
                    {idx === 0 && <td className="border-top-0 text-left" rowSpan={investmentList.length}>on {vehicle.closedEndedVehicle?.managementFees?.investmentPeriod?.feeBasis?.value}</td>}
                  </tr>
                )
              })}
              {/* {investmentList.length === 0 &&
                <tr>
                  <td className="border-top-0 text-left">Investment Period</td>
                  <td className="border-top-0 text-left" colSpan={3}></td>
                </tr>
              }
              <tr>
                <td className="text-left">Inv. Period Detail</td>
                <td className="text-left" colSpan={3}>{vehicle.closedEndedVehicle?.managementFees?.investmentPeriod?.details}</td>
              </tr> */}
              {(vehicle.__typename === "ClosedEndedPrivateNonRegisteredPrivateCredit" || vehicle.__typename === "ClosedEndedPrivateNonRegisteredPrivateEquity") &&
                postInvestmentList.map((investment, idx) => {
                let name = ""
                if(postInvestmentValue === "feesByYear"){
                  if(investment.feeSpan.length === 1){
                    name = `Year ${_.first(investment.feeSpan)}`
                  } else {
                    name = `Year ${_.first(investment.feeSpan)}-${_.last(investment.feeSpan)}`
                  }
                } else {
                  if(investment.feeSpan.length === 1){
                    name = `$${_.first(investment.feeSpan)}`
                  } else {
                    name = `$${_.first(investment.feeSpan)}-$${_.last(investment.feeSpan)}`
                  }
                }
                if(idx === postInvestmentList.length - 1){
                  name += "+"
                }
                return(
                  <tr key={idx}>
                    {idx === 0 && <td className=" text-left" rowSpan={postInvestmentList.length}>Post Investment Period</td>}
                    <td className="text-left">{name}</td>
                    <td className="text-right">{investment.value ? numbro(investment.value || 0).format("0.00%") : ' - '}</td>
                    {idx === 0 && <td className="text-left" rowSpan={postInvestmentList.length}>on {vehicle.closedEndedVehicle?.managementFees?.postInvestmentPeriod?.feeBasis?.value}</td>}
                  </tr>
                )
              })}
              {/* {postInvestmentList.length === 0 &&
                <tr>
                  <td className="text-left">Post Investment Period</td>
                  <td className="text-left" colSpan={3}></td>
                </tr>
              }
              <tr>
                <td className="text-left">Post Inv. Period Detail</td>
                <td className="text-left" colSpan={3}>{vehicle.closedEndedVehicle?.managementFees?.postInvestmentPeriod?.details}</td>
              </tr> */}
              {vehicle.__typename === "ClosedEndedPrivateNonRegisteredPrivateEquity" &&
                <>
                  <tr>
                    <td className="text-left">Waterfall</td>
                    <td className="text-left" colSpan={3}>{vehicle.privateEquityVehicle?.waterfall?.value}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Hurdle</td>
                    {_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdleReturnType?.code === "TVPI" &&
                      <td className="text-left" colSpan={3}>{numbroFormatter(multiply(_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdle || 0, 100),'0,0[.]00')}{_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdle ? 'x' : '-'} {_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdleReturnType?.value}</td>
                    }
                    {_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdleReturnType?.code === "IRR" &&
                      <td className="text-left" colSpan={3}>{numbroFormatter(multiply(_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdle || 0, 100),'0,0[.]00')}{_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdle ? '%' : '-'} {_.maxBy(vehicle.privateEquityVehicle?.hurdle, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.hurdleReturnType?.value}</td>
                    }
                  </tr>
                  <tr>
                    <td className="text-left">Carry</td>
                    <td className="text-left" colSpan={3}>{`${numbroFormatter(multiply(_.maxBy(vehicle.privateEquityVehicle?.carry, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.rate || 0, 100),'0,0[.]00')}%`}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Offset</td>
                    <td className="text-left" colSpan={3}>{`${numbroFormatter(multiply(_.maxBy(vehicle.privateEquityVehicle?.offset, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.rate || 0, 100),'0,0[.]00')}%`}</td>
                  </tr>
                </>
              }
              {vehicle.__typename === "ClosedEndedPrivateNonRegisteredRealAssets" &&
                <>
                  <tr>
                    <td className="text-left">Performance Fee</td>
                    <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.performanceFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.rate}%</td>
                  </tr>
                  <tr>
                    <td className="text-left">Performance Fee Details</td>
                    <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.performanceFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.details}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Acquisition Fee</td>
                    <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.acquisitionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.rate}%</td>
                  </tr>
                  <tr>
                    <td className="text-left">Acquisition Fee Details</td>
                    <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.acquisitionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.details}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Disposition Fee</td>
                    <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.dispositionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.rate}%</td>
                  </tr>
                  <tr>
                    <td className="text-left">Disposition Fee Details</td>
                    <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.dispositionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.details}</td>
                  </tr>
                </>
              }
            </tbody>
          </Table>
        </>
      )
    } else if (vehicle && "hedgeFundVehicle" in vehicle){
      const datedFees = _.maxBy(vehicle.vehicle?.datedFees, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())

      const vehicleHurdleBenchmarkName = _.maxBy(vehicle.hedgeFundVehicle?.hurdleBenchmark, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.benchmark?.vehicle?.name
      const vehicleHurdleBenchmarkAdd = _.maxBy(vehicle.hedgeFundVehicle?.hurdleBenchmark, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.benchmarkAdd

      const hurdle = (vehicleHurdleBenchmarkName && vehicleHurdleBenchmarkAdd) ?
          `${vehicleHurdleBenchmarkName} + ${numbro(vehicleHurdleBenchmarkAdd || 0).format("0%")}`
        : vehicleHurdleBenchmarkName ?
           vehicleHurdleBenchmarkName
        : vehicleHurdleBenchmarkAdd ?
         numbro(vehicleHurdleBenchmarkAdd || 0).format("0%")
        : null

      return(
        <>
          <h3 className="headline underline border-callan-olive">Fees</h3>
          <Table hover className= " exportable" data-export-name={`Fees-2`}>
            <tbody>
              <tr>
                <td className="border-top-0 text-left">Minimum Size</td>
                <td className="border-top-0 text-left">{datedFees?.minimumSize}</td>
              </tr>
              <tr>
                <td className="text-left">Minimum Annual Fee</td>
                <td className="text-left">{datedFees?.minimumAnnualFee}</td>
              </tr>
              <tr>
                <td className="text-left">Management Fee (%)</td>
                <td className="text-left">{_.get(vehicle.hedgeFundVehicle, "feeAtAum50", {} as FeeAtAum)?.fee}%</td>
              </tr>
              <tr>
                <td className="text-left">Most Favored Nation</td>
                <td className="text-left">{vehicle.hedgeFundVehicle?.mostFavoredNation  ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td className="text-left">Highwater Mark</td>
                <td className="text-left">{vehicle.hedgeFundVehicle?.highWaterMark ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td className="text-left">Hurdle</td>
                <td className="text-left">{hurdle} </td>
              </tr>
              <tr>
                <td className="text-left">Rebates</td>
                <td className="text-left">{vehicle.hedgeFundVehicle?.rebates ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td className="text-left">Redemption Fees Apply</td>
                <td className="text-left">{vehicle.hedgeFundVehicle?.redemptionFees ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    } else if (vehicle && "realAssetsVehicle" in vehicle){
      let value50: number = 0
      if ("pooledVehicle" in vehicle) {
         value50 = _.maxBy(_.get((vehicle.pooledVehicle as PooledVehicleFields), "feeAtAum50", [] as FeeAtAum[]), (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.fee || 0
      }
      return(
        <>
          <h3 className="headline underline border-callan-olive">Fees</h3>
          <Table hover className= " exportable" data-export-name={`Fees-3`}>
            <tbody>
              <tr>
                <td className="text-left border-top-0">Fund</td>
                <td className="text-left border-top-0" colSpan={3}>{vehicle.vehicle?.name}</td>
              </tr>
              <tr>
                <td className="text-left">Min</td>
                <td className="text-left" colSpan={3}>{numbro(_.maxBy(vehicle.vehicle?.datedFees , (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.minimumSize || 0).format("$0,0.00")} </td>
              </tr>
              <tr>
                <td className="text-left">Fee at $50(M)</td>
                <td className="text-left" colSpan={3}>{value50 ? numbro(value50 || 0).format("%0.00") : ' - ' }</td>
              </tr>
              <tr>
                <td className="text-left">Minimum Annual Fee</td>
                <td className="text-left" colSpan={3}>{_.maxBy(vehicle.vehicle?.datedFees , (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.minimumAnnualFee || 0} </td>
              </tr>
              <tr>
                <td className="text-left">Acquisition Fee</td>
                <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.acquisitionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.rate}%</td>
              </tr>
              <tr>
                <td className="text-left">Acquisition Fee Details</td>
                <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.acquisitionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.details}</td>
              </tr>
              <tr>
                <td className="text-left">Disposition Fee</td>
                <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.dispositionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.rate}%</td>
              </tr>
              <tr>
                <td className="text-left">Disposition Fee Details</td>
                <td className="text-left" colSpan={3}>{_.maxBy(vehicle.realAssetsVehicle?.dispositionFee, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.details}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    }
  }

  strategyFeesTable = (type:string) => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    let filteredVehicles = filter(product.product?.vehicles || [], (v) => {
      return v?.vehicle?.status?.code !== "t"
    })
    const sortedVehicles = sortBy(filteredVehicles, [i => i?.vehicle?.category?.value, i => i?.vehicle?.name]) as VehicleType[]
    return(
      <>
        <h3 className="headline underline border-callan-olive">Vehicle Fees</h3>
        <Table hover className= " exportable" data-export-name={`Fees-4`}>
          <tbody>
            <tr>
              <td className="border-top-0 border-callan-olive text-left">Vehicle Name</td>
              <td className="border-top-0 border-callan-olive text-left">Vehicle Category</td>
              {!this.props.stableValueProduct &&
                <td className="border-top-0 border-callan-olive text-left">Ticker/CUSIP</td>
              }
              <td className="border-top-0 border-callan-olive text-right">Min</td>
              {this.props.stableValueProduct &&
                <>
                  <td className="border-top-0 border-callan-olive text-right">%Wrapped</td>
                  <td className="border-top-0 border-callan-olive text-right">%Sub-Advised</td>
                </>
              }
              <td className="border-top-0 border-callan-olive text-right">Fee at $50 (M)</td>
              {!this.props.stableValueProduct &&
                <td className="border-top-0 border-callan-olive text-right">Fee at $100 (M)</td>
              }
            </tr>
            {sortedVehicles?.map((vehicle,idx) => {
              let value50:number | undefined = undefined
              let value100:number | undefined = undefined
              let wrappedPercentage: number = 0
              let subAdvisedPercentage: number = 0
              if(vehicle.__typename === "OpenEndedMutualFund" ){
                value50 = _.maxBy(vehicle.mutualFund?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
              } else if (vehicle.__typename === "OpenEndedExchangeTradedFund"){
                value50 = _.maxBy(vehicle.exchangeTradedFund?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
              } else if (vehicle.__typename === "OpenEndedPooledVehicle" || vehicle.__typename === "OpenEndedPooledVehiclePrivateEquity" || vehicle.__typename === "OpenEndedPooledVehicleRealAssets" || vehicle.__typename === "OpenEndedPooledVehiclePrivateCredit"){
                value50 = _.get(vehicle.pooledVehicle, "feeAtAum50.fee", 0)
                value100 = _.get(vehicle.pooledVehicle, "feeAtAum100.fee", 0)
              } else if (vehicle.__typename === "OpenEndedVariableAnnuity"){
                value50 = _.maxBy(vehicle.variableAnnuity?.datedFees, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
              } else if (vehicle.__typename === "OpenEndedCollectiveInvestmentFundComposite"){
                value50 = _.maxBy(vehicle.collectiveInvestmentFundComposite?.datedFees, (i) => !i?.equalWeightedFee ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.equalWeightedFee || 0
                value100 = value50
              } else if (vehicle.__typename === "OpenEndedSeparateAccount" || vehicle.__typename === "OpenEndedSeparateAccountRealAssets"){
                value50 = _.get(vehicle.separateAccount, "feeAtAum50.fee")
                value100 = _.get(vehicle.separateAccount, "feeAtAum100.fee")
              } else if (vehicle.__typename === "OpenEndedPrivateNonRegisteredHedgeFund"){
                value50 = _.get(vehicle.hedgeFundVehicle, "feeAtAum50.fee")
                value100 = _.get(vehicle.hedgeFundVehicle, "feeAtAum100.fee")
              } else if (vehicle.__typename === "OpenEndedCollectiveInvestmentFund"){
                value50 = _.maxBy(vehicle.collectiveInvestmentFund?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
              } else if (vehicle.__typename === "OpenEndedCollectiveInvestmentFundStableValueComposite" || vehicle.__typename === "OpenEndedCollectiveInvestmentFundStableValue"){
                value50 = _.maxBy(vehicle.stableValueCollectiveInvestmentFund?.fees, (i) => !i?.allInFee ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.allInFee || 0
                wrappedPercentage = _.maxBy(vehicle.stableValue?.fees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedWrapPctOfApplicableWrappedAssets || 0
                subAdvisedPercentage = _.maxBy(vehicle.stableValue?.fees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets || 0
                value100 = value50
              } else if (vehicle.__typename === "OpenEndedSeparateAccountStableValue"){
                value50 = _.get(vehicle.stableValueSeparateAccount, "feeAtAum50.fee")
                value100 = _.get(vehicle.stableValueSeparateAccount, "feeAtAum100.fee")
                wrappedPercentage = _.maxBy(vehicle.stableValue?.fees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedWrapPctOfApplicableWrappedAssets || 0
                subAdvisedPercentage = _.maxBy(vehicle.stableValue?.fees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets || 0
              }
              const minValue = _.maxBy(vehicle.vehicle?.datedFees, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.minimumSize
              return(
                <tr key={idx}>
                  <td className="text-left">
                    <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productVehicleFeesLinkRenderer(vehicle, this.props.data.product?.product?.id))}>
                      {vehicle?.vehicle?.name}
                    </Button>
                  </td>
                  <td className="text-left">{vehicle?.vehicle?.category?.value}</td>
                  {!this.props.stableValueProduct &&
                    <td className="text-left">{vehicle?.vehicle?.identifiers?.ticker || vehicle?.vehicle?.identifiers?.cusip}</td>
                  }
                  <td className="text-right">{!!minValue ? numbro(minValue || 0).format("$0,0.00") : " - "}</td>
                  {this.props.stableValueProduct &&
                    <>
                      <td className="text-right">{wrappedPercentage ? numbro(wrappedPercentage || 0).format("0.00%") : " - " }</td>
                      <td className="text-right">{subAdvisedPercentage ? numbro(subAdvisedPercentage || 0).format("0.00%") : " - "}</td>
                    </>
                  }
                  <td className="text-right">{!!value50 ? numbro(value50).format("0.00%") : " - "}</td>
                  {!this.props.stableValueProduct &&
                    <td className="text-right">{!!value100 ? numbro(value100).format("0.00%") : " - "}</td>
                  }
                </tr>
              )
            })}
          </tbody>
        </Table>
      </>
    )
  }

  clientFeesTable = (type:string) => {
    const product:ProductTypes = this.props.data.product as ProductTypes
    let portfolioExposure = filter(product.product?.productRelatedClientPortfolioExposure || [], (v) => {
      // https://callanllc.atlassian.net/browse/CAL-3081?focusedCommentId=85222
      return v?.vehicle?.vehicle?.status?.code !== "t" && v?.clientAssets && v?.portfolio?.endAssetDate === "9999-12-31"
    }) as ProductRelatedClientPortfolioExposure[]
    if(!portfolioExposure?.length || !this.props.auth.checkPermissions(["view:client_fees"])) return (<></>)
    const sortedVehicles = sortBy(portfolioExposure, [i => i?.vehicle?.vehicle?.category?.value, i => i?.vehicle?.vehicle?.name, i => i?.portfolio?.plan?.name])
    return (<>
      <h3 className="headline underline border-callan-olive">Client Fees</h3>
        <Table hover className= " exportable" data-export-name={`Fees-4`}>
          <tbody>
            <tr>
              <td className="border-top-0 border-callan-olive text-left">Plan</td>
              <td className="border-top-0 border-callan-olive text-left">Vehicle Name</td>
              <td className="border-top-0 border-callan-olive text-left">Vehicle Category</td>
              <td className="border-top-0 border-callan-olive text-right">Assets Invested ($M)</td>
              {this.props.stableValueProduct &&
                <>
                  <td className="border-top-0 border-callan-olive text-right">%Wrapped</td>
                  <td className="border-top-0 border-callan-olive text-right">%Sub-Advised</td>
                  <td className="border-top-0 border-callan-olive text-right">Management Fee at $50 (M)</td>
                </>
              }
              {!this.props.stableValueProduct &&
              <>
                <td className="border-top-0 border-callan-olive text-right">Fee at $50 (M)</td>
                <td className="border-top-0 border-callan-olive text-right">Fee at $100 (M)</td>
              </>
              }
            </tr>
            {sortedVehicles?.map((vehicleField,idx) => {

              let value50:number | undefined = undefined
              let value100:number | undefined = undefined
              let wrappedPercentage: number = 0
              let subAdvisedPercentage: number = 0
              let vehicle = vehicleField?.vehicle as VehicleType

              // CAL-3188 show vehicle fees if client fees not collected.
              const clientFeeQuestionnaireFeesRequired = vehicleField?.portfolio?.clientSpecificDataCollectionFields?.questionnaireFeesRequired
              if(vehicle.__typename === "OpenEndedMutualFund" ){
                value50 = _.maxBy(vehicleField?.portfolio?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
                if(!clientFeeQuestionnaireFeesRequired){
                  value50 = _.maxBy(vehicle.mutualFund?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                  value100 = value50
                }
              } else if (vehicle.__typename === "OpenEndedExchangeTradedFund"){
                value50 = _.maxBy(vehicleField?.portfolio?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
                if(!clientFeeQuestionnaireFeesRequired){
                  value50 = _.maxBy(vehicle.exchangeTradedFund?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                  value100 = value50
                }
              } else if (vehicle.__typename === "OpenEndedPooledVehicle" || vehicle.__typename === "OpenEndedPooledVehiclePrivateEquity" || vehicle.__typename === "OpenEndedPooledVehicleRealAssets" || vehicle.__typename === "OpenEndedPooledVehiclePrivateCredit"){
                value50 = _.get(vehicleField?.portfolio, "feeAtAum50.fee", 0)
                value100 = _.get(vehicleField?.portfolio, "feeAtAum100.fee", 0)
              } else if (vehicle.__typename === "OpenEndedVariableAnnuity"){
                value50 = _.maxBy(vehicleField?.portfolio?.datedFees, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
                if(!clientFeeQuestionnaireFeesRequired) {
                  value50 = _.maxBy(vehicle.variableAnnuity?.datedFees, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                  value100 = value50
                }
              } else if (vehicle.__typename === "OpenEndedCollectiveInvestmentFundComposite"){
                value50 = _.maxBy(vehicleField?.portfolio?.datedFees, (i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
              } else if (vehicle.__typename === "OpenEndedSeparateAccount" || vehicle.__typename === "OpenEndedSeparateAccountRealAssets"){
                value50 = _.get(vehicleField?.portfolio, "feeAtAum50.fee")
                value100 = _.get(vehicleField?.portfolio, "feeAtAum100.fee")
              } else if (vehicle.__typename === "OpenEndedPrivateNonRegisteredHedgeFund"){
                value50 = _.get(vehicleField?.portfolio, "feeAtAum50.fee")
                value100 = _.get(vehicleField?.portfolio, "feeAtAum100.fee")
                if(!clientFeeQuestionnaireFeesRequired) {
                  value50 = _.get(vehicle.hedgeFundVehicle, "feeAtAum50.fee")
                  value100 = _.get(vehicle.hedgeFundVehicle, "feeAtAum100.fee")
                }
              } else if (vehicle.__typename === "OpenEndedCollectiveInvestmentFund"){
                value50 = _.maxBy(vehicleField?.portfolio?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                value100 = value50
                if(!clientFeeQuestionnaireFeesRequired) {
                  value50 = _.maxBy(vehicle.collectiveInvestmentFund?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                  value100 = value50
                }
              } else if (vehicle.__typename === "OpenEndedCollectiveInvestmentFundStableValueComposite" || vehicle.__typename === "OpenEndedCollectiveInvestmentFundStableValue"){
                value50 = _.maxBy(vehicleField?.portfolio?.datedFees, (i) => !i?.totalExpense ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.totalExpense || 0
                wrappedPercentage = _.maxBy(vehicleField?.portfolio?.datedFees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedWrapPctOfApplicableWrappedAssets || 0
                subAdvisedPercentage = _.maxBy(vehicleField?.portfolio?.datedFees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets || 0
                value100 = value50
                if(!clientFeeQuestionnaireFeesRequired && vehicle.__typename === "OpenEndedCollectiveInvestmentFundStableValue") {
                  value50 = _.maxBy(vehicle.stableValueCollectiveInvestmentFund?.fees, (i) => !i?.allInFee ? 0 : moment(i?.date, DATE_API_FORMAT).valueOf())?.allInFee || 0
                  value100 = value50
                }
              } else if (vehicle.__typename === "OpenEndedSeparateAccountStableValue"){
                value50 = _.get(vehicleField?.portfolio, "feeAtAum50.fee")
                value100 = _.get(vehicleField?.portfolio, "feeAtAum100.fee")
                wrappedPercentage = _.maxBy(vehicleField?.portfolio?.datedFees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedWrapPctOfApplicableWrappedAssets || 0
                subAdvisedPercentage = _.maxBy(vehicleField?.portfolio?.datedFees,(i) => moment(i?.date, DATE_API_FORMAT).valueOf())?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets || 0
              }

              const client = vehicleField as ProductRelatedClientPortfolioExposure
              let clientAssetsMillions = '-'
              const assetsMillion = (client.clientAssets || 0) / 1000000
                if(client.clientAssets == null){
                  clientAssetsMillions = "N/A"
                }
                else {
                  clientAssetsMillions = numbro(assetsMillion || 0).format(`${assetsMillion ? '$0,0.00' : '0,0.00'}`)
                }
              return(
                <tr key={idx}>
                  <td className="text-left">
                    <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => 
                      this.navigateToScreen(portfolioClientFundLinkRenderer(client))}>
                      {(sortedVehicles.find((_, index) => index === idx))?.portfolio?.plan?.name || ""}
                    </Button>
                  </td>
                  <td className="text-left">
                    <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productVehicleFeesLinkRenderer(vehicle, this.props.data.product?.product?.id))}>
                      {vehicle?.vehicle?.name}
                    </Button>
                  </td>
                  <td className="text-left">{vehicle?.vehicle?.category?.value}</td>
                  <td className="text-right">{clientAssetsMillions}</td>
                  {this.props.stableValueProduct &&
                    <>
                      <td className="text-right">{wrappedPercentage ? numbro(wrappedPercentage || 0).format("0.00%") : " - " }</td>
                      <td className="text-right">{subAdvisedPercentage ? numbro(subAdvisedPercentage || 0).format("0.00%") : " - "}</td>
                      <td className="text-right">{!!value50 ? numbro(value50).format("0.00%") : " - "}</td>
                    </>
                  }
                  {!this.props.stableValueProduct &&
                    <>
                      <td className="text-right">{!!value50 ? numbro(value50).format("0.00%") : " - "}</td>
                      <td className="text-right">{!!value100 ? numbro(value100).format("0.00%") : " - "}</td>
                    </>
                  }
                </tr>
              )
            })}
          </tbody>
        </Table>
    </>)
  }

  glidePathGraph = (glidePathVersion: GlidePathVersion) => {
    const allocations = _.maxBy(glidePathVersion.glidePath?.detailedAllocations, (i) => moment(i?.revisionDate, DATE_API_FORMAT).valueOf())?.years
    // Only show data on the 5 years starts at 1 as -40 years
    const years = allocations?.map((allocation) => allocation?.yearNumber)?.filter((year) => (year || 0) <= 76 && ((year|| 0) % 5 === 1) )

    let assetClasses:string[] = []
    const flatAllocations = _.flatMap(allocations, (allocation) => allocation?.allocations.map((detail) => {
      assetClasses = _.union(assetClasses, [detail?.assetClass?.shortName || ""]) || [detail?.assetClass?.shortName]
      return{"name": detail?.assetClass?.shortName, "year": allocation.yearNumber, "weight": detail?.targetWeight}
    }))
    const groupedAllocations = _.groupBy(flatAllocations, (allocation) => allocation?.name)

    let graphData: GraphData[] = Object.keys(groupedAllocations).map((assetClass, idx) => {
      const allocation = groupedAllocations[assetClass]
      const data = years?.map((year) => _.find(allocation, {"year": year})?.weight || 0) || []
      return(
        {
          name: assetClass,
          data: data
        }
      )
    })

    // CAL-1622: Hard code to pivot to 40 years
    const xAxis = years?.map((year) => ((41) - (year || 0)).toString())
    return(
      <>
        <ChartContainer
          options={PercentAreaOptions({heading: "", subHeading: glidePathVersion.name || "", xAxis: xAxis, data: graphData, colours: graphColors.map((cl) => cl.hex)})}
          height={400}
          hasData={graphData.length > 0}
          modalName={"Glide Path"}
        />
        <Button color="blue-link" className="float-right" onClick={() => this.props.history.push((`/glidepaths/${glidePathVersion.id}/glidepath/allocation`))}>
          <span className="pr-1">View Glide Path Allocation</span>
          <FontAwesomeIcon
            icon={"chevron-right"}
            size="sm"
          />
        </Button>
      </>
    )
  }

  loopValue = (array:any[] | null | undefined, field:string, heading:string) => {
    if(!array){
      return(
        <tr>
            <td className="text-left">{heading}</td>
            <td className="text-left"></td>
        </tr>
      )
    }
    return(
      <>
        {array.map((entry:any, idx:number) => {
          return(
            <tr key={idx}>
              <td className="text-left">{idx === 0 && heading}</td>
              <td className="text-left">{_.get(entry, field)}</td>
            </tr>
          )
        })}
      </>
    )
  }
}


export default ProductProfile
import { GridApi } from '@ag-grid-community/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import { compact, first, get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { match } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Row } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { draggingStatus, expandList, ListHeaderTracker, listMembersAny, typeIdMapping, updateListItem, UpdateListItemsProps } from '../../helpers/list'
import { FileFullDetailsFragment, ListDetailFragment, ListFavoriteInput, ListMemberIdType, ListOrder, useUpdateListMutation, UpdateDynamicListInput, useCreateListHeaderMutation, useCreatePrimaryExcludeListMutation, useDeleteListHeaderMutation, useFavoriteListMutation, useListDetailQuery, useMeQuery, useSetListOrderMutation, useUnfavoriteListMutation, useUpdateDynamicListFiltersMutation, useUpdateListHeaderMutation, useUpdateStaticListMembersMutation, UpdateListInput, UpdateListFields, ListType, ListDetailQuery, MeQuery } from '../../__generated__/graphql'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import EditButtons from '../ui/EditButtons'
import { WithTopNav } from '../ui/LayoutWrapper'
import PlaceHolder from '../ui/PlaceHolder'
import ListDetailAddItem from './ListDetailAddItem'
import ListDetailSidebar from './ListDetailSidebar'
import ListDetailTable from './ListDetailTable'
import { ListSettingsModal } from './ListSettingsModal'
import { registerRecentList } from "../../helpers/session"
import {CsvExportLimitModal} from '../Shared/CsvExportLimitModal'
import LoadingOverlay from '../ui/Loading'
import ErrorDisplay from '../Shared/ErrorDisplay'


type ListDetailProps = {
  history: History
  location: Location
  auth: Auth
  match: match & {params: { listId: string}}
}

export type documentTableData = {
  clientId: number
  reportId: number
  associations: string[]
} & FileFullDetailsFragment

enum savingStatus{
  Initial = 1,
  StartPrimaryExclude = 2,
  CreatingPrimaryExclude = 3,
  StartItems = 4,
  UpdatingItems = 5,
  StartOrder = 6,
  UpdatingOrder = 7,
  Error = 8,
}

const ListDetail: React.FC<ListDetailProps> = ({ history, location, auth, match }) => {
  const authAccess = auth.checkPermissions(["edit:lists"])
  if(!authAccess){
    history.replace('/lists')
  }

  let {data: currentUserData} = useMeQuery({fetchPolicy: "cache-first"})
  const { loading, data, error } = useListDetailQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: parseInt(match.params.listId) },
    onCompleted: (incomingData) => {
      // setEditedList(incomingData.list || undefined)
    }
  })

  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <div>
        <ErrorDisplay error={error}/>
      </div>
    )
  }
  if (!loading && data) {
    return (
      <ListDetailMain
        auth={auth}
        history={history}
        location={location}
        match={match}
        currentUserData={currentUserData}
        data={data}
      />
    )
  } else {
    return <div>data doesn't exist</div>
  }

}

type ListDetailMainProps = {
  history: History
  location: Location
  auth: Auth
  match: match & {params: { listId: string}}
  currentUserData?: MeQuery
  data?: ListDetailQuery
}


const ListDetailMain: React.FC<ListDetailMainProps> = ({ history, location, auth, match, currentUserData, data }) => {
  const authAccess = auth.checkPermissions(["edit:lists"])
  const urlParams = new URLSearchParams(location.search);
  const [editMode, setEditMode] = useState(urlParams.get('editMode') === 'true' ? true : false)
  const [saving, setSaving] = useState(savingStatus.Initial)
  const [dragging, setDragging] = useState(draggingStatus.Initial)
  const [savingCallCount, setSavingCallCount] = useState(0)
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const [settingsModalOpen, setSettingsModalOpen] = useState(false)
  // const [showAll, setShowAll] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [editedList, setEditedList] = useState<ListDetailFragment | undefined>(() => data?.list || undefined)
  const [listHeaders, setListHeaders] = useState<ListHeaderTracker[]>([])
  const baseUpdateList = {
    add: {},
    remove: {},
  }
  const [excludeListInput, setExcludeListInput] = useState<UpdateListItemsProps>(baseUpdateList)
  const [mainListInput, setMainListInput] = useState<UpdateListItemsProps>(baseUpdateList)
  const [csvExportModalOpen, setCsvExportModalOpen] = useState(false)

  // const addFileInputRef = useRef<HTMLInputElement>(null)
  // const [search, setSearch] = useState("")
  // const { resetErrors } = useContext(EditButtonContext)


  const personAccess = data && currentUserData?.me?.person?.id && (data.list?.owner?.id === currentUserData.me.person.id || data.list?.personAccess?.map((pa) => pa?.editable && pa?.person?.id).includes(currentUserData.me.person.id))
  const departmentAccess = data && currentUserData?.me?.person?.department?.code && data.list?.departmentAccess?.map((da) => da?.editable && da?.department?.code).includes(currentUserData.me.person.department.code)
  const orgAccess = data && currentUserData?.me?.person?.employer?.id && data.list?.orgAccess?.map((oa) => oa?.editable && oa.org?.id).includes(currentUserData.me.person.employer.id)
  const authAccessAny = auth.checkPermissions(["edit:all_lists"])
  const canEdit = authAccess && (personAccess || departmentAccess || orgAccess || authAccessAny)

  const [setListOrder] = useSetListOrderMutation()
  const [createPrimaryExcludeList] = useCreatePrimaryExcludeListMutation()
  const [updateList] = useUpdateListMutation()
  const [createListHeader] = useCreateListHeaderMutation()
  const [updateListHeader] = useUpdateListHeaderMutation()
  const [deleteListHeader] = useDeleteListHeaderMutation()
  const [updateStaticListMembers] = useUpdateStaticListMembersMutation()
  const [updateDynamicListFilters] = useUpdateDynamicListFiltersMutation()

  const [favoriteList] = useFavoriteListMutation()
  const [unfavoriteList] = useUnfavoriteListMutation()

  const toggleFavorite = (favorite: boolean) => {
    if(!currentUserData?.me?.person?.id || !data?.list?.id){
      return
    }
    const favoriteInput:ListFavoriteInput = {
      listIds: [data.list.id],
      personId: currentUserData.me.person.id,
    }
    const favoriteFunction = favorite ? favoriteList : unfavoriteList
    favoriteFunction({variables: {input: favoriteInput}}).then(()=>{
      setEditedList((editedList) => {
        if(!editedList){
          return editedList
        }
        return {
          ...editedList,
          isCurrentUsersFavorite: favorite,
        }
      })
    })
  }

  const openCsvExportModal = () => {
    setCsvExportModalOpen(!csvExportModalOpen)
  }

  const exportToCsv =() => {
    let exportedRows = 0;
    gridApi?.exportDataAsCsv({
      onlySelected: gridApi?.getSelectedRows().length > 0,
      shouldRowBeSkipped: () => {
        exportedRows++
        if(exportedRows > 1000){
          openCsvExportModal()
        }
        return exportedRows > 10000
      }
    })
  }

  const openSettingsModal = () => {
    setSettingsModalOpen(!settingsModalOpen)
    // addFileInputRef?.current?.click()
  }

  const handleEdit = (mode:boolean) => {
    setEditMode(mode)
    if(data?.list){
      setEditedList(data.list)
    }
  }

  useEffect(() => {
    if(saving === savingStatus.StartOrder && editedList?.items){
      // use hook so order update only happens after all others.
      const updatedOrder = compact(editedList.items?.map((item):ListOrder | undefined => {
        if(item.group && (item.order || item.order === 0) && item.type){
          return {
            memberId: get(item, `item.${typeIdMapping[item.type]}`, "").toString(),
            idType: item.type,
            order: item.order,
            group: item.group,
          }
        }
        return undefined
      }))
      if(updatedOrder.every(el => el?.idType !== "list_header" ||  (el?.memberId) > "0")) {
        setListOrder({
          variables:{
            input: {
              id: editedList.id,
              order: updatedOrder
            }
          }
        }).then(result => {
          setSaving(savingStatus.Initial)
          if (result && result.data) {
            setListHeaders([])
            setEditMode(false)
            setEditedList(result.data.setListOrder?.list || undefined)
            setExcludeListInput(baseUpdateList)
            setMainListInput(baseUpdateList)
            setSavingCallCount(0)
          }
        })
        .catch(err => {
          setSaving(savingStatus.Error)
          console.error("Error Update list order:", err.message)
        })
      }
    }
  }, [saving, editedList?.items])

  const handleSubmit = () => {
    if(!canEdit){
      return
    }
    if(!editedList?.primaryExcludeList && editedList?.type !== ListType.Static){
      console.log("create primary Exclude", editedList?.primaryExcludeList)
      setSaving(savingStatus.StartPrimaryExclude)
    } else {
      setSaving(savingStatus.StartItems)
    }
  }

  // State system for saving
  // 0 - Create primary exclude list if missing
  // 1 - Update List, Update headers, Main list and static members for main list and exclude list, Dynamic filters
  // 2 - Update ordering
  if(editedList){
    if(saving === savingStatus.StartPrimaryExclude){
      setSaving(savingStatus.CreatingPrimaryExclude)
      createPrimaryExcludeList({
        variables: {
          input: {
            listId: editedList.id,
          }
        }
      }).then(result => {
        setSaving(savingStatus.StartItems)
        if (result && result.data) {
          setEditedList({...editedList, primaryExcludeList: result.data.createPrimaryExcludeList?.list?.primaryExcludeList})
        }
      })
      .catch(err => {
        setSaving(savingStatus.Error)
        console.error("Error Create Primary exclude:", err.message)
      })
    } else if(saving === savingStatus.StartItems){
      setSaving(savingStatus.UpdatingItems)
      let totalCalls = 0
      // Update List
      const previousIncludeIds = data?.list?.includeLists?.map((l) => l.id)
      const previousExcludeIds = data?.list?.excludeList?.map((l) => l.id)
      const editedIncludeIds = editedList.includeLists?.map((l) => l.id)
      const editedExcludeIds = editedList.excludeList?.map((l) => l.id)
      const addInclude = editedIncludeIds?.filter((l) => !previousIncludeIds?.includes(l)) || []
      const removeInclude = previousIncludeIds?.filter((l) => !editedIncludeIds?.includes(l)) || []
      const addExclude = editedExcludeIds?.filter((l) => !(previousExcludeIds?.includes(l) || data?.list?.primaryExcludeList?.id === l)) || []
      const removeExclude = previousExcludeIds?.filter((l) => !(editedExcludeIds?.includes(l) || editedList.primaryExcludeList?.id === l)) || []
      if(addInclude.length > 0 || removeInclude.length > 0 || addExclude.length > 0 || removeExclude.length > 0){
        totalCalls += 1
        let patch = {
          name: editedList.name,
          includeLists: {
            add: addInclude,
            remove: removeInclude,
          },
          excludeLists: {
            add: addExclude,
            remove: removeExclude,
          }
        } as UpdateListFields
        let input = {
          id: editedList.id,
          patch: patch
        } as UpdateListInput
        updateList({variables: {input: input}})
        .then(result => {
          setSavingCallCount((savingCallCount) => savingCallCount - 1)
        })
        .catch(err => {
          setSaving(savingStatus.Error)
          console.error("Error updating list:", err.message)
        })
      }
      // Update Headers
      listHeaders.forEach((header) => {
        totalCalls += 1
        if(header.status === "created"){
          createListHeader({variables: {
            input: {
              listId: editedList.id,
              text: header.value
            }
          }}).then(result => {
            if (result && result.data) {
              setEditedList((editedList) => {
                setSavingCallCount((savingCallCount) => savingCallCount - 1)
                if(editedList?.items){
                  const updatedItems = updateListItem(editedList?.items, `list_header:${header.id}`, (item)=>{
                    return {
                      ...item,
                      item: result.data?.createListHeader
                    }
                  })
                  return {...editedList, items: updatedItems}
                }
                return editedList
              })
            } else {
              setSavingCallCount((savingCallCount) => savingCallCount - 1)
            }
          })
          .catch(err => {
            setSaving(savingStatus.Error)
            console.error("Error Create list header:", err.message)
          })
        } else if (header.status === "updated"){
          updateListHeader({variables: {
            input: {
              id: header.id,
              patch: {
                text: header.value
              }
            }
          }}).then(result => {
            if (result && result.data) {
              setEditedList((editedList) => {
                setSavingCallCount((savingCallCount) => savingCallCount - 1)
                if(editedList?.items){
                  const updatedItems = updateListItem(editedList?.items, `list_header:${header.id}`, (item)=>{
                    return {
                      ...item,
                      item: result.data?.updateListHeader
                    }
                  })
                  return {...editedList, items: updatedItems}
                }
                return editedList
              })
            } else {
              setSavingCallCount((savingCallCount) => savingCallCount - 1)
            }
          })
          .catch(err => {
            setSaving(savingStatus.Error)
            console.error("Error Update list header:", err.message)
          })
        } else if(header.status === "deleted"){
          if(header.id < 0) return
          deleteListHeader({variables: {
            input: {
              id: header.id,
            }
          }}).then(result => {
            if (result && result.data) {
              setEditedList((editedList) => {
                setSavingCallCount((savingCallCount) => savingCallCount - 1)
                if(editedList?.items){
                  const updatedItems = updateListItem(editedList?.items, `list_header:${header.id}`, (item)=>{
                    return undefined
                  })
                  return {...editedList, items: updatedItems}
                }
                return editedList
              })
            } else {
              setSavingCallCount((savingCallCount) => savingCallCount - 1)
            }
          })
          .catch(err => {
            setSaving(savingStatus.Error)
            console.error("Error Delete list header:", err.message)
          })
        }
      })
      // Save static lists
      if(listMembersAny(mainListInput)){
        totalCalls += 1
        updateStaticListMembers({variables: {
          input: {
            id: editedList.id,
            ...mainListInput
          }
        }}).then(result => {
          setSavingCallCount((savingCallCount) => savingCallCount - 1)
          // Don't update list as it cannot be done without
        })
        .catch(err => {
          setSaving(savingStatus.Error)
          console.error("Error Update list header:", err.message)
        })
      }
      if(listMembersAny(excludeListInput) && editedList.primaryExcludeList){
        totalCalls += 1
        updateStaticListMembers({variables: {
          input: {
            id: editedList.primaryExcludeList.id,
            ...excludeListInput
          }
        }}).then(result => {
          setSavingCallCount((savingCallCount) => savingCallCount - 1)
          // Don't update list as it cannot be done without
        })
        .catch(err => {
          setSaving(savingStatus.Error)
          console.error("Error Update list header:", err.message)
        })
      }
      // Dynamic Filters
      if(JSON.stringify(editedList.dynamicFilters) !== JSON.stringify(data?.list?.dynamicFilters)){
        totalCalls += 1
        const editedDynamicFilters = editedList.dynamicFilters
        let patch = {}
        if(editedList.itemIdTypes?.includes(ListMemberIdType.fund_num)) {
          // Plan
          patch = {
            ...patch,
            plan: {
              fundType: editedDynamicFilters?.plan?.fundType,
              accountType: editedDynamicFilters?.plan?.accountType,
              client: compact(editedDynamicFilters?.plan?.client?.map((item) => item?.id)),
              owner: compact(editedDynamicFilters?.plan?.owner?.map((item) => item?.id)),
            }
          }
        }
        if(editedList.itemIdTypes?.includes(ListMemberIdType.fundid)) {
          // Vehicles
          patch = {
            ...patch,
            vehicle: {
              category: editedDynamicFilters?.vehicle?.category,
              status: editedDynamicFilters?.vehicle?.status,
              product: compact(editedDynamicFilters?.vehicle?.product?.map((item) => item?.product?.id)),
            }
          }
        }
        if(editedList.itemIdTypes?.includes(ListMemberIdType.org_id)) {
          // Organizations
          patch = {
            ...patch,
            org: {
              type: editedDynamicFilters?.org?.type,
              subType: editedDynamicFilters?.org?.subType,
              accountType: editedDynamicFilters?.org?.accountType,
              owner: compact(editedDynamicFilters?.org?.owner?.map((item) => item?.id)),
            }
          }
        }
        if(editedList.itemIdTypes?.includes(ListMemberIdType.portfolio_num)) {
          // Portfolios
          patch = {
            ...patch,
            portfolio: {
              composite: editedDynamicFilters?.portfolio?.composite,
              dataType: editedDynamicFilters?.portfolio?.dataType,
              plan: compact(editedDynamicFilters?.portfolio?.plan?.map((item) => item?.id)),
              assetClass: compact(editedDynamicFilters?.portfolio?.assetClass?.map((item) => item?.id)),
              parentMix: compact(editedDynamicFilters?.portfolio?.parentMix?.map((item) => item?.id)),
            }
          }
        }
        if(editedList.itemIdTypes?.includes(ListMemberIdType.product_id)) {
          // Products
          patch = {
            ...patch,
            product: {
              category: editedDynamicFilters?.product?.category,
              manager: compact(editedDynamicFilters?.product?.manager?.map((item) => item?.id)),
              assetClass: compact(editedDynamicFilters?.product?.assetClass?.map((item) => item?.id)),
            },
            closedEndedProductListFilters: {
              lastFund: editedDynamicFilters?.closedEndedProductListFilters?.lastFund,
              vintageYearFirstCashFlow: editedDynamicFilters?.closedEndedProductListFilters?.vintageYearFirstCashFlow,
            }
          }
        }

        let input = {
          id: editedList.id,
          patch: patch
        } as UpdateDynamicListInput
        updateDynamicListFilters({variables: {input: input}})
          .then(result => {
            setSavingCallCount((savingCallCount) => savingCallCount - 1)
          })
          .catch(err => {
            setSaving(savingStatus.Error)
            console.error("Error updating dynamic filters:", err.message)
          })
      }
      setSavingCallCount((savingCallCount) => savingCallCount < 0 ? savingCallCount + totalCalls : totalCalls)
    } else if(saving === savingStatus.UpdatingItems){
      if(savingCallCount === 0){
        setSaving(savingStatus.StartOrder)
      }
    } else if(saving === savingStatus.StartOrder){
      // setSaving(savingStatus.UpdatingOrder)
      // const updatedOrder = compact(editedList.items?.map((item):ListOrder | undefined => {
      //   if(item.group && (item.order || item.order === 0) && item.type){
      //     return {
      //       memberId: get(item, `item.${typeIdMapping[item.type]}`, "")?.toString(),
      //       idType: item.type,
      //       order: item.order,
      //       group: item.group,
      //     }
      //   }
      //   return undefined
      // }))
      // setListOrder({
      //   variables:{
      //     input: {
      //       id: editedList.id,
      //       order: updatedOrder
      //     }
      //   }
      // }).then(result => {
      //   setSaving(savingStatus.Initial)
      //   if (result && result.data) {
      //     setListHeaders([])
      //     setEditMode(false)
      //     setEditedList(result.data.setListOrder?.list || undefined)
      //     setExcludeListInput(baseUpdateList)
      //     setMainListInput(baseUpdateList)
      //   }
      // })
      // .catch(err => {
      //   setSaving(savingStatus.Error)
      //   console.error("Error Update list order:", err.message)
      // })
    }
  }

  const handleSetGridApi = (api:GridApi | undefined) => {
    setGridApi(api)
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      <Row className='ml-0'>
        <Col xs="10" sm="8" md="6">
          <Breadcrumb>
            <BreadcrumbItem
              className="headline-breadcrumbs"
              key="test"
              onClick={() =>
                history.push(
                  `/lists`
                )
              }
            >
              Lists
            </BreadcrumbItem>
          </Breadcrumb>
          <h2 className="headline">
            {data?.list?.name || "List"}
            {editedList?.isCurrentUsersFavorite ?
              <FontAwesomeIcon
                icon="star"
                className="align-baseline text-accent-yellow cursor-pointer"
                onClick={() => toggleFavorite(false)}
              />
            :
              <FontAwesomeIcon
                icon={["far", "star"]}
                className="align-baseline cursor-pointer"
                onClick={() => toggleFavorite(true)}
              />
            }
          </h2>
        </Col>
      </Row>
      <div className="pane pane-toolbar sticky-top above-picker">
        <div>
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={openSettingsModal} disabled={!data}>
            Settings
          </Button>
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        {editedList &&
          <ListSettingsModal
            modalOpen={settingsModalOpen}
            setModalOpen={setSettingsModalOpen}
            categoryMap={data?.listCategoryMap || []}
            list={editedList}
            setEditedList={setEditedList}
          />
        }
        {/* <div className="border-right">
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={openFileModal} disabled={!data}>
            Share
          </Button>
        </div>
        <div>
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={openFileModal} disabled={!data}>
            Duplicate List
          </Button>
        </div> */}
        {canEdit &&
          <EditButtons
            editMode={editMode}
            setEditMode={handleEdit}
            saving={![savingStatus.Initial, savingStatus.Error].includes(saving)}
            onSubmit={handleSubmit}
          />
        }
        <CsvExportLimitModal
          modalOpen={csvExportModalOpen}
          setModalOpen={setCsvExportModalOpen}
        />
      </div>
    </>
  )

  if (!!data && !!editedList?.items) {
    const expandedList = expandList(editedList)
    registerRecentList(data.list.id, data.list.name, data.list.type)
    return (
      <Container fluid className='d-flex flex-direction-column h-100'>
        {heading}
        <div className="d-flex flex-grow">
          {editMode &&
            <ListDetailAddItem
              list={editedList}
              setEditedList={setEditedList}
              editMode={editMode}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              mainListInput={mainListInput}
              setMainListInput={setMainListInput}
              auth={auth}
            />
          }
          <ListDetailTable
            list={editedList}
            setEditedList={setEditedList}
            gridApi={gridApi}
            setGridApi={handleSetGridApi}
            editMode={editMode}
            excludeListInput={excludeListInput}
            setExcludeListInput={setExcludeListInput}
            mainListInput={mainListInput}
            setSelectedItems={setSelectedItems}
            setMainListInput={setMainListInput}
            setDragging={setDragging}
            setListHeaders={setListHeaders}
          />
          <ListDetailSidebar
            list={editedList}
            setEditedList={setEditedList}
            tree={expandedList}
            editMode={editMode}
            listHeaders={listHeaders}
            setListHeaders={setListHeaders}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            dragging={dragging}
          />
        </div>
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

export default WithTopNav(ListDetail)

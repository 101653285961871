import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import iassign from "immutable-assign"
import _ from "lodash"
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { Button, CustomInput, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Auth from "../../../Auth/Auth"
import { EditButtonContext } from "../../../Context/EditButtonContext"
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { CreateListInput, ListType, MeFragment, useCreateListMutation, ListMemberIdType, PortfolioDataType, useGetLookupQuery, useGetAllAssetClassesQuery, AssetClassFragment, useCreateClientPortfolioMutation, CompositeMemberInputItem, useUpdateClientPortfolioDefinitionMutation } from "../../../__generated__/graphql"
import EditButtons from "../../ui/EditButtons"
import { FormInput } from "../../ui/Forms/FormInput"
import { GetLookupDataToOptions } from "../../ui/LookupOptions"

const DEFAULT_START_DATE = moment().format(DATE_API_FORMAT)
const DEFAULT_END_DATE = "9999-12-31"
const defaultCompositeMemberInput = {startDate: DEFAULT_START_DATE, endDate: DEFAULT_END_DATE}

interface AddPIDProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  user?: MeFragment
  auth: Auth
  planId: number
  clientId: number | null | undefined // to set client field for createPID
  compositeByDefault?: boolean
  selectedIds?: number[] // selected composite members
  callBackIfSuccess: () => void
}

export const AddPIDModal: React.FC<AddPIDProps> = ({
  modalOpen,
  setModalOpen,
  user,
  auth,
  planId,
  clientId,
  selectedIds,
  compositeByDefault,
  callBackIfSuccess,
}) => {
  // const [createList] = useCreateListMutation() // create PID mutation
  // const [dynamicType, setDynamicType] = useState("org")

   // create PID mutation
  const [isComposite, setComposite] = useState((!!compositeByDefault) || false) // default single portfolio

  const { loading: typeLoading, error: typeError, data: typeData } = useGetLookupQuery({
    variables: { name: "PortfolioDataType" }
  })
  const {
    loading: assetClassesLoading,
    error: assetClassesError,
    data: assetClasses
  } = useGetAllAssetClassesQuery()

  const { resetErrors } = useContext(EditButtonContext)
  const [createPID] = useCreateClientPortfolioMutation()
  const [addPidsToComposite] = useUpdateClientPortfolioDefinitionMutation()

  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const nameRef = useRef<HTMLInputElement>(null)
  const assetClassRef = useRef<HTMLInputElement>(null)

  const defaultPID ={
    // __typename: "CreatePIDInput",
    name: "",
    client: clientId,
    plan: planId,
    isComposite,
    dataType: PortfolioDataType.ASC, // default
    parentAssetClass: undefined,
    assetClass: undefined,
  }

  const [pid, setPid] = useState<any>(defaultPID)
  const resetModal = () => {
    setComposite((!!compositeByDefault) || false)
    setSaving(false)
    setPid(defaultPID)
  }
  const [assetClassesOptions, setAssetClassesOptions] = useState<React.ReactElement<any, any> | null>(null)
  const [assetClassesOptionsComposite, setAssetClassesOptionsComposite] = useState<React.ReactElement<any, any> | null>(null)
  const [strategyTypeOptions, setStrategyTypeOptions] = useState<React.ReactElement<any, any> | null>(null)
  const [typeOptions, setTypeOptions] =  useState<React.ReactElement<any, any> | null>(null)
  const [successMessage, setSuccessMessage] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  useEffect(() => {
    if(typeData) {
      setTypeOptions(GetLookupDataToOptions({
        data: typeData?.__type?.enumValues || [{code: "", value: ""}]}))
    }
    if(assetClasses) {
      let allAssetClassesData =
        assetClasses?.assetClasses ||
        ([] as ({
          __typename: "AssetClass"
          children: ({
            __typename: "AssetClass"
          } & AssetClassFragment)[]
        } & AssetClassFragment)[])
      // filter out total assets and total fund composite
      let allAssetClassesDataFiltered = allAssetClassesData.filter(
        el => !(el.code === 999 || el.code === 20))
      setAssetClassesOptions(GetLookupDataToOptions({
        data: allAssetClassesDataFiltered,
        hideBlank: false
      }))
      let allAssetClassesCompositeDataFiltered = allAssetClassesData.filter(
        el => !(el.code === 999))
      setAssetClassesOptionsComposite(GetLookupDataToOptions({
        data: allAssetClassesCompositeDataFiltered,
        hideBlank: false
      }))
    }
  }, [assetClasses, typeData])

  useEffect(() => {
    if (pid.parentAssetClass && assetClasses?.assetClasses) {
      let parent = assetClasses.assetClasses.filter(
        (assetClass:any) => assetClass.code.toString() === pid.parentAssetClass.toString()
      )[0]

      const children = parent?.children
      const filteredOptions = parent?.id === 20? children.filter(el => el?.id === 20): children
      setStrategyTypeOptions(GetLookupDataToOptions({
        data: filteredOptions as AssetClassFragment[]
      }))
    }
  }, [pid.parentAssetClass])

  const onSubmit = (addAnother:boolean = false) => {
    let earlyReturn = false
    if(pid.name.length === 0){
      nameRef?.current?.focus()
      earlyReturn = true
    }
    if(!pid.assetClass){
      assetClassRef?.current?.focus()
      earlyReturn = true
    }
    if(earlyReturn){
      // console.log('early', pid)
      return
    }
    setSuccessMessage("")
    setShowSuccessMessage(false)
    setSaving(true)
    let isComposite = pid.isComposite
    let formattedPid =  {...pid, assetClass: parseInt(pid.assetClass)}
    delete formattedPid.parentAssetClass
    let input = formattedPid
    createPID({variables: {input}}).then(result => {
      if(result?.data?.createClientPortfolio) {
        let newId = result.data?.createClientPortfolio?.clientPortfolio?.id
        console.log(`success, create portfolio newId= ${newId}`)
        if(isComposite && newId && selectedIds && selectedIds?.length > 0) {
          let members = selectedIds.map(memberId => ({...defaultCompositeMemberInput, memberId} as CompositeMemberInputItem ))
          console.log('add', newId, members, selectedIds)
          addPidsToComposite({variables: {
            input: {
              id: newId,
              patch: { compositeMember: {add: members}
              }
            }
          }}).then(result => {
            setSaving(false)
            if(result?.data) {
              closeModal()
              console.log(`success addToComposite newId= ${result?.data.updateClientPortfolio?.clientPortfolio?.id}`)
              // history.push(`/clientportfolios/${newId}?editMode=true`)
              callBackIfSuccess()
              if(addAnother) {
                setSuccessMessage(`success addToComposite newId= ${result?.data.updateClientPortfolio?.clientPortfolio?.id}`)
                setShowSuccessMessage(true)
                setModalOpen(true)
              }
            }
          }).catch(err => {
            setSaving(false)
            console.log('error addPidsToComposite', err.message)
          })
        }else {
          setSaving(false)
          closeModal()
          callBackIfSuccess()
          if(addAnother) {
            // needs test.
            setSuccessMessage(`Portfolio ID ${newId} was successfully created.`)
            setShowSuccessMessage(true)
            setModalOpen(true)
          }
        }
        // history.push(`/clientportfolios/${newId}?editMode=true`)
      }else {
        closeModal()
        setSaving(true)
      }
    }).catch(err => {
      setSaving(false)
      console.log("error createPid",err.message)
    })

  }

  const closeModal = (refresh:boolean = false) => {
    resetModal()
    setModalOpen(false)
    if(refresh && callBackIfSuccess) {
      callBackIfSuccess()
    }
  }

  const handleChange = (property:string, value:any) => {
    setPid((pid:any) => {
      const newState = iassign(
        pid,
        [property],
        obj => {
          return value
        }
      )
      return newState
    })
  }

  const transitionType = (value: boolean) => {
    resetErrors()
    handleChange("dataType", undefined)
    handleChange("isComposite", value)
  }

  return (
    <>
      <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header">
          <div className="d-flex justify-content-between">
            <div>
              {!!compositeByDefault && `New Composite`}
              {!compositeByDefault && `New Portfolio`}
            </div>
            <div onClick={() => closeModal()}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
        {!compositeByDefault &&
        <>
          {showSuccessMessage &&<h5 className="modal-title mb-2 pb-1">{successMessage}</h5>}
          <h5 className="modal-title">Choose type</h5>
          <CustomInput
            id={"1-single"}
            className="boolean-radio mt-2 full-width"
            bsSize="sm"
            type="radio"
            value="true"
            checked={pid.isComposite === false}
            onChange={(value) => value.target.value === "true" && transitionType(false)}
            label="Portfolio"
          />
          <Label className="pl-4 text-gray-50" for="4-static">A single client portfolio.</Label>
        </>}
        {!compositeByDefault && pid.isComposite === false &&
         <div className="px-4">
          <h5 className="mt-1">Portfolio name</h5>
          <FormInput
            property={"single.name"}
            displayName={""}
            type={"text"}
            placeholder={"Portfolio Name"}
            idx={"1-single-name"}
            editMode={true}
            propertyVal={pid.name}
            updateValue={(value) => handleChange('name', value)}
            required={true}
            inputRef={nameRef}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
            charactersLimit={40}
          />
          <h5 className="mt-2">Portfolio type</h5>
          <FormInput
            property={"4-dataType"}
            displayName={""}
            type={"select"}
            subtype={"single"}
            idx={"4-dataType"}
            editMode={true}
            // options={strategyTypeOptions}
            optionSource={'PortfolioDataType'}
            optionFilterRule={{'APR': 1, 'AUD': 2, 'BNR': 3, 'IRP': 4, 'MIS': 5, 'RTO': 5, 'SUM': 6}}
            propertyVal={pid.dataType}
            updateValue={(value) => handleChange('dataType', value)}
            required={true}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
          />
          <h5 className="mt-2">Asset class</h5>
          <FormInput
            property={"1-assetClass"}
            displayName={""}
            type={"select"}
            subtype={"single"}
            placeholder={"Asset class"}
            idx={"1-single-assetClass"}
            editMode={true}
            options={assetClassesOptions}
            propertyVal={pid.parentAssetClass}
            updateValue={(value) => handleChange('parentAssetClass', value)}
            required={true}
            inputRef={assetClassRef}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
          />
          <h5 className="mt-2">Strategy type</h5>
          <FormInput
            property={"3-strategyType"}
            displayName={""}
            type={"select"}
            subtype={"single"}
            placeholder={"List Name"}
            idx={"3-strategyType"}
            editMode={true}
            options={strategyTypeOptions}
            propertyVal={pid.assetClass}
            updateValue={(value) => handleChange('assetClass', value)}
            required={true}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
          />
        </div>
        }
        <CustomInput
          id={"2-composite"}
          className="boolean-radio mt-2 full-width"
          bsSize="sm"
          type="radio"
          value="true"
          checked={pid.isComposite === true}
          onChange={(value) => value.target.value === "true" && transitionType(true)}
          label="Composite"
        />
        <Label className="pl-4 text-gray-50" for="5-composite">{"Combine existing portfolios into a composite."}</Label>
        {pid.isComposite === true &&
        <div className="px-4">
          <h5 className="mt-1">Composite name</h5>
          <FormInput
            property={"composite.name"}
            displayName={""}
            type={"text"}
            placeholder={"Composite Name"}
            idx={"2-composite-name"}
            editMode={true}
            propertyVal={pid.name}
            updateValue={(value) => handleChange('name', value)}
            required={true}
            inputRef={nameRef}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
            charactersLimit={40}
          />
          <h5 className="mt-2">Composite type</h5>
          <FormInput
            property={"2-composite-dataType"}
            displayName={""}
            type={"select"}
            placeholder={"Composite dataType"}
            idx={"2-composite-dataType"}
            editMode={true}
            optionSource={'PortfolioDataType'}
            optionFilterRule={{'ASC': 1, 'COM': 2, 'STY': 3, 'TOT': 4}}
            propertyVal={pid.dataType}
            updateValue={(value) => handleChange('dataType', value)}
            required={true}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
          />
          <h5 className="mt-2">Asset class</h5>
          <FormInput
            property={"2-composite-assetClass"}
            displayName={""}
            type={"select"}
            subtype={"single"}
            placeholder={"List Name"}
            idx={"2-composite-assetClass"}
            editMode={true}
            options={assetClassesOptionsComposite}
            propertyVal={pid.parentAssetClass}
            updateValue={(value) => handleChange('parentAssetClass', value)}
            required={true}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
          />
          <h5 className="mt-2">Strategy type</h5>
          <FormInput
            property={"3-composite-strategyType"}
            displayName={""}
            type={"select"}
            subtype={"single"}
            placeholder={"List Name"}
            idx={"3-composite-strategyType"}
            editMode={true}
            options={strategyTypeOptions}
            propertyVal={pid.assetClass}
            updateValue={(value) => handleChange('assetClass', value)}
            required={true}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-12 ml-2 pl-1"}}
          />
        </div>
        }
        </ModalBody>
        <ModalFooter>
          {!saving && <EditButtons key={0} editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={saving} onSubmit={() => onSubmit(true)} disableOnError={true} saveText={`Save & Add Another`}
          hideCancelButton={true}/>}
          <EditButtons key={1} editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={saving} onSubmit={onSubmit} disableOnError={true}/>
        </ModalFooter>
      </Modal>
    </>
  )
}
import { OtherFeeVehicleType } from "./OtherFees"

export type OtherFeeInputType =
  | "datedFee"
  | "entryFee"
  | "redemptionFee"
  | "booleanFee"
  | "hfHurdle"
  | "altFee"
  | "peHurdle"
  | "waterfall"

export interface OtherFeeFeeInput {
  label: string
  path: string
  type: OtherFeeInputType
}

export interface OtherFeeInputs {
  vehicleType: OtherFeeVehicleType
  fees: OtherFeeFeeInput[]
}

export const OtherFeeInputs: OtherFeeInputs[] = [
  {
    vehicleType: "OpenEndedMutualFund",
    fees: [
      {
        label: "Management Fee",
        path: "mutualFund.datedFees.managementFee",
        type: "datedFee",
      },
      {
        label: "12b-1",
        path: "mutualFund.datedFees.fee12b1",
        type: "datedFee",
      },
      {
        label: "Administrative, Operating Expenses",
        path: "mutualFund.datedFees.administrativeOperatingExpenses",
        type: "datedFee",
      },
      {
        label: "Sub-Advisor",
        path: "mutualFund.datedFees.subAdvisor",
        type: "datedFee",
      },
      {
        label: "Total Expense",
        path: "mutualFund.datedFees.totalExpense",
        type: "datedFee",
      },
      {
        label: "Entry Fees",
        path: "mutualFund",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "mutualFund",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedExchangeTradedFund",
    fees: [
      {
        label: "Total Expense",
        path: "exchangeTradedFund.datedFees.totalExpense",
        type: "datedFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedPooledVehicle",
    fees: [
      {
        label: "Entry Fees",
        path: "pooledVehicle",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "pooledVehicle",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedVariableAnnuity",
    fees: [
      {
        label: "Total Expense",
        type: "datedFee",
        path: "variableAnnuity.datedFees.totalExpense",
      },
      {
        label: "Entry Fees",
        path: "variableAnnuity",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "variableAnnuity",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedCollectiveInvestmentFund",
    fees: [
      {
        label: "Management Fee",
        path: "collectiveInvestmentFund.datedFees.managementFee",
        type: "datedFee",
      },
      {
        label: "Total Expense",
        path: "collectiveInvestmentFund.datedFees.totalExpense",
        type: "datedFee",
      },
      {
        label: "Entry Fees",
        path: "collectiveInvestmentFund",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "collectiveInvestmentFund",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedCollectiveInvestmentFundComposite",
    fees: [
      {
        label: "Entry Fees",
        path: "collectiveInvestmentFundComposite",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "collectiveInvestmentFundComposite",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "ClosedEndedPrivateNonRegisteredPrivateCredit",
    fees: [
      {
        label: "Carried Interest",
        path: "privateCreditVehicle.carry",
        type: "altFee",
      },
      {
        label: "Management Fee Offset",
        path: "privateCreditVehicle.offset",
        type: "altFee",
      },
      {
        label: "Waterfall",
        path: "privateCreditVehicle",
        type: "waterfall",
      },
      {
        label: "Clawback Provisions",
        path: "closedEndedVehicle.clawBackProvisions",
        type: "booleanFee",
      },
      {
        label: "Preferred Return",
        path: "privateCreditVehicle.hurdle",
        type: "peHurdle",
      },
      {
        label: "GP Catch Up",
        path: "closedEndedVehicle.catchupFee",
        type: "datedFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedPooledVehiclePrivateEquity",
    fees: [
      {
        label: "Carried Interest",
        path: "privateEquityVehicle.carry",
        type: "altFee",
      },
      {
        label: "Management Fee Offset",
        path: "privateEquityVehicle.offset",
        type: "altFee",
      },
      {
        label: "Waterfall",
        path: "privateEquityVehicle",
        type: "waterfall",
      },
      // {
      //   label: "Clawback Provisions",
      //   path: "privateEquityVehicle.clawBackProvisions",
      //   type: "booleanFee",
      // },
      {
        label: "Entry Fees",
        path: "pooledVehicle",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "pooledVehicle",
        type: "redemptionFee",
      },
      {
        label: "Preferred Return",
        path: "privateEquityVehicle.hurdle",
        type: "peHurdle",
      },
      // {
      //   label: "Catch-up Fee",
      //   path: "privateEquityVehicle.catchupFee",
      //   type: "datedFee",
      // },
    ],
  },
  {
    vehicleType: "ClosedEndedPrivateNonRegisteredPrivateEquity",
    fees: [
      {
        label: "Carried Interest",
        path: "privateEquityVehicle.carry",
        type: "altFee",
      },
      {
        label: "Management Fee Offset",
        path: "privateEquityVehicle.offset",
        type: "altFee",
      },
      {
        label: "Waterfall",
        path: "privateEquityVehicle",
        type: "waterfall",
      },
      {
        label: "Clawback Provisions",
        path: "closedEndedVehicle.clawBackProvisions",
        type: "booleanFee",
      },
      {
        label: "Preferred Return",
        path: "privateEquityVehicle.hurdle",
        type: "peHurdle",
      },
      {
        label: "GP Catch Up",
        path: "closedEndedVehicle.catchupFee",
        type: "datedFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedPooledVehicleRealAssets",
    fees: [
      {
        label: "Preferred Return",
        path: "realAssetsVehicle.hurdle",
        type: "peHurdle",
      },
      {
        label: "Carried Interest",
        path: "realAssetsVehicle.carry",
        type: "altFee",
      },
      {
        label: "Acquisition Fee",
        path: "realAssetsVehicle.acquisitionFee",
        type: "altFee",
      },
      {
        label: "Disposition Fee",
        path: "realAssetsVehicle.dispositionFee",
        type: "altFee",
      },
      {
        label: "Entry Fees",
        path: "pooledVehicle",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "pooledVehicle",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedCollectiveInvestmentFundCompositeRealAssets",
    fees: [
      {
        label: "Management Fee",
        path: "collectiveInvestmentFund.datedFees.managementFee",
        type: "datedFee",
      },
      {
        label: "Total Expense",
        path: "collectiveInvestmentFund.datedFees.totalExpense",
        type: "datedFee",
      },
      {
        label: "Preferred Return",
        path: "realAssetsVehicle.hurdle",
        type: "peHurdle",
      },
      {
        label: "Carried Interest",
        path: "realAssetsVehicle.carry",
        type: "altFee",
      },
      {
        label: "Acquisition Fee",
        path: "realAssetsVehicle.acquisitionFee",
        type: "altFee",
      },
      {
        label: "Disposition Fee",
        path: "realAssetsVehicle.dispositionFee",
        type: "altFee",
      }
    ],
  },
  {
    vehicleType: "ClosedEndedPrivateNonRegisteredRealAssets",
    fees: [
      {
        label: "Waterfall",
        path: "realAssetsVehicle",
        type: "waterfall",
      },
      {
        label: "Clawback Provisions",
        path: "closedEndedVehicle.clawBackProvisions",
        type: "booleanFee",
      },
      {
        label: "Preferred Return",
        path: "realAssetsVehicle.hurdle",
        type: "peHurdle",
      },
      {
        label: "GP Catch Up",
        path: "closedEndedVehicle.catchupFee",
        type: "datedFee",
      },
      {
        label: "Carried Interest",
        path: "realAssetsVehicle.carry",
        type: "altFee",
      },
      {
        label: "Acquisition Fee",
        path: "realAssetsVehicle.acquisitionFee",
        type: "altFee",
      },
      {
        label: "Disposition Fee",
        path: "realAssetsVehicle.dispositionFee",
        type: "altFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedCollectiveInvestmentFundRealAssets",
    fees: [
      {
        label: "Hurdle",
        path: "realAssetsVehicle.hurdle",
        type: "peHurdle",
      },
      {
        label: "Performance Fee",
        path: "realAssetsVehicle.performanceFee",
        type: "altFee",
      },
      {
        label: "Acquisition Fee",
        path: "realAssetsVehicle.acquisitionFee",
        type: "altFee",
      },
      {
        label: "Disposition Fee",
        path: "realAssetsVehicle.dispositionFee",
        type: "altFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedSeparateAccountRealAssets",
    fees: [
      {
        label: "Performance Fee",
        path: "realAssetsVehicle.performanceFee",
        type: "altFee",
      },
      {
        label: "Acquisition Fee",
        path: "realAssetsVehicle.acquisitionFee",
        type: "altFee",
      },
      {
        label: "Disposition Fee",
        path: "realAssetsVehicle.dispositionFee",
        type: "altFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedPrivateNonRegisteredHedgeFund",
    fees: [
      {
        label: "High-water Mark",
        path: "hedgeFundVehicle.highWaterMark",
        type: "booleanFee",
      },
      {
        label: "Custody Included in Fee",
        path: "hedgeFundVehicle.custodyIncludedInFee",
        type: "booleanFee",
      },
      {
        label: "GP Catch Up",
        path: "hedgeFundVehicle.catchupFee",
        type: "datedFee",
      },
      {
        label: "Most Favored Nation",
        path: "hedgeFundVehicle.mostFavoredNation",
        type: "booleanFee",
      },
      {
        label: "Preferred Return",
        path: "hedgeFundVehicle",
        type: "hfHurdle",
      },
      {
        label: "Rebates",
        path: "hedgeFundVehicle.rebates",
        type: "booleanFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "hedgeFundVehicle",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedCollectiveInvestmentFundStableValueComposite",
    fees: [
      {
        label: "Entry Fees",
        path: "collectiveInvestmentFundComposite",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "collectiveInvestmentFundComposite",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedCollectiveInvestmentFundStableValue",
    fees: [
      {
        label: "Management Fee",
        path: "collectiveInvestmentFund.datedFees.managementFee",
        type: "datedFee",
      },
      {
        label: "Entry Fees",
        path: "collectiveInvestmentFund",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "collectiveInvestmentFund",
        type: "redemptionFee",
      },
    ],
  },
  {
    vehicleType: "OpenEndedPooledVehiclePrivateCredit",
    fees: [
      {
        label: "Carried Interest",
        path: "privateCreditVehicle.carry",
        type: "altFee",
      },
      {
        label: "Offset",
        path: "privateCreditVehicle.offset",
        type: "altFee",
      },
      {
        label: "Waterfall",
        path: "privateCreditVehicle",
        type: "waterfall",
      },
      // {
      //   label: "Clawback Provisions",
      //   path: "privateCreditVehicle.clawBackProvisions",
      //   type: "booleanFee",
      // },
      {
        label: "Preferred Return",
        path: "privateCreditVehicle.hurdle",
        type: "peHurdle",
      },
      // {
      //   label: "Catch-up Fee",
      //   path: "privateCreditVehicle.catchupFee",
      //   type: "datedFee",
      // },
      {
        label: "Entry Fees",
        path: "pooledVehicle",
        type: "entryFee",
      },
      {
        label: "Redemption Fees Apply",
        path: "pooledVehicle",
        type: "redemptionFee",
      },
    ],
  },
]

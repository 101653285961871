import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Table } from 'reactstrap'

import { FormInputSubClasses } from '../../helpers/constant'
import { getNewStateObject } from '../../helpers/helpers'
import { FormInput } from '../ui/Forms/FormInput'

const CHARACTER_LIMIT = 200;

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
  charactersLimit?: number
}

interface TermsTableItemProps {
  data: any
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
}

type TermsTableProps = {
  data: any
  productType: { [type: string]: boolean }
  handleChange: (state: any, property: string) => void
  editMode: boolean
}

export const TermsTableRowDef: RowDefItem[] = [
  {
    field: "closedEnded.irrTarget.gross",
    type: "float",
    title: "Target IRR - Gross",
    subClasses: {
      wrapperClasses: "no-export-form"
    }
  },
  {
    field: "closedEnded.irrTarget.net",
    type: "float",
    title: "Target IRR - Net",
    subClasses: {
      wrapperClasses: "no-export-form"
    }
  },
  {
    field: "closedEnded.targetTotalValuePaidIn.gross",
    type: "float",
    title: "Target TVPI - Gross",
    subtype: "multiplier",
    subClasses: {
      wrapperClasses: "no-export-form"
    }
  },
  {
    field: "closedEnded.targetTotalValuePaidIn.net",
    type: "float",
    title: "Target TVPI - Net",
    subtype: "multiplier",
    subClasses: {
      wrapperClasses: "no-export-form"
    }
  },
  {
    field: "realAssets.realReturnTarget",
    type: "float",
    title: "Real Return Target",
    charactersLimit: CHARACTER_LIMIT,
    subClasses: {
      wrapperClasses: "no-export-form"
    }
  },
  {
    field: "product.incomeReturnTarget",
    type: "float",
    title: "Income Return Target",
    subClasses: {
      wrapperClasses: "no-export-form"
    }
  }
]

const TermsColDef: RowDefItem[] = [
  {
    field: "low",
    type: "float",
    subtype: "percent",
    title: "Low",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1", wrapperClasses: "no-export-form"
    }
  },
  {
    field: "high",
    type: "float",
    subtype: "percent",
    title: "High",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1", wrapperClasses: "no-export-form"
    }
  },
  {
    field: "target",
    type: "float",
    subtype: "percent",
    title: "Target",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1", wrapperClasses: "no-export-form"
    }
  },
  {
    field: "explanation",
    type: "textarea",
    title: "Explanation",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1", wrapperClasses: "no-export-form"
    }
  }
]

interface TermsTableRowProps {
  data: any
  index: number
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const TermsTableRow = ({
  data,
  index,
  rowDef,
  editMode,
  updateValue
}: TermsTableRowProps) => {
  let field = rowDef.field
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  return (
    <tr key={`${index}`}>
      <td key={`Terms-${field}`} className={"text-left"}>
        {rowDef.title}
      </td>
      {TermsColDef.map((col, colIndex) => {
        return (
          <TermsTableItem
            data={value[col.field]}
            rowDefItem={rowDef}
            key={colIndex}
            idx={colIndex}
            editMode={editMode}
            updateValue={updateValue}
          />
        )
      })}
    </tr>
  )
}

const TermsTableItem = ({
  data,
  rowDefItem,
  idx,
  editMode,
  updateValue
}: TermsTableItemProps) => {
  let { field, subClasses } = rowDefItem
  let { type, subtype } = TermsColDef[idx]
  let className = type === "textarea" ? "wide": ""
  return (
    <td key={`${field}-${idx}`} className={className}>
      <FormInput
        property={field + "." + TermsColDef[idx].field}
        displayName={""}
        type={type}
        subtype={rowDefItem.subtype ? rowDefItem.subtype : subtype}
        idx={idx}
        editMode={editMode}
        propertyVal={data}
        placeholder={type === "number" ? "0" : ""}
        subClasses={subClasses}
        updateValue={value => updateValue(idx, value, type, field)}
        charactersLimit={CHARACTER_LIMIT}
      />
    </td>
  )
}

const TermsTableDisplay = (seed: TermsTableProps) => {
  const tableName = "Terms"
  const widthClassName = "col-sm-12"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)
  useEffect(() => {
    setData(data)
  }, [seed])

  const onHandleChangeTermsRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = getNewStateObject({
      state: tableState,
      newValue: value,
      property: `${property}.${TermsColDef[idx].field}`,
      type
    })
    handleChange(_.get(newData, property), property)
    setData(newData)
  }

  return (
    <div className={`${tableName}-table ${widthClassName} row form-group pl-0`}>
      <Table
        hover
        responsive
        size="sm"
        key={`Terms-table`}
        className="exportable"
        data-export-name="TermsTable"
      >
        <thead>
          <tr className="table-header">
            <th key={0}></th>
            {TermsColDef.map((header, headerIdx) => (
              <th key={headerIdx + 1}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody key={0}>
          {TermsTableRowDef.map((rowDef, rowIndex) => {
            if (_.isUndefined(_.get(tableState, rowDef.field))) {
              return <Fragment key={rowIndex}></Fragment>
            }
            return (
              <TermsTableRow
                index={rowIndex}
                key={rowIndex}
                data={tableState}
                rowDef={rowDef}
                editMode={editMode}
                updateValue={onHandleChangeTermsRow}
              />
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default TermsTableDisplay

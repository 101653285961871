import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import _ from 'lodash'
import numbro from 'numbro'
import { Component } from 'react'
import {
  Col, Form, FormFeedback, FormGroup, Input, Label, Table
} from 'reactstrap'
import { ExtraRow } from '../../../helpers/exportTable'
import {
  HedgeFundFeeBalance,
  HedgeFundFeeSchedule
} from '../../../__generated__/graphql'
import { CurrencyInput, PercentInput } from './FeeFormComponents'
import { formatCurrency } from './helper'
import {
  FeeBalanceComponent, FeeScheduleComponent, FeeScheduleData, FeeScheduleTiers
} from './ManagementFees'

const PERFORMANCE_FEE_TOLERANCE_MESSAGE =
  "Performance fees aren't typically higher than 50%. Did you enter the fee correctly?"
const MANAGEMENT_FEE_TOLERANCE_MESSAGE =
  "Management fees aren't typically higher than 20%. Did you enter the fee correctly?"

interface FeeScheduleProps {
  editing: boolean
  onChangeCallback: (feeSchedule: FeeScheduleData) => void
  feeScheduleTiers: FeeScheduleTiers
  performanceFee: boolean
}

interface FeeScheduleState {
  feeScheduleTiers: FeeScheduleTiers
  smallestAccountSizeInvalid: boolean
  smallestManagementFeeInvalid: boolean
  smallestPerformanceFeeInvalid: boolean
  balanceManagementFeeInvalid: boolean
  balancePerformanceFeeInvalid: boolean
  tierAccountSizeInvalid: boolean[]
  tierManagementFeeInvalid: boolean[]
  tierPerformanceFeeInvalid: boolean[]
  errorMessage: string
}

export default class FeeSchedule extends Component<
  FeeScheduleProps,
  FeeScheduleState
> {
  state = {
    feeScheduleTiers: this.props.feeScheduleTiers,
    smallestAccountSizeInvalid: false,
    smallestManagementFeeInvalid: false,
    smallestPerformanceFeeInvalid: false,
    balanceManagementFeeInvalid: false,
    balancePerformanceFeeInvalid: false,
    tierAccountSizeInvalid: new Array(
      this.props.feeScheduleTiers.tiers.length
    ).fill(false),
    tierManagementFeeInvalid: new Array(
      this.props.feeScheduleTiers.tiers.length
    ).fill(false),
    tierPerformanceFeeInvalid: new Array(
      this.props.feeScheduleTiers.tiers.length
    ).fill(false),
    errorMessage: '',
  }

  static getDerivedStateFromProps(
    nextProps: FeeScheduleProps,
    prevState: FeeScheduleState
  ) {
    return {
      ...prevState,
      feeScheduleTiers: nextProps.feeScheduleTiers,
    }
  }

  componentDidUpdate() {
    const { onChangeCallback, performanceFee } = this.props
    const {
      feeScheduleTiers: { smallest, tiers, balance },
    } = this.state
    const feeSchedule: FeeScheduleComponent[] = []

    const feeBalance: FeeBalanceComponent = {
      managementFee: +balance.managementFee,
    }
    if (performanceFee) {
      const balancePerformanceFee = (
        balance as Omit<HedgeFundFeeBalance, '__typename'>
      ).performanceFee
      if (
        balancePerformanceFee !== null &&
        balancePerformanceFee !== undefined
      ) {
        ;(
          feeBalance as Pick<
            HedgeFundFeeBalance,
            'managementFee' | 'performanceFee'
          >
        ).performanceFee = +balancePerformanceFee
      } else {
        ;(
          feeBalance as Pick<
            HedgeFundFeeBalance,
            'managementFee' | 'performanceFee'
          >
        ).performanceFee = balancePerformanceFee
      }
      const performanceFeeValue = (smallest as HedgeFundFeeSchedule)
        .performanceFee
      feeSchedule.push({
        accountSize: +smallest.accountSize,
        managementFee: +smallest.managementFee,
        performanceFee: performanceFeeValue
          ? +performanceFeeValue
          : performanceFeeValue,
      })
      tiers.forEach((tier, idx) => {
        const performanceFeeValue = (tier as HedgeFundFeeSchedule)
          .performanceFee
        feeSchedule.push({
          accountSize: +tier.accountSize,
          managementFee: +tier.managementFee,
          performanceFee: performanceFeeValue
            ? +performanceFeeValue
            : performanceFeeValue,
        })
      })
    } else {
      feeSchedule.push({
        accountSize: +smallest.accountSize,
        managementFee: +smallest.managementFee,
      })
      tiers.forEach((tier, idx) => {
        let accountSize
        if (idx === 0) {
          accountSize = tier.accountSize - smallest.accountSize
        } else {
          accountSize = tier.accountSize - tiers[idx - 1].accountSize
        }
        feeSchedule.push({
          accountSize: +accountSize,
          managementFee: +tier.managementFee,
        })
      })
    }
    onChangeCallback({
      feeSchedule,
      feeBalance,
    })
  }

  validateAccountSize() {
    const {
      feeScheduleTiers: { smallest, tiers },
    } = this.state
    let isValid = true
    //check if the "less than" value is less than the acct size of the first tier
    if (tiers.length > 0 && +tiers[0].accountSize <= +smallest.accountSize) {
      isValid = false
    }
    tiers.forEach((tier, idx) => {
      //fir the first "between" row (tier)
      if (idx === 0) {
        //check if it's less than the "less than" row's account size
        if (+tier.accountSize <= +smallest.accountSize) {
          isValid = false
        }
        //if there is more than one between row
        if (tiers.length !== 1) {
          //check that the account size is lower than the next "between" row
          if (+tiers[1].accountSize <= +tier.accountSize) {
            isValid = false
          }
        }
        //for all other "between" rows
      } else {
        //make sure the account size is greater than the previous rows account size
        if (+tier.accountSize <= +tiers[idx - 1].accountSize) {
          isValid = false
        }
        //if this isn't the last between row
        if (idx !== tiers.length - 1) {
          //make sure the account size is lower than the next "between" row
          if (+tiers[idx + 1].accountSize <= +tier.accountSize) {
            isValid = false
          }
        }
      }
    })
    return isValid
  }

  validateManagementFeeOrder() {
    const {
      feeScheduleTiers: { smallest, tiers, balance },
    } = this.state
    let isValid = true
    if (tiers.length > 0 && +tiers[0].managementFee >= +smallest.managementFee)
      isValid = false
    else if (+balance.managementFee >= +smallest.managementFee) isValid = false
    tiers.forEach((tier, idx) => {
      if (idx === 0) {
        if (+tier.managementFee >= +smallest.managementFee) isValid = false
        if (tiers.length !== 1) {
          if (+tiers[1].managementFee >= +tier.managementFee) isValid = false
        } else {
          if (+balance.managementFee >= +tier.managementFee) isValid = false
        }
      } else {
        if (+tier.managementFee >= +tiers[idx - 1].managementFee)
          isValid = false
        if (idx !== tiers.length - 1) {
          if (+tiers[idx + 1].managementFee >= +tier.managementFee)
            isValid = false
        } else {
          if (+balance.managementFee > +tier.managementFee) isValid = false
        }
      }
    })
    return isValid
  }

  validateManagementFeeAmount() {
    const {
      feeScheduleTiers: { smallest, tiers, balance },
    } = this.state
    if (smallest.managementFee >= 0.2) return false
    for (let i = 0; i < tiers.length; i++) {
      const tier = tiers[i]
      if (tier.managementFee >= 0.2) return false
    }
    if (balance.managementFee >= 0.2) return false
    return true
  }

  validatePerformanceFeeAmount() {
    const {
      feeScheduleTiers: { smallest, tiers, balance },
    } = this.state
    if ((smallest as HedgeFundFeeSchedule).performanceFee) {
      if (((smallest as HedgeFundFeeSchedule).performanceFee as number) >= 0.5)
        return false
    }
    for (let i = 0; i < tiers.length; i++) {
      const tier = tiers[i]
      if ((tier as HedgeFundFeeSchedule).performanceFee) {
        if (((tier as HedgeFundFeeSchedule).performanceFee as number) >= 0.5) {
          return false
        }
      }
    }
    if ((balance as HedgeFundFeeSchedule).performanceFee) {
      if (((balance as HedgeFundFeeSchedule).performanceFee as number) >= 0.5)
        return false
    }
    return true
  }

  addTier = () => {
    const state = { ...this.state }
    const { performanceFee } = this.props
    const {
      feeScheduleTiers: { smallest, tiers },
    } = state
    const newRow: Omit<HedgeFundFeeSchedule, '__typename'> = {
      accountSize: smallest.accountSize + 1,
      managementFee: 0,
    }
    if (tiers.length > 0) {
      newRow.accountSize = tiers[tiers.length - 1].accountSize + 1
    }
    if (performanceFee) {
      newRow.performanceFee = 0
    }
    state.feeScheduleTiers.tiers.push(newRow)
    this.setState(state)
  }

  removeTier = () => {
    const state = { ...this.state }
    state.feeScheduleTiers.tiers.pop()
    this.setState(state)
  }

  render() {
    const { editing, performanceFee } = this.props
    const {
      feeScheduleTiers: { smallest, balance, tiers },
      smallestAccountSizeInvalid,
      smallestManagementFeeInvalid,
      smallestPerformanceFeeInvalid,
      balanceManagementFeeInvalid,
      balancePerformanceFeeInvalid,
      tierAccountSizeInvalid,
      tierManagementFeeInvalid,
      tierPerformanceFeeInvalid,
      errorMessage,
    } = this.state
    let extraRows: ExtraRow[] = []
    if (performanceFee) {
      extraRows.push({
        values: ['Account Balance', '', '', 'Management Fee', 'Performance Fee'],
      })
    } else {
      extraRows.push({ values: ['Account Balance', '', '', 'Management Fee'] })
    }
    if (smallest) {
      extraRows.push({
        values: [
          'Less than',
          numbro(smallest?.accountSize).format('$0,0.00'),
          '',
          numbro(smallest?.managementFee).format('0.00%'),
          performanceFee
            ? numbro((smallest as HedgeFundFeeSchedule)?.performanceFee).format(
                '0.00%'
              )
            : '',
        ],
      })
    }
    tiers.forEach((tier, idx) => {
      const lessValue =
        idx === 0
          ? smallest?.accountSize &&
            formatCurrency(smallest?.accountSize, false)
          : formatCurrency(tiers[idx - 1]?.accountSize, false)
      extraRows.push({
        values: [
          'Between',
          numbro(lessValue).format('$0,0.00'),
          numbro(tier?.accountSize).format('$0,0.00'),
          numbro(tier?.managementFee).format('0.00%'),
          performanceFee
            ? numbro((smallest as HedgeFundFeeSchedule)?.performanceFee).format(
                '0.00%'
              )
            : '',
        ],
      })
    })
    if(balance){
      extraRows.push({values: ['More than', numbro((tiers.length > 0 ? tiers[tiers.length -1] : smallest )?.accountSize).format('$0,0.00'),'', numbro(balance?.managementFee).format('0.00%') ,(performanceFee ? numbro((balance as HedgeFundFeeSchedule)?.performanceFee).format('0.00%') : '')]})
    }

    return (
      <>
      <div className="exportable-form" data-export-name={"Management Fees"} data-export-settings={JSON.stringify({extraRows})}>
      </div>
      <Table className={"fee-table"}>
        <thead>
          <tr>
            <th>ACCOUNT BALANCE</th>
            <th>MANAGEMENT FEE</th>
            {performanceFee && <th>PERFORMANCE FEE</th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {smallest && (
              <tr>
                <td>
                  <Form>
                    <FormGroup row className="fee-group">
                      <Label sm={2} className={"pl-3"}>Less than</Label>
                      <Col sm={5}>
                        <CurrencyInput
                            value={smallest.accountSize}
                            disabled={!editing}
                            invalid={smallestAccountSizeInvalid}
                            onChange={(accountSize) => {
                              const state = { ...this.state }
                              _.set(
                                state,
                                "feeScheduleTiers.smallest.accountSize",
                                accountSize
                              )
                              if (!this.validateAccountSize()) {
                                state.smallestAccountSizeInvalid = true
                                state.errorMessage =
                                  "account size must less than the next tier"
                              } else {
                                state.tierAccountSizeInvalid = new Array(
                                  state.tierAccountSizeInvalid.length
                                ).fill(false)
                                state.smallestAccountSizeInvalid = false
                                state.smallestPerformanceFeeInvalid = false
                                state.errorMessage = ""
                              }
                              this.setState(state)
                            }}
                          />
                        <FormFeedback>{errorMessage}</FormFeedback>
                      </Col>
                    </FormGroup>
                  </Form>
                </td>
                <td>
                  <FormGroup row>
                    <Col sm={12}>
                      <PercentInput
                        value={smallest.managementFee}
                        disabled={!editing}
                        invalid={smallestManagementFeeInvalid}
                        onChange={(managementFee) => {
                          const state = { ...this.state }
                          _.set(
                            state,
                            'feeScheduleTiers.smallest.managementFee',
                            managementFee
                          )
                          state.smallestManagementFeeInvalid = true
                          if (!this.validateManagementFeeOrder()) {
                            state.errorMessage =
                              'This management fee must be the highest'
                          } else if (!this.validateManagementFeeAmount()) {
                            state.errorMessage =
                              MANAGEMENT_FEE_TOLERANCE_MESSAGE
                          } else {
                            state.tierManagementFeeInvalid = new Array(
                              state.tierManagementFeeInvalid.length
                            ).fill(false)
                            state.smallestManagementFeeInvalid = false
                            state.balanceManagementFeeInvalid = false
                            state.smallestAccountSizeInvalid = false
                            state.smallestPerformanceFeeInvalid = false
                            state.errorMessage = ''
                          }
                          this.setState(state)
                        }}
                      />
                      <FormFeedback>{errorMessage}</FormFeedback>
                    </Col>
                  </FormGroup>
                </td>
                {performanceFee && (
                  <td>
                    <FormGroup row>
                      <Col sm={12}>
                        <PercentInput
                          value={
                            (smallest as HedgeFundFeeSchedule).performanceFee
                          }
                          disabled={!editing}
                          invalid={smallestPerformanceFeeInvalid}
                          onChange={(performanceFee) => {
                            const state = { ...this.state }
                            _.set(
                              state,
                              'feeScheduleTiers.smallest.performanceFee',
                              performanceFee
                            )
                            if (!this.validatePerformanceFeeAmount()) {
                              state.smallestPerformanceFeeInvalid = true
                              state.errorMessage =
                                PERFORMANCE_FEE_TOLERANCE_MESSAGE
                            } else {
                              state.smallestPerformanceFeeInvalid = false
                              state.errorMessage = ''
                            }
                            this.setState(state)
                          }}
                        />
                        <FormFeedback>{errorMessage}</FormFeedback>
                      </Col>
                    </FormGroup>
                  </td>
                )}
                <td></td>
              </tr>
            )}
            {tiers &&
              tiers.map((tier, idx) => (
                <tr
                  key={idx}
                  className={classNames({
                    'last-tier-row': editing && idx === tiers.length - 1,
                  })}
                >
                  <td>
                    <Form>
                      <FormGroup row className="fee-group">
                        <Label sm={2} className={"pl-3"}>Between</Label>
                        <Col sm={5}>
                          <Input
                            bsSize="sm"
                            value={
                              idx === 0
                                ? smallest?.accountSize &&
                                  formatCurrency(smallest.accountSize, false)
                                : formatCurrency(
                                    tiers[idx - 1].accountSize,
                                    false
                                  )
                            }
                            disabled
                          />
                        </Col>
                        <Col sm={5}>
                          <CurrencyInput
                            value={tier.accountSize}
                            disabled={!editing}
                            invalid={tierAccountSizeInvalid[idx]}
                            onChange={(accountSize) => {
                              const state = { ...this.state }
                              _.set(
                                state,
                                `feeScheduleTiers.tiers[${idx}].accountSize`,
                                accountSize
                              )
                              if (!this.validateAccountSize()) {
                                state.tierAccountSizeInvalid[idx] = true
                                state.errorMessage =
                                  'account size must be greater than previous tier and less than next'
                              } else if (!this.validateManagementFeeAmount()) {
                                state.errorMessage =
                                  MANAGEMENT_FEE_TOLERANCE_MESSAGE
                              } else {
                                state.tierAccountSizeInvalid[idx] = false
                                state.smallestAccountSizeInvalid = false
                                state.errorMessage = ''
                              }
                              this.setState(state)
                            }}
                          />
                          <FormFeedback>{errorMessage}</FormFeedback>
                        </Col>
                      </FormGroup>
                    </Form>
                  </td>
                  <td>
                    <FormGroup row>
                      <Col sm={12}>
                        <PercentInput
                          value={tier.managementFee}
                          disabled={!editing}
                          invalid={tierManagementFeeInvalid[idx]}
                          onChange={(managementFee) => {
                            const state = { ...this.state }
                            _.set(
                              state,
                              `feeScheduleTiers.tiers[${idx}].managementFee`,
                              managementFee
                            )
                            if (!this.validateManagementFeeOrder()) {
                              state.tierManagementFeeInvalid[idx] = true
                              state.errorMessage =
                                'This management fee must be lower than the previous tier and higher than the next'
                            } else if (!this.validateManagementFeeAmount()) {
                              state.errorMessage =
                                MANAGEMENT_FEE_TOLERANCE_MESSAGE
                            } else {
                              state.tierManagementFeeInvalid[idx] = false
                              state.smallestManagementFeeInvalid = false
                              state.balanceManagementFeeInvalid = false
                            }
                            this.setState(state)
                          }}
                        />
                        <FormFeedback>{errorMessage}</FormFeedback>
                      </Col>
                    </FormGroup>
                  </td>
                  {performanceFee && (
                    <td>
                      <FormGroup row>
                        <Col sm={12}>
                          <PercentInput
                            value={
                              (tier as HedgeFundFeeSchedule).performanceFee
                            }
                            disabled={!editing}
                            invalid={tierPerformanceFeeInvalid[idx]}
                            onChange={(performanceFee) => {
                              const state = { ...this.state }
                              _.set(
                                state,
                                `feeScheduleTiers.tiers[${idx}].performanceFee`,
                                performanceFee
                              )
                              if (!this.validatePerformanceFeeAmount()) {
                                state.tierPerformanceFeeInvalid[idx] = true
                                state.errorMessage =
                                  PERFORMANCE_FEE_TOLERANCE_MESSAGE
                              } else {
                                state.tierPerformanceFeeInvalid[idx] = false
                                state.errorMessage = ''
                              }
                              this.setState(state)
                            }}
                          />
                          <FormFeedback>{errorMessage}</FormFeedback>
                        </Col>
                      </FormGroup>
                    </td>
                  )}
                  <td>
                    {editing && idx === tiers.length - 1 && (
                      <FontAwesomeIcon
                        icon="trash"
                        onClick={this.removeTier}
                        className="cursor-pointer"
                      />
                    )}
                  </td>
                </tr>
              ))}
            {balance && (
              <tr>
                <td>
                  <Form>
                    <FormGroup row className={'fee-group'}>
                      <Label sm={2} className={"pl-3"}>More than</Label>
                      <Col sm={5}>
                        <Input
                          bsSize="sm"
                          value={
                            tiers.length > 0
                              ? formatCurrency(
                                  tiers[tiers.length - 1].accountSize,
                                  false
                                )
                              : formatCurrency(smallest.accountSize, false)
                          }
                          disabled
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </td>
                <td>
                  <FormGroup row>
                    <Col sm={12}>
                      <PercentInput
                        value={balance.managementFee}
                        disabled={!editing}
                        invalid={balanceManagementFeeInvalid}
                        onChange={(managementFee) => {
                          const state = { ...this.state }
                          _.set(
                            state,
                            'feeScheduleTiers.balance.managementFee',
                            managementFee
                          )
                          state.smallestManagementFeeInvalid = true
                          if (!this.validateManagementFeeOrder()) {
                            state.balanceManagementFeeInvalid = true
                            state.errorMessage =
                              'This management fee must be the lowest'
                          } else if (!this.validateManagementFeeAmount()) {
                            state.errorMessage =
                              MANAGEMENT_FEE_TOLERANCE_MESSAGE
                          } else {
                            state.tierManagementFeeInvalid = new Array(
                              state.tierManagementFeeInvalid.length
                            ).fill(false)
                            state.smallestManagementFeeInvalid = false
                            state.balanceManagementFeeInvalid = false
                          }
                          this.setState(state)
                        }}
                      />
                      <FormFeedback>{errorMessage}</FormFeedback>
                    </Col>
                  </FormGroup>
                </td>
                {performanceFee && (
                  <td>
                    <FormGroup row>
                      <Col sm={12}>
                        <PercentInput
                          value={
                            (balance as HedgeFundFeeSchedule).performanceFee
                          }
                          disabled={!editing}
                          invalid={balancePerformanceFeeInvalid}
                          onChange={(performanceFee) => {
                            const state = { ...this.state }
                            _.set(
                              state,
                              'feeScheduleTiers.balance.performanceFee',
                              performanceFee
                            )
                            if (!this.validatePerformanceFeeAmount()) {
                              state.balancePerformanceFeeInvalid = true
                              state.errorMessage =
                                PERFORMANCE_FEE_TOLERANCE_MESSAGE
                            } else {
                              state.balancePerformanceFeeInvalid = false
                              state.errorMessage = ''
                            }
                            this.setState(state)
                          }}
                        />
                        <FormFeedback>{errorMessage}</FormFeedback>
                      </Col>
                    </FormGroup>
                  </td>
                )}
                <td></td>
              </tr>
            )}
            {editing && (
              <tr>
                <td
                  colSpan={performanceFee ? 4 : 3}
                  className="add-tier-row cursor-pointer"
                >
                  <b>Add Another Tier</b>{' '}
                  <FontAwesomeIcon
                    icon="plus-circle"
                    className="ml-1"
                    onClick={this.addTier}
                    style={{
                      color: '#055f8e',
                    }}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    )
  }
}

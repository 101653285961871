import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { FeeMinimums } from './FeeMinimums'
import { ManagementFees } from './ManagementFees'
import { VehicleDetailsQuery } from '../../../__generated__/graphql'
import { StableValueFees } from './StableValueFees'
import { CalculatedFees } from './CalculatedFees'
import Auth from '../../../Auth/Auth'
import { ClosedEndedFees } from './ClosedEndedFees'
import { OtherFees } from './OtherFees'

const auth = new Auth()

export type VehicleType = NonNullable<
  NonNullable<VehicleDetailsQuery>['vehicle']
>['__typename']

const FEE_SCHEDULE_TYPES: VehicleType[] = [
  'OpenEndedPooledVehicle',
  'OpenEndedSeparateAccount',
  'OpenEndedPooledVehiclePrivateEquity',
  'OpenEndedPooledVehicleRealAssets',
  'OpenEndedSeparateAccountRealAssets',
  'OpenEndedPrivateNonRegisteredHedgeFund',
  'OpenEndedSeparateAccountStableValue',
  'OpenEndedPooledVehiclePrivateCredit',
  'OpenEndedCollectiveInvestmentFundRealAssets',
]

const STABLE_VALUE_FEE_TYPES: VehicleType[] = [
  'OpenEndedCollectiveInvestmentFundStableValueComposite',
  'OpenEndedCollectiveInvestmentFundStableValue',
  'OpenEndedSeparateAccountStableValue',
]

const CALCULATED_FEE_TYPES: VehicleType[] = [
  'OpenEndedMutualFund',
  'OpenEndedExchangeTradedFund',
  'OpenEndedCollectiveInvestmentFundComposite',
  'OpenEndedCollectiveInvestmentFund',
]

const CLOSED_ENDED_FEE_TYPES: VehicleType[] = [
  'ClosedEndedPrivateNonRegisteredMulitAssetClass',
  'ClosedEndedPrivateNonRegisteredPrivateCredit',
  'ClosedEndedPrivateNonRegisteredPrivateEquity',
  'ClosedEndedPrivateNonRegisteredRealAssets',
]

const EXCLUDE_OTHER_FEE_TYPES: VehicleType[] = [
  'OpenEndedSeparateAccount',
  'OpenEndedSeparateAccountStableValue',
]

interface VehicleFeesProps {
  vehicleType: VehicleType
  handleInputChange: (value: any, property: string) => void
  editing: boolean
  fundid: string
}

export default class VehicleFees extends Component<VehicleFeesProps> {
  render() {
    const { vehicleType, handleInputChange, editing, fundid } = this.props
    const feeSchedule: boolean = FEE_SCHEDULE_TYPES.indexOf(vehicleType) !== -1
    const stableValue: boolean =
      STABLE_VALUE_FEE_TYPES.indexOf(vehicleType) !== -1
    const calculatedFees: boolean =
      CALCULATED_FEE_TYPES.indexOf(vehicleType) !== -1
    const closedEndedFees: boolean =
      CLOSED_ENDED_FEE_TYPES.indexOf(vehicleType) !== -1
    const otherFees: boolean =
      EXCLUDE_OTHER_FEE_TYPES.indexOf(vehicleType) === -1
    return (
      <>
        <Row>
          <Col>
            <FeeMinimums
              handleInputChange={handleInputChange}
              editing={editing}
              fundid={fundid}
            />
          </Col>
        </Row>
        {feeSchedule && (
          <Row className="mt-3">
            <Col>
              <ManagementFees
                handleInputChange={handleInputChange}
                editing={editing}
                fundid={fundid}
              />
            </Col>
          </Row>
        )}
        {stableValue && (
          <Row className="mt-3">
            <Col>
              <StableValueFees
                handleInputChange={handleInputChange}
                editing={editing}
                fundid={fundid}
              />
            </Col>
          </Row>
        )}
        {auth.checkPermissions(['view:vehicle_private_fee']) &&
          calculatedFees &&
          !editing && (
            <Row className="mt-3">
              <Col>
                <CalculatedFees fundid={fundid} />
              </Col>
            </Row>
          )}
        {closedEndedFees && (
          <Row className="mt-3">
            <Col>
              <ClosedEndedFees
                handleInputChange={handleInputChange}
                editing={editing}
                fundid={fundid}
              />
            </Col>
          </Row>
        )}
        {otherFees && (
          <Row className="mt-3">
            <Col>
              <OtherFees
                handleInputChange={handleInputChange}
                editing={editing}
                fundid={fundid}
              />
            </Col>
          </Row>
        )}
      </>
    )
  }
}

import gql from "graphql-tag"
import {
  FOOTNOTE_SHOW_FRAGMENT,
  FOOTNOTE_SHOW_TARGET_FRAGMENT,
  REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT,
  REPORT_COMMON_VEHICLE_FRAGMENT,
} from "./FootnoteFragments"

export const REPORT_PERFORMANCE_COMPARISON_PORTFOLIO_FRAGMENT = gql`
  fragment ReportPerformanceComparisonPortfolioFragment on ClientPortfolio {
    id
    name
    serviceStartDate
    inceptionDate
    performanceTargetMap {
      target {
        targetId: id
        name
      }
    }
    styleGroup {
      id
      shortName
    }
    plan {
      id
      fiscalMonth
    }
    performanceType {
      code
      value
    }
    groupPerformanceType {
      code
      value
    }
  }
`

export const REPORT_PERFORMANCE_COMPARISON_FETCH_FRAGMENT = gql`
  fragment ReportPerformanceComparisonFetchFragment on PerformanceComparison {
    clientPortfolio {
      ...ReportPerformanceComparisonPortfolioFragment
    }
    vehicles {
      vehicle {
        fundid
        name
        inceptionDate
        product {
          product {
            manager {
              id
              name
            }
          }
        }
      }
    }
    group {
      id
      shortName
    }
    indices {
      id
      name
    }
    date
    actualPerformance
    portfolioPerformanceType
    groupPerformanceType {
      code
      value
    }
    vehiclePerformanceType {
      code
      value
    }
    performanceComparisonPeriod: period {
      lastQuarter
      yearToDate
      fiscalYear
      last1Year
      last2Years
      last3Years
      last5Years
      last7Years
      last10Years
      last15Years
      last20Years
      sinceInception
    }
    reportingPerformancePeriod
    numberOfYearsToInclude
    showInvestmentPhilosophy
    showAssetGrowth
    showDCInfo
    DCTotalFundPortfolio {
      id
      name
    }
    showFootnote
    showTargetFootnote
    showPerformanceTable
    showMedianColumn
  }
  ${REPORT_PERFORMANCE_COMPARISON_PORTFOLIO_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_RANKING_FRAGMENT = gql`
  fragment ReportPerformanceComparisonRankingFragment on GroupRankingResult {
    ... on GroupRanking {
      percentiles {
        percentile
        value
      }
      rank
      stat
      info
    }
    ... on PendingGroupCache {
      jqid
      stat
    }
    ... on NotFoundGroupCache {
      message
      stat
    }
  }
`

const REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT = gql`
  fragment ReportPerformanceComparisonPerformanceFragment on Performance {
    ranking {
      ...ReportPerformanceComparisonRankingFragment
    }
    defaultRanking {
      ...ReportPerformanceComparisonRankingFragment
    }
    startDate
    endDate
    status
    value {
      active
    }
    warning
    error
  }
  ${REPORT_PERFORMANCE_COMPARISON_RANKING_FRAGMENT}
`

const REPORT_PERFORMANCE_COMPARISON_VEHICLE_GRAPH_FRAGMENT = gql`
  fragment ReportPerformanceComparisonVehicleGraphFragment on VehicleFields {
    graphNameComputed
    inceptionDate
    category {
      code
    }
    product {
      product {
        id
        manager {
          id
          shortName
        }
      }
    }
  }
`

export const REPORT_PERFORMANCE_COMPARISON_DC_PORTFOLIO_FRAGMENT = gql`
  fragment ReportPerformanceComparisonDcPortfolioFragment on ClientPortfolio {
    assets(filter: { startDate: $monthDate, endDate: $monthDate }) {
      date
      totalMarketValue
    }
    numberOfParticipants(
      filter: { startDate: $monthDate, endDate: $monthDate }
    ) {
      date
      count
    }
    datedFees(filters: { startDate: $monthDate, endDate: $monthDate }) {
      date
      revenueShare
      totalExpense
    }
    relatedVehicle {
      ... on OpenEndedCollectiveInvestmentFundStableValue {
        stableValueCollectiveInvestmentFund {
          fees(filter: { startDate: $monthDate, endDate: $monthDate }) {
            date
            allInFee
          }
        }
      }
      ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
        stableValueCollectiveInvestmentFund {
          fees(filter: { startDate: $monthDate, endDate: $monthDate }) {
            date
            allInFee
          }
        }
      }
      ... on OpenEndedSeparateAccountStableValue {
        stableValueSeparateAccount {
          feeAtAum(filter: { date: $monthDate, aum: $aum }) {
            date
            fee
          }
        }
      }
      ... on OpenEndedMutualFund {
        vehicle {
          identifiers {
            ticker
          }
        }
      }
      ... on OpenEndedExchangeTradedFund {
        vehicle {
          identifiers {
            ticker
          }
        }
      }
      vehicle {
        id: fundid
        name
        category {
          code
          shortValue
        }
      }
    }
  }
`

const REPORT_PERFORMANCE_COMPARISON_SUPPORTING_PORTFOLIO_FRAGMENT = gql`
  fragment ReportPerformanceComparisonSupportingPortfolioFragment on ClientPortfolio {
    footnote {
      ...FootnoteShowFragment
    }
    targetFootnotes {
      ...FootnoteShowTargetFragment
    }
    assets(filter: { startDate: $monthDate, endDate: $monthDate }) {
      date
      totalMarketValue
    }
    priorAssets: assets(
      filter: { startDate: $priorDate, endDate: $priorDate }
    ) {
      date
      totalMarketValue
    }
    cashFlowActivity(
      filter: { startDate: $monthDate, endDate: $monthDate, period: Quarterly }
    ) {
      date
      netFlow
    }
    performanceType {
      code
      value
    }
    relatedVehicle {
      vehicle {
        id: fundid
        product {
          product {
            id
            writeUps {
              facts {
                id
                researchCategory {
                  code
                  value
                }
                text
                lastUpdated
                date
                bullets
              }
            }
          }
        }
      }
    }
  }
  ${FOOTNOTE_SHOW_FRAGMENT}
  ${FOOTNOTE_SHOW_TARGET_FRAGMENT}
`

// TAILING GROUP FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_GROUP_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTrailingGroupFragment on Group {
    id
    name: shortName
    veryLongName
    quarterly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarter) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYear: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYear) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1Year) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInception: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          dateToDateEnd: $monthDate
          trailingNumberOfQuarters: $sinceInceptionQuarters
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
      }
    ) @include(if: $sinceInception) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// TAILING TARGET FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_TARGET_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTrailingTargetFragment on Target {
    targetId: id
    name
    quarterly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarter) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYear: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYear) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1Year) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInception: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          dateToDateEnd: $monthDate
          trailingNumberOfQuarters: $sinceInceptionQuarters
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
      }
    ) @include(if: $sinceInception) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// TAILING INDEX FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_INDEX_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTrailingIndexFragment on Index {
    id
    name
    quarterly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarter) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYear: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYear) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1Year) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInception: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          dateToDateEnd: $monthDate
          trailingNumberOfQuarters: $sinceInceptionQuarters
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
      }
    ) @include(if: $sinceInception) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// TAILING VEHICLE FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTrailingVehicleFragment on VehicleFields {
    ...ReportCommonVehicleFragment
    ...ReportPerformanceComparisonVehicleGraphFragment
    quarterly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarter) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYear: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYear) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1Year) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearly: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInception: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          dateToDateEnd: $monthDate
          trailingNumberOfQuarters: $sinceInceptionQuarters
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
      }
    ) @include(if: $sinceInception) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_VEHICLE_GRAPH_FRAGMENT}
  ${REPORT_COMMON_VEHICLE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// TAILING VEHICLE FRAGMENT - DEFAULT

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_DEFAULT_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTrailingVehicleDefaultFragment on VehicleFields {
    ...ReportCommonVehicleFragment
    ...ReportPerformanceComparisonVehicleGraphFragment
    quarterly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarter) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytd: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYear: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYear) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1Year) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearly: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20Years) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInception: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          dateToDateEnd: $monthDate
          trailingNumberOfQuarters: $sinceInceptionQuarters
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
      }
    ) @include(if: $sinceInception) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_VEHICLE_GRAPH_FRAGMENT}
  ${REPORT_COMMON_VEHICLE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// TAILING CLIENT PORTFOLIO FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTrailingClientPortfolioFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    serviceStartDate
    serviceEndDate
    quarterlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarterGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytdGross: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYearGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYearGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1YearGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearlyGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInceptionGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          dateToDateEnd: $monthDate
          trailingNumberOfQuarters: $sinceInceptionQuarters
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
      }
    ) @include(if: $sinceInceptionGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    quarterlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarterNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytdNet: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYearNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYearNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1YearNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearlyNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInceptionNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          dateToDateEnd: $monthDate
          trailingNumberOfQuarters: $sinceInceptionQuarters
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                dateToDateEnd: $monthDate
                trailingNumberOfQuarters: $sinceInceptionQuarters
              }
            }
          }
        }
      }
    ) @include(if: $sinceInceptionNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    performanceTargetMap {
      order
      target {
        ...ReportPerformanceComparisonTrailingTargetFragment
      }
    }
    styleGroup @include(if: $withGroup) {
      ...ReportPerformanceComparisonTrailingGroupFragment
    }
    ...ReportPerformanceComparisonSupportingPortfolioFragment
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_SUPPORTING_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_GROUP_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_TARGET_FRAGMENT}
`

// TAILING CLIENT PORTFOLIO FRAGMENT - DEFAULT

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_CLIENT_PORTFOLIO_DEFAULT_FRAGMENT = gql`
  fragment ReportPerformanceComparisonTrailingClientPortfolioDefaultFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    serviceStartDate
    serviceEndDate
    quarterlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarterGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytdGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYearGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYearGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1YearGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearlyGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20YearsGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInceptionGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: SinceInception, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: SinceInception, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: { type: SinceInception, dateToDateEnd: $monthDate }
            }
          }
        }
      }
    ) @include(if: $sinceInceptionGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    quarterlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 1
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $lastQuarterNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytdNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalYearNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalYearNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last1YearNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twoYearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 8
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 8
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last2YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    threeYearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 12
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 12
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last3YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiveYearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 20
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 20
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last5YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sevenYearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 28
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 28
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last7YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    tenYearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 40
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 40
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last10YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fifteenYearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 60
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 60
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last15YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    twentyYearlyNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 80
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 80
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $last20YearsNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    sinceInceptionNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: SinceInception, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: SinceInception, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: { type: SinceInception, dateToDateEnd: $monthDate }
            }
          }
        }
      }
    ) @include(if: $sinceInceptionNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    performanceTargetMap {
      order
      target {
        ...ReportPerformanceComparisonTrailingTargetFragment
      }
    }
    styleGroup @include(if: $withGroup) {
      ...ReportPerformanceComparisonTrailingGroupFragment
    }
    ...ReportPerformanceComparisonSupportingPortfolioFragment
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_SUPPORTING_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_GROUP_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_TARGET_FRAGMENT}
`

// YEARS GROUP FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_YEARS_GROUP_FRAGMENT = gql`
  fragment ReportPerformanceComparisonYearsGroupFragment on Group {
    id
    name: shortName
    veryLongName
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscal: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscal) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwo: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwo) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThree: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThree) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFour: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFour) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFive: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFive) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSix: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSix) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSeven: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSeven) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEight: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEight) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNine: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNine) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTen: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [10, 25, 50, 75, 90]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTen) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// YEARS TARGET FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_YEARS_TARGET_FRAGMENT = gql`
  fragment ReportPerformanceComparisonYearsTargetFragment on Target {
    targetId: id
    name
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscal: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscal) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwo: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwo) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThree: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThree) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFour: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFour) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFive: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFive) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSix: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSix) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSeven: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSeven) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEight: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEight) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNine: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNine) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTen: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTen) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// YEARS INDEX FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_YEARS_INDEX_FRAGMENT = gql`
  fragment ReportPerformanceComparisonYearsIndexFragment on Index {
    id
    name
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscal: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscal) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwo: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwo) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThree: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThree) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFour: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFour) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFive: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFive) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSix: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSix) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSeven: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSeven) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEight: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEight) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNine: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNine) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTen: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTen) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// YEARS VEHICLE FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_FRAGMENT = gql`
  fragment ReportPerformanceComparisonYearsVehicleFragment on VehicleFields {
    ...ReportCommonVehicleFragment
    ...ReportPerformanceComparisonVehicleGraphFragment
    ytd: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscal: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscal) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwo: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwo) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThree: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThree) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFour: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFour) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFive: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFive) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSix: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSix) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSeven: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSeven) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEight: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEight) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNine: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNine) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTen: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        vehiclePerformanceTypeCode: $vehiclePerformanceType
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTen) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_VEHICLE_GRAPH_FRAGMENT}
  ${REPORT_COMMON_VEHICLE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// YEARS VEHICLE FRAGMENT - DEFAULT

export const REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_DEFAULT_FRAGMENT = gql`
  fragment ReportPerformanceComparisonYearsVehicleDefaultFragment on VehicleFields {
    ...ReportCommonVehicleFragment
    ...ReportPerformanceComparisonVehicleGraphFragment
    ytd: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDate) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscal: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscal) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwo: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwo) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThree: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThree) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFour: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFour) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFive: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFive) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSix: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSix) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSeven: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSeven) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEight: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEight) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNine: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNine) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTen: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        useRanking: true
        projection: r
        type: t
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTen) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_VEHICLE_GRAPH_FRAGMENT}
  ${REPORT_COMMON_VEHICLE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
`

// YEARS CLIENT PORTFOLIO FRAGMENT

export const REPORT_PERFORMANCE_COMPARISON_YEARS_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportPerformanceComparisonYearsClientPortfolioFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    serviceStartDate
    serviceEndDate
    ytdGross: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwoGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwoGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThreeGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThreeGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFourGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFourGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFiveGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFiveGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSixGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSixGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSevenGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSevenGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEightGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEightGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNineGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNineGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTenGross: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTenGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytdNet: performance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: { type: YearToDate, dateToDateEnd: $monthDate }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwoNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwoNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThreeNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThreeNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFourNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFourNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFiveNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFiveNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSixNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSixNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSevenNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSevenNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEightNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEightNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNineNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNineNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTenNet: performance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTenNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    performanceTargetMap {
      order
      target {
        ...ReportPerformanceComparisonYearsTargetFragment
      }
    }
    styleGroup @include(if: $withGroup) {
      ...ReportPerformanceComparisonYearsGroupFragment
    }
    ...ReportPerformanceComparisonSupportingPortfolioFragment
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_SUPPORTING_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_TARGET_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_GROUP_FRAGMENT}
`

// YEARS CLIENT PORTFOLIO FRAGMENT - DEFAULT

export const REPORT_PERFORMANCE_COMPARISON_YEARS_CLIENT_PORTFOLIO_DEFAULT_FRAGMENT = gql`
  fragment ReportPerformanceComparisonYearsClientPortfolioDefaultFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    serviceStartDate
    serviceEndDate
    ytdGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { groupPerformanceTypeCode: $groupPerformanceType period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwoGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwoGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThreeGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThreeGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFourGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFourGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFiveGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFiveGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSixGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSixGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSevenGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSevenGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEightGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEightGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNineGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNineGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTenGross: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTenGross) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    ytdNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: { type: YearToDate, dateToDateEnd: $monthDate }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { groupPerformanceTypeCode: $groupPerformanceType period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: { period: { type: YearToDate, dateToDateEnd: $monthDate } }
          }
        }
      }
    ) @include(if: $yearToDateNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    fiscalNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: $fiscalQuarters
          dateToDateEnd: $monthDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: $fiscalQuarters
                dateToDateEnd: $monthDate
              }
            }
          }
        }
      }
    ) @include(if: $fiscalNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTwoNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTwoDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTwoDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTwoNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearThreeNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearThreeDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearThreeDate
              }
            }
          }
        }
      }
    ) @include(if: $yearThreeNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFourNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFourDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFourDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFourNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearFiveNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearFiveDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearFiveDate
              }
            }
          }
        }
      }
    ) @include(if: $yearFiveNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSixNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSixDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSixDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSixNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearSevenNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearSevenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearSevenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearSevenNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearEightNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearEightDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearEightDate
              }
            }
          }
        }
      }
    ) @include(if: $yearEightNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearNineNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearNineDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearNineDate
              }
            }
          }
        }
      }
    ) @include(if: $yearNineNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    yearTenNet: defaultPerformance(
      filters: {
        groupId: $groupId
        period: {
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 4
          dateToDateEnd: $yearTenDate
        }
        actual: $actualPerformance
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
        useRanking: true
        rankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              groupPerformanceTypeCode: $groupPerformanceType
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
        defaultRankingInput: {
          percentiles: [50]
          stat: {
            metric: performance
            filter: {
              period: {
                type: TrailingNumberOfQuarters
                trailingNumberOfQuarters: 4
                dateToDateEnd: $yearTenDate
              }
            }
          }
        }
      }
    ) @include(if: $yearTenNet) {
      ...ReportPerformanceComparisonPerformanceFragment
    }
    performanceTargetMap {
      order
      target {
        ...ReportPerformanceComparisonYearsTargetFragment
      }
    }
    styleGroup @include(if: $withGroup) {
      ...ReportPerformanceComparisonYearsGroupFragment
    }
    ...ReportPerformanceComparisonSupportingPortfolioFragment
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_SUPPORTING_PORTFOLIO_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_PERFORMANCE_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_TARGET_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_GROUP_FRAGMENT}
`
const DefaultHelpSiteHomeAddress = "http://callandna.helpdocsonline.com/home"
const DefaultCallanHelpSite = "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839464449/Dashboards"

const GenericHelpSite = {
  "interactions": "http://callandna.helpdocsonline.com/interactions",
  "clients": "http://callandna.helpdocsonline.com/client-portal",
  "sort filter grid": "http://callandna.helpdocsonline.com/sort-filter-grid",
  "products": "http://callandna.helpdocsonline.com/navigation$Product Data",
  "product people": "http://callandna.helpdocsonline.com/how-to-add-person-product",
  "product performance": "http://callandna.helpdocsonline.com/navigation$product performance",
  "product characteristics": "http://callandna.helpdocsonline.com/navigation$product characteristics",
  "product portfolio": "https://callandna.helpdocsonline.com/navigation$Client Portfolios",
  "documents": "http://callandna.helpdocsonline.com/documents",
  "manager data": "http://callandna.helpdocsonline.com/client-portal$Manager Data",
  "diversity": "http://callandna.helpdocsonline.com/callan-esg-dei$dei",
  "esg": "http://callandna.helpdocsonline.com/callan-esg-dei",
  "navigation": "http://callandna.helpdocsonline.com/navigation",
  "people": "http://callandna.helpdocsonline.com/how-to-add-person-firm",
  "research": "http://callandna.helpdocsonline.com/research-products",
  "analysis": "https://callandna.helpdocsonline.com/product-analysis",
}

const ClientHelpSite = {
  "default": GenericHelpSite["clients"],
  "client documents": "http://callandna.helpdocsonline.com/client-portal$Client Docs",
  "documents": "http://callandna.helpdocsonline.com/navigation",
  "client fund documents": "http://callandna.helpdocsonline.com/client-portal$Fund Docs",
  "client fund profile": "http://callandna.helpdocsonline.com/client-portal$Fund Profile",
  "interactions": "http://callandna.helpdocsonline.com/client-portal$Interactions",
  "client manager documents": "http://callandna.helpdocsonline.com/client-portal$manager documents",
}

const CallanInternalSite = {
  "interactions": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Interactions",
  "write-ups": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Write-ups",
  "lists": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839432744/Lists",
  "list detail": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839432744/Lists#%5BinlineExtension%5DList-Detail-Screen",
  "client documents": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839465005/Client+Portal#Client-Documents",
  "clients-mega": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839464961/Clients",
  "documents": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1601012069/Callan+DNA+Internal+Documentation#Documents",
  "manager documents": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Documents",
  "client fund profile": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839465005/Client+Portal#Fund-Profile",
  "client fund documents": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839465005/Client+Portal#Fund-Documents",
  "client manager documents": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839465005/Client+Portal#Manager-Documents",
  "product documents": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Documents",
  "client interactions": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839465005/Client+Portal#Interactions",
  "esg": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Manager-Overview-%3EESG",
  "reports": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839432311/Reports",
  "reports editor": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839432360/Report+Editor",
  "reports sumsheet": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1911095314/Sumsheet",
  "plans": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839333759/Manage+Plans",
  "portfolios": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/2314174499/Plan+Portfolios",
  "performance": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/2203353117/Plan+Performance%3A+Returns+and+Cash+Flow",
  "overview": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839333504/Plans",
  "client portfolio overview": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839333610/Portfolio+Definition",
  "diversity": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Manager-Overview-%3E-Diversity-&-Inclusion",
  "home": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839464449/Dashboards",
  // "control-center": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1913225278/Report+Due+Dates",
  "report-due-dates": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1913225278/Report+Due+Dates",
  "dna-users": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1912307765/DNA+Users",
  "imported-files": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/2052653120/Data+Importer",
  "research": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1891008538/Research+Products",
  "control-center": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1913225278/Report+Due+Dates",
  "groups": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1945600028/Group+Overview",
  "indexes": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1946845185/Index+Overview",
  "analysis": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Product-Analysis",
  "profilePrivateEquity": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839432232/Private+Equity+and+Private+Credit+Profile+Factsheet",
  "product portfolio": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/1839267861/Managers+Products#Product-Vehicles-%3E-Client-Portfolio-Fees",
  "plan-activities": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/2313912326/Plan+Activities",
  "job-settings": "https://callanllc.atlassian.net/wiki/spaces/1TMVCRIEPTPIGWCRMPL/pages/2256633877/Portfolio+Job+Settings",
}

interface defaultSite {
  "default": string
}

interface HelpSiteMappingType extends defaultSite  {
  [category: string]: {
    [type:string]:{
      [role:string]: string
    } | string
  } | string
}

export const HelpSiteMapping: HelpSiteMappingType = {
  "managers":{
    "profile":{
      "default": DefaultHelpSiteHomeAddress,
    },
    "overview": {
      "default": GenericHelpSite["navigation"],
    },
    "esg":{
      "callanInternal": CallanInternalSite["esg"],
      "default": GenericHelpSite["esg"],
    },
    "products":{
      "default": GenericHelpSite["sort filter grid"],
    },
    "people":{
      "default": GenericHelpSite["people"],
    },
    "assets":{
      "default": DefaultHelpSiteHomeAddress,
    },
    "interactions":{
      "callanInternal": CallanInternalSite["interactions"],
      "default": GenericHelpSite["interactions"],
    },
    "write-ups":{
      "callanInternal": CallanInternalSite["write-ups"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "documents":{
      "default": GenericHelpSite["documents"],
      "callanInternal": CallanInternalSite["manager documents"],
      "clientUser": ClientHelpSite["documents"],

    },
    "diversity":{
      "default": GenericHelpSite["diversity"],
      "callanInternal": CallanInternalSite["diversity"],
    },
    "target date families":{
      "default": GenericHelpSite["sort filter grid"],
    },
    "client type":{
      "default": GenericHelpSite["navigation"],
    },
    "location":{
      "default": GenericHelpSite["navigation"],
    },
    "asset class":{
      "default": GenericHelpSite["navigation"],
    },
    "accounts":{
      "default": GenericHelpSite["navigation"],
    },
    "closed-end fundraising":{
      "default": GenericHelpSite["navigation"],
    },
    "default": DefaultHelpSiteHomeAddress
  },
  "products":{
    "interactions":{
      "callanInternal": CallanInternalSite["interactions"],
      "default": GenericHelpSite["interactions"],
    },
    "write-ups":{
      "callanInternal": CallanInternalSite["write-ups"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "overview":{
      "default": GenericHelpSite["products"],
    },
    "people":{
      "default": GenericHelpSite["product people"],
    },
    "vehicles":{
      "default": GenericHelpSite["products"],
    },
    "portfolios":{
      "callanInternal": CallanInternalSite["product portfolio"],
      "clientUser": GenericHelpSite["product portfolio"],
      "default": GenericHelpSite["product portfolio"],
    },
    "assets":{
      "default": GenericHelpSite["products"],
    },
    "performance":{
      "default": GenericHelpSite["product performance"],
    },
    "characteristics":{
      "default": GenericHelpSite["product characteristics"],
    },
    "profile":{
      "default": DefaultHelpSiteHomeAddress,
      "callanInternalPrivateEquity": CallanInternalSite["profilePrivateEquity"],
      "clientUserPrivateEquity": GenericHelpSite["products"],
    },
    "documents":{
      "callanInternal": CallanInternalSite["product documents"],
      "clientUser": ClientHelpSite["documents"],
      "default": GenericHelpSite["documents"],
    },
    "analysis":{
      "default": GenericHelpSite["analysis"],
      "callanInternal": CallanInternalSite["analysis"],
    },
    "default": DefaultHelpSiteHomeAddress,
  },

  "glidepaths":{
    "interactions":{
      "callanInternal": CallanInternalSite["interactions"],
      "default": GenericHelpSite["interactions"],
    },
    "default": DefaultHelpSiteHomeAddress
  },
  "clients":{
    "documents": {
      "callanInternal": CallanInternalSite["client documents"],
      "clientUser": ClientHelpSite["client documents"],
      "default": DefaultHelpSiteHomeAddress
    },
    "fund documents": {
      "callanInternal": CallanInternalSite["client fund documents"],
      "clientUser": ClientHelpSite["client fund documents"],
      "default": DefaultHelpSiteHomeAddress
    },
    "manager documents": {
      "callanInternal": CallanInternalSite["client manager documents"],
      "clientUser": ClientHelpSite["client manager documents"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "portfolios": {
      "default": GenericHelpSite["manager data"],
    },
    "report": {
      "callanInternal": CallanInternalSite["client fund profile"],
      "clientUser": ClientHelpSite["client fund profile"],
      "default": DefaultHelpSiteHomeAddress
    },
    "interactions": {
      "callanInternal": CallanInternalSite["client interactions"],
      "clientUser": ClientHelpSite["interactions"],
      "default": DefaultHelpSiteHomeAddress
    },
    "callanInternal": CallanInternalSite["clients-mega"],
    "clientUserDefault": ClientHelpSite.default,
    "default": DefaultHelpSiteHomeAddress,
  },
  "lists":{
    "list detail":{
      "callanInternal": CallanInternalSite["list detail"],
    },
    "callanInternal": CallanInternalSite["lists"],
    "default": DefaultHelpSiteHomeAddress,
  },
  "reports":{
    "callanInternal": CallanInternalSite["reports"],
    "default": DefaultHelpSiteHomeAddress,
    "reports editor": {
      "callanInternal": CallanInternalSite["reports editor"],
      "default": DefaultHelpSiteHomeAddress
    },
    "reports sumsheet": {
      "callanInternal": CallanInternalSite["reports sumsheet"],
      "default": DefaultHelpSiteHomeAddress
    },
  },
  "plans":{
    "overview":{
      "callanInternal": CallanInternalSite["overview"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "portfolios":{
      "callanInternal": CallanInternalSite["portfolios"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "performance":{
      "callanInternal": CallanInternalSite["performance"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "activities": {
      "callanInternal": CallanInternalSite["plan-activities"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "callanInternal": CallanInternalSite["plans"],
    "default": DefaultHelpSiteHomeAddress,
  },
  "clientportfolios":{
    "overview":{
      "callanInternal": CallanInternalSite["client portfolio overview"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "jobsettings":{
      "callanInternal": CallanInternalSite["job-settings"],
      "default": DefaultHelpSiteHomeAddress,
    },
    "default": DefaultHelpSiteHomeAddress,
  },
  "control-center":{
    "report-due-dates": {
      "callanInternal": CallanInternalSite["report-due-dates"],
    },
    "dna-users": {
      "callanInternal": CallanInternalSite["dna-users"],
    },
    "imported-files": {
      "callanInternal": CallanInternalSite["imported-files"],
    },
    // "callanInternal": CallanInternalSite["control-center"],
    "default": DefaultHelpSiteHomeAddress,
  },
  "documents":{
    "callanInternal": CallanInternalSite["manager documents"],
    "clientUserDefault": ClientHelpSite["documents"],
    "default": GenericHelpSite["documents"],
  },
  "research": {
    "callanInternal": CallanInternalSite["research"],
    "default": GenericHelpSite["research"],
  },
  "groups": {
    "overview": {
      "callanInternal": CallanInternalSite["groups"],
    },
  },
  "indexes": {
    "overview": {
      "callanInternal": CallanInternalSite["indexes"],
    },
  },
  "callanInternal": DefaultCallanHelpSite,
  "clientUserDefault": ClientHelpSite.default,
  "default": DefaultHelpSiteHomeAddress,
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import iassign from "immutable-assign"
import _, { difference, first, sortBy } from "lodash"
import moment from 'moment'
import React, { Component, useContext, useEffect, useMemo, useState } from "react"
import { Button, ButtonDropdown, Col, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap"
import { CalendarContext } from '../../../Context/CalendarContext'
import { DATE_API_FORMAT, FormInputField, FormInputSubClasses } from "../../../helpers/constant"
import { ClientPortfolioIsCompositeFragment, Maybe, useUpdateClientPortfolioDefinitionMutation } from "../../../__generated__/graphql"
import { SearchTypeDisplays } from '../../Search/SearchEnums'
import { FormInput } from "../../ui/Forms/FormInput"
import { PlanSearch, PlanSelection } from './ClientPortfolioMemberOfComposite'
import { CompositeMemberExtendedType } from './helper'
import { ClientPortfolioAddStatic, UpdatePortfolioListProps } from './Search'
import { HistoryModal } from "./HistoryModal"
import { getCompositeMembersInput } from "./ClientPortfolioDefinition"

type CompositeMembersComponentProps = {
  clientportfolioId: number
  data: CompositeMemberExtendedType[] | any[]
  inceptionDate?: string
  endAssetDate?: string
  initialState: CompositeMemberExtendedType[] | any[]
  handleChange: (value: any) => void
  editMode: boolean
  hidden?: boolean
  planIds: {id: number, name: string, __typename: string}[]
  setEditedCompositeMembers: React.Dispatch<React.SetStateAction<CompositeMemberExtendedType[]>>
}

type DisplayDataType = {
  property: string
  checkFunction?: (input: any) => any
  value: any
}

interface columnDefItem {
  field: string
  type: string
  title: string
  className?: string
  readonly?: boolean
  subtype?: string
  optionSource?: string
  required?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
  textLinkParams?: {
    property?: string, // to get property value from state and then passed to input as text params
    url: string,
  },
  displayData?: DisplayDataType
}

interface SingleCompositeMemberInput extends columnDefItem {

}

type TableVisibility = {[key in TableKeys]: boolean}

interface SingleCompositeMemberProps {
  data: any
  row: number
  columnDef: SingleCompositeMemberInput[]
  editMode: boolean
  removeRow: (input: any) => void
  updateValue: (value: any, type: string, property: string) => void
  tableKey: string
  hideRemoveButton?: boolean
  grayClass?: string
}

const CompositeMemberInput: SingleCompositeMemberInput[] = [
  {
    field: "member.id",
    title: "Portfolio ID",
    type: "text",
    // required: true,
    readonly: true,
    subClasses: {
      // inputWrapperClasses: "row px-3"
    },
    widthClass: "col-3",
  },
  {
    field: "member.name",
    title: "Portfolio Name",
    type: "text",
    subtype: "textLink",
    // selected options??
    readonly: true,
    subClasses: {
      // inputWrapperClasses: "row px-3"
    },
    textLinkParams: {
      property: "member.id",
      url: "/clientportfolios/",
    },
    widthClass: "col-6",
  },
]

const BatchEndDateInput: FormInputField[] = [
  {
    property: "startDate",
    label: "Start",
    type: "date",
    readonly: true,
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
  {
    property: "endDate",
    label: "End",
    type: "date",
    subtype: "month",
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
]

const getInitialData = (data: CompositeMemberExtendedType[]) => {
  let mappedData: CompositeMemberExtendedType[]  = data?.map(el=>({orderedPid: el?.member?.id, ...el,})) || []
  return _.sortBy(mappedData, ["orderedPid", "member.id"])
}

const getNewTableData = (allData: CompositeMemberExtendedType[]) => {
  let data = allData.filter(el => el?.source === "new")
  let defaultEmptyData = [] as CompositeMemberExtendedType[]
  if(!data || data?.length < 1) {
    return defaultEmptyData
  } else {
    let result = data?.map(el => {
      let {endDate, ...rest} = el
      return el
    }) || defaultEmptyData
    return result
  }
}

export const getCurrentCompositeMembersTableData = (data: CompositeMemberExtendedType[]) => {
  let defaultEmptyData = [] as CompositeMemberExtendedType[]
  if(!data || data?.length < 1) {
    return defaultEmptyData
  } else {
    return data.filter(el => (el?.source === "current"))
  }
}

const getPreviousTableData = (data: CompositeMemberExtendedType[]) => {
  let defaultEmptyData = [] as CompositeMemberExtendedType[]
  if(!data || data?.length < 1) {
    return defaultEmptyData
  } else {
    let today = moment()
    let result =  data.filter(el => (el?.source === "previous"))
    let sorted = _.orderBy(result, ["endDate", "startDate", "orderedPid", "member.id"], ['desc', 'desc', 'asc', 'asc']) as CompositeMemberExtendedType[]
    return sorted
  }
}

const getHistoryTableData = (data: CompositeMemberExtendedType[]) => {
  let headers = CompositeMemberInput.map(row => row.title)
  let sortedData = sortBy(data, (acc) => acc.endDate).reverse()
  let result = sortedData.reduce((acc, row) => {
    let {endDate, ...rest} = row
    if(!acc[endDate]) {
      acc[endDate] = {
        date: endDate,
        data: []
      }
    }
    acc[endDate].data.push(row)
    return acc
  }, {} as any)
  let rows =  Object.values(result) as {date: string, data: any[]}[]
  return ({headers, rows}) as CompositeMemberHistoryData
}

const CompositeMemberRow = ({ data, row, columnDef, editMode, removeRow, updateValue, tableKey, hideRemoveButton, grayClass }: SingleCompositeMemberProps) => {
  return (
    <tr className={editMode ? "hover-actions editing" : "hover-actions"}>
      {columnDef.map((header, idx) => {
        let { field, displayData, textLinkParams, type, subtype } = header
        let propertyVal = _.get(data, field)
        let editable = !!editMode
        let textLinkResolved: Maybe<{ url: string }> = null
        if(type === "text" && subtype == "textLink" && !editMode && textLinkParams) {
          let {property: linkProperty, url} = textLinkParams
          let urlResolved: string = url
          if(linkProperty) {
            urlResolved +=  _.get(data, linkProperty)
          }
          textLinkResolved = {url: urlResolved}
        }
        return (
          <td key={`${idx}`} className={`${header?.widthClass}`}>
            <FormInput
              key={header.field}
              property={`${row}-${idx}-${header.field}`}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={`${tableKey}-composite-member-item-${row}-${idx}`}
              editMode={false}
              propertyVal={propertyVal}
              readonly={!!header.readonly}
              updateValue={(value: any) => {
                updateValue(value, header.type, header.field)
              }}
              // required={header.required}
              // optionSource={header.optionSource}
              subClasses={header.subClasses || {}}
              textLinkParams={textLinkResolved}
            />
          </td>
        )
      })}
      {/* hide action column in history modal*/}
      <td className="actions">
        <div className="w-100 d-flex justify-content-end">
          {editMode && !hideRemoveButton && (
            <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeRow(row)}>
              <FontAwesomeIcon icon="trash" className="text-blue-100" />
            </Button>
          )}
        </div>
      </td>
    </tr>
  )
}

const isEmptyTable = (props: CompositeMemberExtendedType[]) => !(props?.length > 0)

type TableKeys = "new" | "current" | "previous"

const DEFAULT_NEW_START_DATE = "1900-01-31"

interface CompositeMemberHistoryProps {
  clientportfolioId: number
  isOpen: boolean
  title: string
  toggle: () => void
  data: CompositeMemberExtendedType[]
  handleChange: (value: any) => void
  planIds: {id: number, name: string, __typename: string}[]
  inceptionDate?: string
}

interface CompositeMemberHistoryData {
  headers: string[]
  rows: {date: string, data: any[]}[]
  // rows: FeeHistoryRow[]
}
export const CompositeMemberHistoryModal: React.FC<CompositeMemberHistoryProps> = ({
  clientportfolioId,
  isOpen,
  title,
  toggle,
  data,
  handleChange,
  planIds,
  inceptionDate,
}) => {
  const [updateClientPortfolio] = useUpdateClientPortfolioDefinitionMutation()
  const [localData, setLocalData] = useState<CompositeMemberExtendedType[]>(data)
  const [saving, setSaving] = useState<boolean>(false)
  const removeRow = (endDate: string, compositeId: number ) => {
    let newData = localData.filter((data) => !(data.endDate === endDate && data.member?.id === compositeId))
    setLocalData(newData)
  }
  const historyData = useMemo(() => {
    return getHistoryTableData(localData) as CompositeMemberHistoryData
  }, [localData])
  useEffect(() => {
    setLocalData(data)
  }, [data, isOpen])

  const handleSubmit = () => {
    setSaving(true)
    let patch = { compositeMember: getCompositeMembersInput({oldState: data, newState: localData})}
    let input = {id: clientportfolioId, patch}
    updateClientPortfolio({variables: {input}}).then((res) => {
      setSaving(false)
      handleChange(localData)
      toggle()
    }).catch((err) => {
      setSaving(false)
      console.log(err)
    })
  }

  return (
    <HistoryModal isOpen={isOpen} toggle={toggle} title={title} handleSubmit={handleSubmit} saving={saving}>
      {historyData.rows?.map((row, rowNum) => {
        return (
          <CompositeMemberHistoryTable
            key={rowNum}
            title={title}
            data={localData}
            setLocalData={setLocalData}
            row={row}
            removeRow={removeRow}
            planIds={planIds}
            inceptionDate={inceptionDate}
          />
        )
      })}
    </HistoryModal>
  )
}

interface CompositeMemberHistoryTableProps {
  title: string
  data: CompositeMemberExtendedType[]
  setLocalData: React.Dispatch<React.SetStateAction<CompositeMemberExtendedType[]>>
  row: {date: string, data: any[]}
  removeRow: (endDate: string, compositeId: number ) => void
  planIds: {id: number, name: string, __typename: string}[]
  inceptionDate?: string
}

export const CompositeMemberHistoryTable: React.FC<CompositeMemberHistoryTableProps> = ({
  title,
  data,
  setLocalData,
  row,
  removeRow,
  planIds,
  inceptionDate,
}) => {
  const [showSearchBar, setShowSearchBar] = useState(false)
  const toDate = row.data[0]?.endDate === DEFAULT_NEW_END_DATE ? "Current" : moment(first(row.data)?.endDate).format("MM/DD/YYYY")
  const fromDate = row.data[0]?.startDate === DEFAULT_NEW_START_DATE ? moment(inceptionDate).add(1, 'day').format("MM/DD/YYYY") : moment(first(row.data)?.startDate).add(1, 'day').format("MM/DD/YYYY")

  const generateId = () => {
    // console.log("new", {key, data})
    if(data?.length > 0) {
      return (data[data.length - 1]?.orderedPid || 999999999) + 1
    }
  }

  const convertAddedItem = (addItem: ClientPortfolioIsCompositeFragment) => {
    let {compositeMembers, ...rest} = addItem
    let startDate = row.data[0]?.startDate
    let endDate = row.data[0]?.endDate
    setShowSearchBar(false)
    return ({
      startDate,
      endDate,
      member: rest,
      newAdded: true,
      orderedPid: generateId(),
      source: "previous", // to help decide which table new data sits in.
      __typename: "CompositeMember"
    })
  }
  return (
    <div className="">
      <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
        <h5 className="headline mt-3 text-nowrap d-inline-block">
          {fromDate} - {toDate}
        </h5>
      </div>
      <Table hover size="sm" className="exportable" data-export-name={title} data-export-settings={JSON.stringify({arbitraryCols: [{heading: "Date", value: fromDate, column: 0}]})}>
        <thead className="table-border-bottom">
          <tr className="table-header">
            {CompositeMemberInput.map((headerDef, idx) => {
              return (
                <th key={`Composite-members-header-${idx}`} className={classnames(headerDef.widthClass, "text-uppercase font-weight-normal text-gray-80 font-small text-left vertical-align-bottom")}>
                  {headerDef.title}
                </th>
              )
            })}
            <th key={"actions"}>

            </th>
          </tr>
        </thead>
        <tbody>
          {row.data.map((el: any, idx: number) => {
            return (
              <CompositeMemberRow
                data={el}
                key={`previous-composite-member-row-${idx}`}
                row={idx}
                editMode={true}
                columnDef={CompositeMemberInput}
                removeRow={() => {removeRow(el?.endDate, el?.member?.id)}}
                updateValue={() => {}}
                tableKey={"previous"}
                hideRemoveButton={row.data.length <= 1}
              />
            )
          })}
          <tr key={"add-member"} className={"text-center border-bottom-line-break"}>
            <td align="center" colSpan={3}>
              <Row key={`add-member`}>
                <Button color="link" className="text-callan-blue btn-thin py-0" onClick={() => setShowSearchBar(true)}>
                  {"Add Member"}
                  <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
                </Button>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
      { showSearchBar &&
        <ClientPortfolioAddStatic
          list={{items: row.data} as any}
          setEditedList={(value) => setLocalData((localData) => value(localData))}
          editMode={true}
          convertAddedItem={convertAddedItem}
          convertToClientPortfolioFragment={(item: any) => _.get(item, `member.id`)}
          auth={{} as any}
          planIds={planIds}
          searchTypesOverride={[SearchTypeDisplays['Portfolio']]}
        />
      }
    </div>
  )
}

export interface CompositeMembersTableProps {
  editMode: boolean,
  tableVisibility: TableVisibility,
  setTableVisibility: React.Dispatch<React.SetStateAction<TableVisibility>>,
  key: TableKeys,
  data: CompositeMemberExtendedType[],
  planIds: {id: number, name: string, __typename: string}[],
  setEditedCompositeMembers: (props: any) => void,
  endCurrent: () => void,
  revertEndCurrent: () => void,
  setData: (data: any) => void, // to update parent state for all data
  currentTableBatchEndDate: string | null
  endAssetDate?: string
  handleTableStateChange: (state: CompositeMemberExtendedType[], tableName: TableKeys) => void
  setCurrentTableBatchEndDate?: React.Dispatch<React.SetStateAction<string | null>>
  hasFullHistoryButton?: boolean
  inceptionDate?: string
  toggleFullHistoryModal?: () => void
}

const DEFAULT_NEW_END_DATE = "9999-12-31"

const CompositeMembersTableComponent = (props: CompositeMembersTableProps) => {
  let {editMode, key, data, setData, planIds, setEditedCompositeMembers, endCurrent, revertEndCurrent, currentTableBatchEndDate, handleTableStateChange: handleChange, tableVisibility, hasFullHistoryButton, toggleFullHistoryModal, inceptionDate, endAssetDate} = props
  const showStatus = tableVisibility[key]

  const showFullHistoryButton = key ==="current" && !!hasFullHistoryButton
  const [showSearchBar, setShowSearchBar] = useState(false)
  let greyClass = ""

  useEffect(() => {
    setShowSearchBar(false)
  }, [editMode])

  if (key === "current" && tableVisibility["new"]) {
    greyClass = "background-gray-20"
  } else if (key === "previous" && tableVisibility["new"] && tableVisibility["current"]) {
    greyClass = "background-gray-30"
  } else if (key === "previous" && tableVisibility["current"]) {
    greyClass = "background-gray-20"
  }

  const generateId = () => {
    // console.log("new", {key, data})
    if(data?.length > 0) {
      return (data[data.length - 1]?.orderedPid || 999999999) + 1
    }
  }

  // logic diff among tables
  const convertAddedItem = (addItem: ClientPortfolioIsCompositeFragment) => {
    let {compositeMembers, ...rest} = addItem
    let startDate = key === "new" ? currentTableBatchEndDate : moment().format(DATE_API_FORMAT)
    if(key === "current") {
      startDate =  data[0]?.startDate || inceptionDate || DEFAULT_NEW_START_DATE
    }
    let endDate = DEFAULT_NEW_END_DATE
    if(key === "previous") {
      startDate = data[0]?.startDate
      endDate = data[0]?.endDate
    }
    setShowSearchBar(false)
    return ({
      startDate,
      endDate,
      member: rest,
      newAdded: true,
      orderedPid: generateId(),
      source: key, // to help decide which table new data sits in.
      __typename: "CompositeMember"
    })
  }
  // TODO
  const removeRow = (idx: number) => {
    let newData = [
      ...data.slice(0, idx),
      ...data.slice(idx + 1)
    ]
    setData(newData)
    handleChange(newData, key)
  }

  const handleValueChange = (idx: any, value: any, type: any, property: any) => {
    let nextData: Maybe<CompositeMemberExtendedType[]> =
    data?.map((el, index) => {
        if (idx === index) {
          let newValue = _.cloneDeep(data[index])
          if (newValue) {
            _.set(newValue, property, value)
          }
          return newValue
        } else {
          return el
        }
      }) || []
    console.log("handleValue")
    handleChange(nextData, key)
  }

  const batchEndDateComponent = () => {
    let setCurrentTableBatchEndDate = props?.setCurrentTableBatchEndDate

    let labelClass = ""
    if(key === "current") {
      labelClass = "background-private-background text-accent-red"
    }

    return (
      <div key={"batch-end-date"} className={classnames("d-flex w-100 justify-content mb-3", labelClass)}>
        {BatchEndDateInput.map((headerDef, idx) => {
          let property = headerDef.property
          let propertyVal:string|null = ""
          if(key === "current" && property === "endDate") {
            propertyVal = props?.currentTableBatchEndDate
          }else if (property === "startDate") {
            propertyVal = moment(_.get(data[0], property) || "").add(1, 'day').format(DATE_API_FORMAT)
          }

          return(
            <div key={idx} className={classnames('d-flex ml-3')}>
              <div className="client-portfolio-date-label">
                {headerDef.label}
              </div>
              <FormInput
                key={headerDef.property}
                property={property}
                displayName={""}
                type={headerDef.type}
                subtype={headerDef.subtype}
                idx={`${key}-composite-member-item-batch-end-date`}
                editMode={editMode}
                propertyVal={propertyVal}
                readonly={!!headerDef.readonly || key ==="previous"}
                updateValue={(value: any) => {
                  // updateValue(value, headerDef.type, headerDef.field)
                  // handleBatchCurrentEndDateChange(value, data, key)
                  setCurrentTableBatchEndDate && setCurrentTableBatchEndDate(value)
                }}
                inputProps={{
                  min: (data && data?.length > 0) ? data[0].startDate : "1900-01-01",
                }}
                // required={headerDef.required}
                // optionSource={headerDef.optionSource}
                subClasses={headerDef.subClasses || {}}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const addMemberComponent = () => {
    return(
      <tr key={"add-member"} className={"text-center border-bottom-line-break"}>
        <td align="center" colSpan={3}>
          <Row key={`add-member`}>
            {editMode &&(
              <Button color="link" className="text-callan-blue btn-thin py-0" onClick={() => setShowSearchBar(true)}>
                {"Add Member"}
                <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
              </Button>)
            }
        </Row>
        </td>
      </tr>
    )
  }
  const showEndDate = (key ==="previous" || (key === "current" && tableVisibility["new"])) && !isEmptyTable(data)
  if(!showStatus) { //CAL-2891
    return <div key={`no-${key}`}></div>
  }else {
    return (
      <div key={`composite-members-${key}`}>
        <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
          <div className="white-space-nowrap">
            <h5 className="headline text-uppercase mt-3 text-nowrap d-inline-block">
              {`Composite Members `}
            </h5>
            <h5 className="headline text-uppercase text-gray-70 pl-1 mt-3 d-inline-block">{(showEndDate || key !== "current") && ` (${key})`}</h5>
          </div>
          <div className="mt-3">
            {editMode && key === "current" && endAssetDate === DEFAULT_NEW_END_DATE && (
              !tableVisibility["new"] && <Button color="link" className="text-callan-blue btn-thin pt-0 pb-0" onClick={() => endCurrent()}>
                {"End Current & Start New"}
                <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {editMode && (key === "new") &&
              (<Button color="link" className="text-callan-blue btn-thin pt-0" onClick={() => revertEndCurrent()}>
                {"Delete"}
                <FontAwesomeIcon icon="trash" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {key === "current" && showFullHistoryButton &&
              (<Button color="link" className="text-callan-blue btn-thin pt-0 pb-0" onClick={toggleFullHistoryModal}>
                {"View Full History"}
                <FontAwesomeIcon icon="history" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {key === "current" && endAssetDate === DEFAULT_NEW_END_DATE &&
              <>
                Beginning {moment(data[0]?.startDate || inceptionDate).add(1, "day").format("MM/DD/YYYY")}
              </>
            }
            {key === "current" && endAssetDate !== DEFAULT_NEW_END_DATE &&
              <>
                Ending {moment(data[0]?.endDate).format("MM/DD/YYYY")}
              </>
            }
          </div>
        </div>
        {/* {`greyState ${!!props?.greyOut}`} */}
        <Table hover size="sm" className={classnames(greyClass, {"mb-0": showEndDate})}>
          <thead className="table-border-bottom">
            <tr className="table-header">
              {CompositeMemberInput.map((headerDef, idx) => {
                return (
                  <th key={`Composite-members-header-${idx}`} className={classnames(headerDef.widthClass, "text-uppercase font-weight-normal text-gray-80 font-small text-left vertical-align-bottom")}>
                    {headerDef.title}
                  </th>
                )
              })}
              <th key={"actions"}>

              </th>
            </tr>
          </thead>
          <tbody>
            {!isEmptyTable(data) &&
              data.map((el: any, idx: number) => {
                return (
                  <CompositeMemberRow
                    data={el}
                    key={`${key}-composite-member-row-${idx}`}
                    row={idx}
                    editMode={editMode}
                    columnDef={CompositeMemberInput}
                    removeRow={removeRow}
                    updateValue={(value, type, property) => handleValueChange(idx, value, type, property)}
                    tableKey={key}
                    hideRemoveButton={(key === "current" && tableVisibility["new"]) || data.length <= 1}
                    grayClass={greyClass}
                  />
                )
              })}
            {editMode && isEmptyTable(data) && (
              <tr key={"empty"} className={"text-center border-bottom-line-break"}>
                <td align="center" colSpan={3}>
                  Add a portfolio to this composite
                </td>
              </tr>
            )}
            {editMode && (key === "new" || (key === "current" && !tableVisibility["new"])) && addMemberComponent()}
          </tbody>
        </Table>
        {showEndDate && (
          batchEndDateComponent()
        )}
        { showSearchBar &&
          <ClientPortfolioAddStatic
            list={{items: data} as any}
            setEditedList={(value) => setEditedCompositeMembers(value)}
            editMode={editMode}
            convertAddedItem={convertAddedItem}
            convertToClientPortfolioFragment={(item: any) => _.get(item, `member.id`)}
            auth={{} as any}
            planIds={planIds}
            searchTypesOverride={[SearchTypeDisplays['Portfolio']]}
          />
        }
      </div>
    )
  }
}

/** test clientPortfolioId 50483(with histories)
 **/
export const ClientPortfolioCompositeMembers: React.FC<CompositeMembersComponentProps> = (props) => {
  const componentName = "CompositeMembers"
  const { clientportfolioId, data, initialState, editMode, handleChange, planIds, setEditedCompositeMembers, inceptionDate, endAssetDate } = props
  // edited data
  const [currentData, setData] = useState(() => getInitialData(data))

  const [newTableData, setNewTableData] =  useState<CompositeMemberExtendedType[]>(() => getNewTableData(currentData))
  const [currentTableData, setCurrentTableData] = useState(() => getCurrentCompositeMembersTableData(currentData))
  const [previousTableData, setPreviousTableData] =  useState(() => getPreviousTableData(currentData))

  const [tableVisibility, setTableVisibility] = useState<TableVisibility>({
    new: false,
    current: !!currentTableData,
    previous: false,
  })

  const [currentTableBatchEndDate, setCurrentTableBatchEndDate] = useState<string | null>(null)

  const [showFullHistoryModal, setShowFullHistoryModal] = useState<boolean>(false)

  const toggleFullHistoryModal = () => setShowFullHistoryModal(!showFullHistoryModal)

  // generate duplicate data from initial current table, doesn't change state.
  const feedNewTable = () => {
    // handleChange
    let newTableDataReturn: CompositeMemberExtendedType[] = (newTableData.length > 0 ? newTableData : currentTableData)?.map(el => {
      let {startDate, ...rest} = el
      return {...rest, startDate: currentTableBatchEndDate || startDate, source: "new", newAdded: true, orderedPid: el?.member?.id}
    }) || []
    return newTableDataReturn
  }

  useEffect(() => {
    let newState = getInitialData(data)
    setCurrentTableData(getCurrentCompositeMembersTableData(newState))
    setPreviousTableData(getPreviousTableData(newState))
    setNewTableData(getNewTableData(newState))
  }, [data])

  useEffect(() => {
    if(!editMode) {
      // setSelectedPlan(null)
      // setShowDeleteButton(false)
      // reset after save/cancel
      setNewTableData([])
      setTableVisibility((previousTableData) => ({...previousTableData, new: false, previous: false}))
      let newState = getInitialData(data)
      setData(getInitialData(newState))
      setCurrentTableData(getCurrentCompositeMembersTableData(newState))
      setPreviousTableData(getPreviousTableData(newState))
      setCurrentTableBatchEndDate(null)
    }
  }, [editMode])

  useEffect(() => {
    if(editMode && endAssetDate) {
      if(endAssetDate === DEFAULT_NEW_END_DATE) {
        let updatedData = currentData.map((el) => {
          let {endDate, ...rest} = el
          if(el.source === "current") {
            endDate = endAssetDate
          }
          return {...rest, endDate}
        })
        handleChange(updatedData)
      } else {
        const inputEndAssetDate = moment(endAssetDate)
        let deletedData = currentData.filter(el => moment(el?.startDate).isSameOrAfter(inputEndAssetDate))
        let keptData = currentData.filter(el => moment(el?.endDate).isSameOrBefore(inputEndAssetDate))
        let modifiedData = difference(currentData, deletedData, keptData).map(el => ({...el, endDate: inputEndAssetDate.format(DATE_API_FORMAT), source: "current"}))
        handleChange([...modifiedData, ...keptData])
      }
    }
  }, [endAssetDate])

  useEffect(() => {
    if(currentTableBatchEndDate) {
      let nextCurrentTableData: CompositeMemberExtendedType[] =
        currentTableData?.map((el, index) => {
        let newValue = _.cloneDeep(el)
        if (newValue) {
          _.set(newValue, "endDate", currentTableBatchEndDate)
          if(el?.source) {
            _.set(newValue, "source", "current")
          }
        }
        return newValue
        }) || []
      let nextNewTableData= feedNewTable()
      // setNewTableData(nextNewTableData)
      let newState = [...nextCurrentTableData, ...nextNewTableData, ...previousTableData]
      handleChange(_.compact(newState))
    }
  }, [currentTableBatchEndDate])

  const handleTableStateChange = (state: any, tableName: string) => {
    let newState: any[] = []
    switch (tableName) {
      case "current":
        newState = [...state, ...newTableData, ...previousTableData]
        break
      case "new":
        newState = [...currentTableData, ...state, ...previousTableData]
        break
      case "previous":
        newState = [...currentTableData, ...newTableData, ...state,]
        break
    }
    handleChange(newState)
  }

  const endCurrent = () => {
    console.log('end current')
    // setShowDeleteButton(true)
    if(editMode) {
      let nextNewTableData= feedNewTable()
      let newState = [...currentTableData, ...nextNewTableData, ...previousTableData]
      handleChange(_.compact(newState))
      setCurrentTableBatchEndDate(null)
      setTableVisibility((previousTableData) => ({...previousTableData, new: true}))
    }
  }

  const revertEndCurrent = () => {
    setEditedCompositeMembers(initialState as CompositeMemberExtendedType[])
    // setShowDeleteButton(false)
    setCurrentTableBatchEndDate(null)
    setTableVisibility((previousTableData) => ({...previousTableData, new: false}))
  }

  const showHistoryButtonComponent = () => {
    return (<div className="expand-link">
      <Button color="link" onClick={(event) => {event.stopPropagation(); setTableVisibility((prev) => ({...prev, previous: !prev.previous}))}} className={"wider-link-on-hover"}>
        <FontAwesomeIcon
          icon={tableVisibility.previous  ? "chevron-up" : "chevron-down"}
          size="sm"
        />
        <span className="pl-2 expand-text">{tableVisibility.previous  ? "Hide History" : "Show History"}</span>
      </Button>
    </div>)
  }

  let componentProps = {editMode, planIds, setEditedCompositeMembers, endCurrent, revertEndCurrent, handleTableStateChange, tableVisibility, setTableVisibility, inceptionDate, endAssetDate}

  let newTableProps = {...componentProps, key: "new" as TableKeys, data: newTableData, setData: setNewTableData, currentTableBatchEndDate,}
  let currentTableProps = {...componentProps, key: "current" as TableKeys, data: currentTableData, setData: setCurrentTableData, setCurrentTableBatchEndDate, currentTableBatchEndDate, editMode: editMode, toggleFullHistoryModal, hasFullHistoryButton: previousTableData?.length > 0}

  let firstPreviousTableData = previousTableData.filter(el => el.endDate === previousTableData[0].endDate)
  let previousTableProps = {...componentProps, key: "previous" as TableKeys, data: firstPreviousTableData, setData: setPreviousTableData, editMode: editMode, toggleFullHistoryModal, hasFullHistoryButton: true}
  return (
    <Row className={classnames("pl-0")} key={`${componentName}`}>
      <Col className={"px-0 mb-2"}>
        {/* "---new Table below ------------------------------------------" */}
        {CompositeMembersTableComponent(newTableProps)}
        {/* "---current Table below --------------------------------------" */}
        {CompositeMembersTableComponent(currentTableProps)}
        {/** no previous table, no show/hide history */}
        {/** previous table, initial show history button, no previousComponent */}
        {/** previous table, click show history show previousComponent , show hide history button*/}
        {/* "---previous Table below -------------------------------------" */}
        {/* {CompositeMembersTableComponent(previousTableProps)} */}
        {/* {previousTableData?.length > 0 && showHistoryButtonComponent()} */}
      </Col>
      <CompositeMemberHistoryModal
        clientportfolioId={clientportfolioId}
        isOpen={showFullHistoryModal}
        title={'Composite Members'}
        toggle={toggleFullHistoryModal}
        data={data}
        handleChange={handleChange}
        planIds={planIds}
        inceptionDate={inceptionDate}
      />
    </Row>
  )
}
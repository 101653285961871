import React, { useEffect, useRef, useState } from "react"
import { WithTopNav } from "../ui/LayoutWrapper"
import { appProps } from "../../queries/extended/appProps"
import { Redirect, Switch, useHistory } from "react-router-dom"
import LoadingOverlay from "../ui/Loading"
import { GetAllAssetClassesQuery, useClientPortfolioDefinitionContinuedQuery, useClientPortfolioDefinitionQuery, useGetAllAssetClassesQuery, ClientPortfolioCompositeMembersDocument, ClientPortfolioCompositeMembersFragment } from "../../__generated__/graphql"
import { Breadcrumb, BreadcrumbItem, Col, Container, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledAlert } from "reactstrap"
import { CrumbRoute } from "../../routes/crumb-route"
import { ClientPortfolioRouteProps } from "../../queries/extended/types/ClientPortfolio"
import ClientPortfolioDefinition from "./ClientPortfolioDefinition/ClientPortfolioDefinition"
import ClientPortfolioPicker from "../ui/Pickers/ClientPortfolioPicker"
import { cloneDeep, compact, isEqual, merge, uniq } from "lodash"
import { ClientPortfolioDefinitionQueryUnionType } from "./ClientPortfolioDefinition/helper"
import classnames from "classnames"
import PortfolioJobSettings from "./ClientPortfolioJobSettings/ClientPortfolioJobSettings"
import Auth from "../../Auth/Auth"
import { useApolloClient } from "@apollo/client"
import iassign from "immutable-assign"

const QueryClientPortfolioDetails: React.FC<appProps & ClientPortfolioRouteProps> = (props) => {
  const { match } = props
  const { clientportfolioId } = match.params
  const history = useHistory()

  const { loading, error, data } = useClientPortfolioDefinitionQuery({
    variables: { id: parseInt(clientportfolioId) },
    fetchPolicy: "cache-first",
  })

  const {
    loading: partIILoading,
    error: partIIError,
    data: partIIData,
  } = useClientPortfolioDefinitionContinuedQuery({
    variables: { id: parseInt(clientportfolioId) },
    fetchPolicy: "cache-first",
  })

  const {
    loading: assetClassesLoading,
    error: assetClassesError,
    data: assetClasses,
  } = useGetAllAssetClassesQuery({ fetchPolicy: "cache-first" })

  if (loading || partIILoading || assetClassesLoading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  } else if (error || partIIError || assetClassesError) {
    return (
      <div>
        {error?.message && <p>{error?.message}</p>}
        {error?.message && <p>{partIIError?.message}</p>}
        {error?.message && <p>{assetClassesError?.message}</p>}
      </div>
    )
  } else if (!data?.clientPortfolio || !partIIData?.clientPortfolio) {
    return (
      <Container fluid>
        <UncontrolledAlert color='danger'>
          <h4>404: ClientPortfolio Not Found</h4>
          <p>No ClientPortfolio exists with this id.</p>
        </UncontrolledAlert>
      </Container>
    )
  }

  if (!assetClasses) {
    console.error("AssetClasses Data Not Found.")
  }

  const { type } = match.params
  const subtype: string = ""
  const urlWithType = subtype ? match.url.slice(0, -subtype.length - 1) : match.url
  const urlWithoutType = type ? urlWithType.slice(0, -type.length - 1) : urlWithType

  let mergedData = merge({}, data, partIIData) as ClientPortfolioDefinitionQueryUnionType
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col xs='10' sm='8' md='6'>
                <Breadcrumb>
                  <BreadcrumbItem
                    className='headline-breadcrumbs'
                    key='test'
                    onClick={() => history.push(`/plans/${data.clientPortfolio?.plan?.id}`)}
                  >
                    {data.clientPortfolio?.plan?.name}
                  </BreadcrumbItem>
                </Breadcrumb>
                {data.clientPortfolio?.plan && (
                  <ClientPortfolioPicker
                    clientportfolioId={data.clientPortfolio?.plan.id}
                    title={`View another client portfolio by ${data.clientPortfolio?.plan?.name || ""}`}
                    onClick={(clientportfolioId) => {
                      history.push(`/clientportfolios/${clientportfolioId}`)
                    }}
                  >
                    <>
                      <div className='dropdown'>
                        <DropdownToggle caret>
                          <h2 className='headline'>{data.clientPortfolio?.name || ""}</h2>
                        </DropdownToggle>
                        {!data.clientPortfolio?.isActive && <span className='inactive-tag ml-4'>Inactive</span>}
                      </div>
                    </>
                  </ClientPortfolioPicker>
                )}
              </Col>
            </Row>
            <Nav className='sub-nav sub-nav-primary' tabs role='group' aria-label='Portfolio Subpage'>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutType}/overview`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/overview`)}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutType}/jobsettings`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/jobsettings`)}
                >
                  Job Settings
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        <CrumbRoute
          exact
          title='overview'
          path={`${urlWithoutType}/overview`}
          render={() => <ClientPortfolioDetailsFetchComposite data={mergedData} assetClasses={assetClasses} {...props} clientportfolioId={parseInt(clientportfolioId)}/>}
        />
        <CrumbRoute
          title='job settings'
          path={`${urlWithoutType}/jobsettings/:id([0-9]+)?`}
          render={() => <PortfolioJobSettings clientportfolioId={parseInt(clientportfolioId)} planId={data?.clientPortfolio?.plan?.id} {...props} />}
        />
        <Redirect to={`${urlWithoutType}/overview`} />
      </Switch>
    </div>
  )
}

type ClientPortfolioDetailsFetchCompositeProps = {
  clientportfolioId: number
  data: ClientPortfolioDefinitionQueryUnionType
  assetClasses?: GetAllAssetClassesQuery
  auth: Auth
  // match: match<{ clientportfolioId: string }>
  // refetchFunction: () => void
}

const ClientPortfolioDetailsFetchComposite: React.FC<ClientPortfolioDetailsFetchCompositeProps> = (props) => {
  const apolloClient = useApolloClient()
  const [mergedData, setMergedData] = useState(props.data)
  const [errors, setErrors] = useState<string[]>([])
  // const [loading, setLoading] = useState<boolean>(true)
  const abortControllerRef = useRef(new AbortController())

  useEffect(() => {
    const newCompositeMembers = compact(uniq(props.data?.clientPortfolio?.memberOfComposites?.map((member) => member?.composite?.id) || []))
    setMergedData(props.data)
    // setLoading(true)
    // Reset data so that it does not add together
    if(abortControllerRef.current){
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    const executeQueries = async () => {
      const results = await Promise.all(
        newCompositeMembers.map(async (id) => {
          return apolloClient.query({
            query: ClientPortfolioCompositeMembersDocument,
            variables: {id: id},
            context: {
              fetchOptions: {
                signal: abortControllerRef.current.signal
              }
            }
          })
        })
      );

      // Send the data to the required state variables
      results.forEach((result) => {
        const data = result.data
        if(result.errors) setErrors((errors) => compact([...errors, ...(data?.errors?.map((error:Error) => error.message) || [])]))
        setMergedData((mergedData) => {
          let newState = iassign(
            mergedData,
            currentState => currentState.clientPortfolio?.memberOfComposites,
            memberOfComposites => {
              let rowsClone = cloneDeep(memberOfComposites) || []
              memberOfComposites?.forEach((member, idx) => {
                if(member?.composite?.id === data?.clientPortfolio?.id){
                  rowsClone[idx] = {...rowsClone[idx], composite: {...rowsClone[idx]?.composite, compositeMembers: data?.clientPortfolio.compositeMembers} as ClientPortfolioCompositeMembersFragment, __typename: "MemberOfComposite"}
                }
              })
              return rowsClone
            }
          )
          return newState
          })
      })
      // setLoading(false)
    };

    executeQueries();
  }, [props.data])

  if (errors.length > 0) {
    return (
      <div>
        {errors.map((error) => <p>{error}</p>)}
      </div>
    )
  }else {
    return <ClientPortfolioDefinition {...props} data={mergedData}/>

  }
}

export default WithTopNav(QueryClientPortfolioDetails)